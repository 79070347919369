import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';


const RegistrationPerMonth = (props) => {


    // revenue per month [all months]

    const [revenue, setRevenue] = useState({})

    const [selectedYear, setSelectedYear] = useState(0)
    

    const getRevenueData = async (year) => {

        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        let response = await fetch(url + '/api/v1/registrations-graph?year=' + year,{credentials: 'include'});
        let data = await response.json();
        

        if(data.success){

            setRevenue({
                data: {
                    labels: [... data.data.labels],
                    datasets: [
                        {
                            label: 'DRB',
                            data:  [... data.data.drbs],
                            borderColor: '#8b054d',
                            backgroundColor: 'rgba(74,162,184, 0.6)',
                            fill: true
                        },
                        {
                            label: 'Lease',
                            data:  [... data.data.leases],
                            borderColor: '#8b054d',
                            backgroundColor: 'rgba(77,81,110, 0.6)',
                            fill: true
                        },
                    ]
                }
            })
        }

        // console.log(props.year)
    }

    const handleChange = (event) => {
        setSelectedYear(
            event.target.value
        );
        getRevenueData(event.target.value);
    }



    return (
        <div>


            <div style={{marginTop:'1rem', paddingLeft:'1rem'}}>
                <span>Year
                <select onChange={handleChange} style={{padding: '4px',
                    backgroundColor: "white",
                    border: '1px solid #dddddd',
                    marginLeft:'5px'}}>
                    <option value="" selected disabled>Select Year</option>
                    {
                        props.years.map((data)=>{
                            return(
                                <option key={data} value={data}
                                >{data}</option>

                            )
                            
                        })
                    }
                </select>
                </span>

            </div>

            {
                selectedYear != 0 ? 
                (
                    <div>
                    <Bar data={
                        revenue.data
                    } width={100} height={400} options={{ maintainAspectRatio: false,
                        scales: {
                            yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Dalasis'
                            }
                            }],
                            xAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: selectedYear
                            }
                            }],
                        }    
                    }}/>        
                </div>
                ) : null
            }


        </div>
    )



}

export default RegistrationPerMonth;

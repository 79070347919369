import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import { Helmet } from 'react-helmet';

const Logs = (props) => {
    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;

    const [page, setPage] = useState({page: page_no});
    const [pagination, setPagination] = useState({current_page: "", last_page: ""});
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const getLogs = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/logs?page=' + page.page, {credentials: 'include'});
        let data = await response.json();

        if(data) {
            setLogs([...data.data.data]);
            setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
        }
    }

    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/admin/logs?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/admin/logs?page=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/admin/logs?page=' + currentPage);
         }
         window.scrollTo(0,0);
         
     }

     useEffect(() => {
        getLogs();
     }, [page])

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>SYSTEM LOGS</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">System Logs</div>
                <div className="add-search">
                    
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                   <table>
                       <thead>
                           <tr>
                               <th>Name</th>
                               <th>Event</th>
                               <th>User</th>
                               <th>IP Address</th>
                               <th>DateTime</th>
                           </tr>
                       </thead>
                       <tbody>
                           {logs.map((value) => {
                               return (
                                   <>
                                    <tr>
                                        <td>{value.event}</td>
                                        <td>{value.description}</td>
                                        <td>{value.admin.length >= 1? value.admin[0].first_name + ' ' + value.admin[0].middle_name + ' ' + value.admin[0].last_name : 'N/A'}</td>
                                        <td>{value.ip_address}</td>
                                        <td>{value.created_at}</td>
                                    </tr>
                                   </>
                               )
                           })}
                       </tbody>
                   </table>
                </div>
                {pagination.last_page >= 2? (
                    <>
                      <div className="pagination">
                        <button className="pagination__btn"  
                        disabled={pagination.lastPage === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                        <button className="pagination__btn" 
                        disabled={ Number(page.page) === pagination.lastPage ? true : false} 
                        onClick={next}>{loading? 
                        <i className="fas fa-spinner fa-spin"></i> : 'Next'}</button>
                    </div>
                    </>
                ): null}
            </div>
        </div>
    )
}

export default Logs;

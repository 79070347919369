import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const TransactionReport = (props) => {

    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;

    const [page, setPage] = useState({page: page_no});
    const [pagination, setPagination] = useState({current_page: "", last_page: ""})
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState([]);
    const [noResultFound, setNoResultFound] = useState(false);

    const getTransactionReport = async (event) => {
        event.preventDefault();
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/transaction-report?query=' + query + '&page=' + page.page, {credentials: 'include'});
        let data = await response.json();

        if(data) {
            if(data.success) {
                setResult([...data.data.data]);
                setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
                setLoading(false);
                if(data.data.data.length != 0)
                    setNoResultFound(false);
                
                else
                    setNoResultFound(true);
            }
            else{
                shortFunds(data.message.message)
            }
        }
        setLoading(false);
    }

    const getReport = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/transaction-report?query=' + query + '&page=' + page.page, {credentials: 'include'});
        let data = await response.json();

        if(data) {
            if(data.success) {
                setResult([...data.data.data]);
                setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
            }
        }
    }

    const saveQuery = (event) => {
        setQuery(event.target.value);
    }

    const shortFunds = (msg) =>{
        Swal.fire({
            title: 'Insufficent funds',
            text: msg,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#17a2b8',
            confirmButtonText: 'OK'
          })

    }
    
    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/search/transaction-report?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/search/transaction-reportpage=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/search/transaction-report?page=' + currentPage);
         }
         window.scrollTo(0,0);
         
     }

     useEffect(() => {
        if(query !== "" && page.page >= 1) {
            getReport();
        }
     }, [page]);
    
    return (
        <div className="dashboard__container">
                <Helmet>
                <meta charSet="utf-8" />
                <title>Transaction Reporting| Dashboard</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="reporting">
                    <h2>TRANSACTION REPORT</h2>
                    <form onSubmit={getTransactionReport}>
                        <input type="text" placeholder="Registration Number" onChange={saveQuery}/>
                        <button type="submit">{loading? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-search"></i>}</button>
                    </form>
                </div>

                <div className="table__container" style={{overflowX: 'auto'}}>
                <table>
                       <thead>
                           <tr>
                               <th>Ref. Number</th>
                               <th>SR. Number</th>
                               <th>Intrument</th>
                               <th>Grantor/Lessor</th>
                               <th>Grantee/Lessee</th>
                               <th>Date of Execution</th>
                               <th>OR Number</th>
                               <th>Details</th>
                               <th>Documents</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                result.length >= 1 ? (
                                    <>

                                        {result.map((value) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{value.serial_number}</td>
                                                        <td>{value.reference_number}</td>
                                                        <td>{value.instrumentType.length >= 1? value.instrumentType[0].type : 'N/A'}</td>
                                                        <td>{value.grantor.length >= 1? value.grantor[0].first_name + " " + value.grantor[0].middle_name + " " +
                                                        value.grantor[0].last_name + " " + value.grantor[0].nin  : null}
                                                            {value.lessor.length >= 1?  value.lessor[0].first_name + " " + value.lessor[0].middle_name + " " +
                                                        value.lessor[0].last_name + " " + value.lessor[0].nin  : null}
                                                        </td>
                                                        <td>{ value.grantee.length >= 1? value.grantee[0].first_name + " " + value.grantee[0].middle_name + " " +
                                                        value.grantee[0].last_name + " " + value.grantee[0].nin  : null }
                                                        { value.lessee.length >= 1? value.lessee[0].first_name + " " + value.lessee[0].middle_name + " " +
                                                        value.lessee[0].last_name + " " + value.lessee[0].nin  : null }
                                                        </td>
                                                        <td>
                                                            {value.execution_date}
                                                        </td>
                                                        <td>
                                                            {value.or_number}
                                                        </td>
                                                        <td>
                                                        {value.registration_type === "KL"? (
                                                            <>
                                                                <Link to={"/deed/kombo-lease/view/" + value.id} className="view">View</Link>
                                                            </>
                                                        ): null}
                                                        {value.registration_type === "PL"? (
                                                            <>
                                                            <Link to={"/deed/provincial-lease/view/" + value.id} className="view">View</Link>
                                                            </>
                                                        ): null}
                                                        {value.registration_type !== "PL" && value.registration_type !== "KL"? (
                                                            <>
                                                            <a href={"/deed/drb-deed/view/" + value.id +"/" + value.registration_type }  className="view">View</a>
                                                            </>
                                                        ): null}
                                                        </td>
                                                        <td>
                                                            <a href={"/document/view/" + value.id} className="view">View</a>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </>
                                ) :   noResultFound ? (
                                    <tr>
                                        <td colSpan='9'  style={{textAlign:"center"}}>
                                            No search results found
                                        </td>
                                        
                                    </tr>
    
                                ) : null
                            }


                        </tbody>
                    </table>
                </div>
                {pagination.last_page >= 2? (
                    <>
                      <div className="pagination">
                        <button className="pagination__btn"  
                        disabled={pagination.last_page === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                        <button className="pagination__btn" 
                        disabled={ Number(page_no) === Number(pagination.last_page) ? true : false} 
                        onClick={next}>{loading? 
                        <i className="fas fa-spinner fa-spin"></i> : 'Next'}</button>
                    </div>
                    </>
                ): null}
                </div>
    </div>
    
    )
}

export default TransactionReport;

import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


const RegistraGeneral = (props) => {
    
    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;

    const [page, setPage] = useState({page: page_no});
    const [registrarGenerals, setRegistrarGenerals] = useState([]);
    const [pagination, setPagination] = useState({current_page: "", last_page: ""});
    const [loading, setLoading] = useState(false);

    const getRegistrarGenerals = async () => {
        let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL : 
        process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/registrar-generals?page=' + page.page, {headers: {'Content-Type': 'application/json'}, 
        credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setRegistrarGenerals([...data.data.data]);
            setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
        }
    }

    const deletePlan = (id) => {

        setLoading(true);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#17a2b8',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {





            if (result.isConfirmed) {


                let url = process.env.NODE_ENV === "development"?
                process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
                let response = await fetch(url + '/api/v1/delete-user?id=' + id, 
                {method: 'DELETE',
                 headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();
        
                if(data.success) {
                    setLoading(false);
                    await Swal.fire(
                        'Deleted',
                        '',
                        'success'
                      )
                    window.location.reload();
                }
    
    



            }
          }).catch(err =>{
            Swal.fire('Deletion was unsuccessful', '', 'error')
          })


        setLoading(false);
    }


    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/admin/manage-registra-general?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/admin/manage-registra-general?page=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/admin/manage-registra-general?page=' + currentPage);
         }
         window.scrollTo(0,0);
         
     }

    useEffect(() => {
        getRegistrarGenerals();
    }, [page]);

    return (
        <div className="dashboard__container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">Registra General</div>
                <div className="add-search">
                    <div className="add">
                        <a href="/admin/manage-registra-general/add">Add New <i className="fas fa-plus"></i></a>
                    </div>
                    <div className="search">
                        <form>
                            <input type="text" placeholder="Search" />
                            <button type="submit" className='button'><i className="fas fa-search"></i></button>
                        </form>
                    </div>
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                <table>
                       <thead>
                           <tr>
                               <th>Name</th>
                               <th>Email</th>
                               <th>Telephone 1</th>
                               <th>Edit</th>
                               <th>Delete</th>
                           </tr>
                       </thead>
                       <tbody>
                           {registrarGenerals.map((value) => {
                               return (
                                   <>
                                    <tr>
                                        <td>{value.first_name + " "
                                         + value.middle_name + " "
                                         + value.last_name}</td>
                                         <td>{value.email}</td>
                                         <td>{value.telephone1}</td>
                                         <td>
                                             <ul>
                                             <li><Link to={"/admin/registra-general/edit/" + value.id}><i className="fas fa-edit"></i></Link></li>
                                             </ul>
                                         </td>
                                         <td>
                                             <ul>
                                                <li  onClick={() => deletePlan(value.id)} ><i className="fas fa-trash"></i></li>
                                             </ul>
                                         </td>
                                    </tr>
                                   </>
                               )
                           })}
                       </tbody>
                   </table>
                </div>
                {pagination.last_page >= 2? (
                    <>
                      <div className="pagination">
                        <button className="pagination__btn"  
                        disabled={pagination.last_page === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                        <button className="pagination__btn" 
                        disabled={ Number(page_no) === Number(pagination.last_page) ? true : false} 
                        onClick={next}>{loading? 
                        <i className="fas fa-spinner fa-spin"></i> : 'Next'}</button>
                    </div>
                    </>
                ): null}
            </div>
        </div>
    )
}

export default RegistraGeneral;

import React, { useState, useEffect } from "react";
import Sidebar from "../../../inc/Sidebar";
import TitleHeader from "../../../inc/TitleHeader";
import DatePicker from "react-datepicker";
import { useParams, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import moment from 'moment';

const EditLease = (props) => {
  const { id } = useParams();
  const { leaseType } = useParams();

  const [loading, setLoading] = useState(false);
  const [executionDate, setExecutionDate] = useState(null);
  const [executionDateText, setExecutionDateText] = useState("");
  const [dateOfReceipt, setDateOfReceipt] = useState(null);
  const [dateOfReceiptText, setDateOfReceiptText] = useState("");
  const [landLocation, setLandLocation] = useState("");
  const [lomp, setLomp] = useState("");
  const [partyRegistering, setPartyRegistering] = useState({
    company_name: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    nin: "",
    dob: "",
    address: "",
    telephone1: "",
    telephone2: "",
    email: "",
  });
  const [required, setRequired] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState({
    orNumber: "",
    stampDuty: "",
  });
  const [notes, setNotes] = useState("");
  const [recipient, setRecipient] = useState({
    company_name: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    nin: "",
    dob: "",
    address: "",
    telephone1: "",
    telephone2: "",
    email: "",
    or_number: "",
    stamp_duty: "",
  });
  const [location, setLocation] = useState({
    box_number: "",
    location_number: "",
  });
  const [witnessInformation, setWitnessInformation] = useState([
    {
      first_name: "",
      middle_name: "",
      last_name: "",
      dob: "",
      nin: "",
      telephone: "",
    },
  ]);
  const [lessee, setLessee] = useState([
    {
      company_name: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    },
  ]);
  const [lessor, setLessor] = useState([
    {
      company_name: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    },
  ]);

  const addLesseeRow = (e) => {
    e.preventDefault();
    lessee.push({
      company_name: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    });
    setLessee([...lessee]);
  };

  const removeLesseeRow = (e, index) => {
    e.preventDefault();
    lessee.splice(index);
    setLessee([...lessee]);
  };

  const saveLessee = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const tempLessee = [...lessee];
    tempLessee[index][id] = value;
    setLessee(tempLessee);
  };

  const addLessorRow = (e) => {
    e.preventDefault();
    lessor.push({
      company_name: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    });
    setLessor([...lessor]);
  };

  const removeLessorRow = (e, index) => {
    e.preventDefault();
    lessor.splice(index);
    setLessor([...lessor]);
  };

  const saveLessor = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const tempLessor = [...lessor];
    tempLessor[index][id] = value;
    setLessor(tempLessor);
  };

  const addWitnessInformationRow = (e) => {
    e.preventDefault();
    witnessInformation.push({
      first_name: "",
      middle_name: "",
      last_name: "",
      dob: "",
      nin: "",
      telephone: "",
    });
    setWitnessInformation([...witnessInformation]);
  };

  const removeWitnessInformationRow = (e, index) => {
    e.preventDefault();
    witnessInformation.splice(index);
    setWitnessInformation([...witnessInformation]);
  };

  const saveWitnessInformation = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const tempWitness = [...witnessInformation];
    tempWitness[index][id] = value;
    setWitnessInformation(tempWitness);
  };

  const savePartyRegistering = (event) => {
    setPartyRegistering({
      ...partyRegistering,
      [event.target.id]: event.target.value,
    });
  };

  const saveRecipient = (event) => {
    setRecipient({ ...recipient, [event.target.id]: event.target.value });
  };

  const saveNotes = (event) => {
    setNotes(event.target.value);
  };

  const saveLocation = (event) => {
    setLocation({ ...location, [event.target.id]: event.target.value });
  };

  const saveLandLocation = (event) => {
    setLandLocation(event.target.value);
  };

  const saveLomp = (event) => {
    setLomp(event.target.value);
  };

  const getLease = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(
      url + "/api/v1/" + leaseType + "?lease_id=" + id,
      { credentials: "include" }
    );
    let data = await response.json();

    if (data) {
      setLandLocation(data.data[0].land_situation);
      setLomp(data.data[0].lomp);
      setLessor([...data.data[0].lessor]);
      setLessee([...data.data[0].lessee]);
      setExecutionDate(new Date(data.data[0].execution_date));
      setExecutionDateText(new Date(data.data[0].execution_date));
      setDateOfReceipt(new Date(data.data[0].receipt_date));
      setDateOfReceiptText(new Date(data.data[0].receipt_date));
      // if(/^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-[0-9]{4}$/.test(data.data[0].execution_date)
      //  && /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-[0-9]{4}$/.test(data.data[0].receipt_date)) {
      //    let execution_date = data.data[0].execution_date.split('T')[0];
      //    let receipt_date = data.data[0].receipt_date.split('T')[0];
      //    let new_execution_date = execution_date.split('-')[1] + '-' + execution_date.split('-')[0] + '-' +
      //    execution_date.split('-')[2];
      //    let new_receipt_date = receipt_date.split('-')[1] + '-' + receipt_date.split('-')[0] + '-' +
      //    receipt_date.split('-')[2];
      //   setExecutionDate(new Date(new_execution_date));
      //   setExecutionDateText(new_execution_date);
      //   setDateOfReceipt(new Date(new_receipt_date));
      //   setDateOfReceiptText(new_receipt_date);

      //  } else {
      //   setExecutionDate(new Date(data.data[0].execution_date));
      //   setExecutionDateText(data.data[0].execution_date);
      //   setDateOfReceipt(new Date(data.data[0].receipt_date));
      //   setDateOfReceiptText(data.data[0].receipt_date);
      //     // console.log(executionDate);
      //     // console.log(dateOfReceipt);
      //  }
      setPartyRegistering({ ...data.data[0].party[0] });
      setRecipient({
        ...data.data[0].recipient[0],
        stamp_duty: data.data[0].stamp_duty,
        or_number: data.data[0].or_number,
      });
      setNotes(data.data[0].notes);
      setWitnessInformation([...data.data[0].witness]);
      setLocation({
        box_number: data.data[0].box_number,
        location_number: data.data[0].location_number,
      });
      setNotes(data.data[0].note);
    }
  };

  const handleExecusionDate = (date) => {
    setExecutionDate(date);
    let month = date.getMonth() + 1;
    let selectedDate = date.getDate();
    let year = date.getFullYear();
    let fullDate = month + "-" + selectedDate + "-" + year;
    setExecutionDateText(fullDate);
  };

  const handleDateOfReceipt = (date) => {
    setDateOfReceipt(date);
    let month = date.getMonth() + 1;
    let selectedDate = date.getDate();
    let year = date.getFullYear();
    let fullDate = month + "-" + selectedDate + "-" + year;
    setDateOfReceiptText(fullDate);
  };

  const submit = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);

    let date = {
      execution_date: executionDateText,
      receipt_date: dateOfReceiptText,
    };
    let data = {
      note: notes,
      date: date,
      book_id: id,
      land_situation: landLocation,
      partyRegistering: partyRegistering,
      location: location,
      witness: witnessInformation,
      recipient: recipient,
      lessor: lessor,
      lessee: lessee,
    };
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL + "/api/v1/update-lease"
        : process.env.REACT_APP_PRODUCTION_API_URL + "/api/v1/update-lease";

    if (
      executionDateText === "" ||
      dateOfReceiptText === "" ||
      recipient.or_number === "" ||
      recipient.stamp_duty === ""
    ) {
      setRequired(true);
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }
    setRequired(false);
    let response = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let result = await response.json();

    if (result) {
      if (result.success) {
        await Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Updated",
          customClass: "swal-height",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      } else {
        await Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error!",
          customClass: "swal-height",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getLease();
  }, []);

  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Lease | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="registration__page">
          <h3>
            {leaseType == "kombo-lease" ? (
              <>Kombo Lease Registration</>
            ) : (
              <>Provincial Lease</>
            )}{" "}
          </h3>
          <span className="underline"></span>
          <div className="registration__form__container">
            <h5>Registration Number</h5>
            <form className="form" onSubmit={submit}>
              <div className="two__column">
                <input
                  type="text"
                  placeholder="L.O.M.P"
                  value={lomp}
                  onChange={saveLomp}
                  disabled
                />
                <input
                  type="text"
                  placeholder="Location of Land"
                  value={landLocation}
                  onChange={saveLandLocation}
                />
              </div>
              <h5>Parties Involved</h5>
              <h5>Lessor</h5>
              {lessor.map((value, index) => {
                return (
                  <>
                    <div className="one__column">
                      <input
                        type="text"
                        placeholder="Company Name"
                        id="company_name"
                        value={value.company_name}
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="first_name"
                        value={value.first_name}
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Middle Name"
                        id="middle_name"
                        value={value.middle_name}
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        id="last_name"
                        value={value.last_name}
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="nin"
                        id="nin"
                        value={value.nin}
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        id="dob"
                        value={value.dob}
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Address"
                        id="address"
                        value={value.address}
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="Telephone 1"
                        id="telephone1"
                        value={value.telephone1}
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Telephone 2"
                        id="telephone2"
                        value={value.telephone2}
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Email"
                        id="email"
                        value={value.REACT_APP_DEVELOPMENT_API_URL}
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    <div className="add__more">
                      <button onClick={addLessorRow}>
                        <i className="fas fa-plus"></i>
                      </button>
                      {index >= 1 ? (
                        <button onClick={(e) => removeLessorRow(e, index)}>
                          <i className="fas fa-minus"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                );
              })}

              <h5>Lessee</h5>
              {lessee.map((value, index) => {
                return (
                  <>
                    <div className="one__column">
                      <input
                        type="text"
                        placeholder="Company Name"
                        id="company_name"
                        value={value.company_name}
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="first_name"
                        value={value.first_name}
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Middle Name"
                        id="middle_name"
                        value={value.middle_name}
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        id="last_name"
                        value={value.last_name}
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="nin"
                        id="nin"
                        value={value.nin}
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        id="dob"
                        value={value.dob}
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Address"
                        id="address"
                        value={value.address}
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="Telephone 1"
                        id="telephone1"
                        value={value.telephone1}
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Telephone 2"
                        id="telephone2"
                        value={value.telephone2}
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Email"
                        id="email"
                        value={value.email}
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    <div className="add__more">
                      <button onClick={addLesseeRow}>
                        <i className="fas fa-plus"></i>
                      </button>
                      {index >= 1 ? (
                        <button onClick={(e) => removeLesseeRow(e, index)}>
                          <i className="fas fa-minus"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                );
              })}
              <h5>Transaction Dates</h5>
              <div className="two__column">
                <div className="group">
                  <label>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </label>
                  <DatePicker
                    placeholderText="Execusion Date"
                    className="input-group"
                    selected={executionDate}
                    onChange={handleExecusionDate}
                    popperClassName="date-picker-popper"
                    showYearDropdown
                  />
                </div>
                <div className="group">
                  <label>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </label>
                  <DatePicker
                    placeholderText="Receipt Date"
                    className="input-group"
                    selected={dateOfReceipt}
                    onChange={handleDateOfReceipt}
                    popperClassName="date-picker-popper"
                    showYearDropdown
                  />
                </div>
              </div>
              <h5>Party Registering</h5>
              <div className="one__column">
                <input
                  type="text"
                  placeholder="Company Name"
                  id="company_name"
                  value={partyRegistering.company_name}
                  onChange={savePartyRegistering}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="First Name"
                  id="first_name"
                  value={partyRegistering.first_name}
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Middle Name"
                  id="middle_name"
                  value={partyRegistering.middle_name}
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  id="last_name"
                  value={partyRegistering.last_name}
                  onChange={savePartyRegistering}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="nin"
                  id="nin"
                  value={partyRegistering.nin}
                  onChange={savePartyRegistering}
                />
                <input
                  type="date"
                  placeholder="Date of Birth"
                  id="dob"
                  value={partyRegistering.dob}
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Address"
                  id="address"
                  value={partyRegistering.address}
                  onChange={savePartyRegistering}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="Telephone 1"
                  id="telephone1"
                  value={partyRegistering.telephone}
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Telephone 2"
                  id="telephone2"
                  value={partyRegistering.telephone2}
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  value={partyRegistering.email}
                  onChange={savePartyRegistering}
                />
              </div>
              <h5>Recipient</h5>
              {required ? <span style={{ color: "red" }}>Required</span> : null}
              <div className="one__column">
                <input
                  type="text"
                  placeholder="Company Name"
                  id="company_name"
                  value={recipient.company_name}
                  onChange={saveRecipient}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="First Name"
                  id="first_name"
                  value={recipient.first_name}
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Middle Name"
                  id="middle_name"
                  value={recipient.middle_name}
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  id="last_name"
                  value={recipient.last_name}
                  onChange={saveRecipient}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="nin"
                  id="nin"
                  value={recipient.nin}
                  onChange={saveRecipient}
                />
                <input
                  type="date"
                  placeholder="Date of Birth"
                  id="dob"
                  value={recipient.dob}
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Address"
                  id="address"
                  value={recipient.address}
                  onChange={saveRecipient}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="Telephone 1"
                  id="telephone1"
                  value={recipient.telephone1}
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Telephone 2"
                  id="telephone2"
                  value={recipient.telephone2}
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  value={recipient.email}
                  onChange={saveRecipient}
                />
              </div>
              {required ? <span style={{ color: "red" }}>Required</span> : null}
              <div className="three__column">
                <input
                  type="text"
                  placeholder="OR Number"
                  id="or_number"
                  value={recipient.or_number}
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Stamp Duty"
                  id="stamp_duty"
                  value={recipient.stamp_duty}
                  onChange={saveRecipient}
                />
              </div>
              <div className="one__column">
                <textarea
                  placeholder="Notes"
                  id="notes"
                  value={notes}
                  onChange={saveNotes}
                ></textarea>
              </div>
              <h5>Witness Information</h5>
              {witnessInformation.map((value, index) => {
                return (
                  <>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="first_name"
                        value={value.first_name}
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Middle Name"
                        id="middle_name"
                        value={value.middle_name}
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        id="last_name"
                        value={value.last_name}
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="ID Number"
                        id="nin"
                        value={value.nin}
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        id="dob"
                        value={value.dob}
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Telephone"
                        id="telephone"
                        value={value.telephone1}
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                    </div>
                    <div className="add__more">
                      <button onClick={addWitnessInformationRow}>
                        <i className="fas fa-plus"></i>
                      </button>
                      {index >= 1 ? (
                        <button
                          onClick={(e) => removeWitnessInformationRow(e, index)}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                );
              })}
              <h5>Location in archive</h5>
              <div className="two__column">
                <input
                  type="text"
                  placeholder="Box Number"
                  id="box_number"
                  value={location.box_number}
                  onChange={saveLocation}
                />
                <input
                  type="text"
                  placeholder="Location Number"
                  id="location_number"
                  value={location.location_number}
                  onChange={saveLocation}
                />
              </div>
              <input type="submit" value={loading ? "Loading..." : "Update"} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(EditLease);

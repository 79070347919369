import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import SubscriptionIcon from '../../../assets/imgs/subscription.png';
import LoadingScreen from '../../inc/LoadingScreen';

const ManageUsers = (props) => {
    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;

    const [page, setPage] = useState({page: page_no});
    const [pagination, setPagination] = useState({current_page: "", last_page: ""})
    const [loading, setLoading] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const subscriptionStyle = {
        color: '#696969',
    }
    const customButton1 = {
        // width: '100px',
        height: '40px',
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '5px',
        flex: '1 1',
        boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
        cursor: 'pointer',
        margin: '5px',


    }

    const noStyle = {
        backgroundColor: '#d33',

    }
    const yesStyle = {
        backgroundColor: 'rgb(52,110,57)',

    }

    const packStyle = {
        fontWeight: "100",
        background : "#696969"
    }

    const searchByStyle ={
        padding: '9px',
        // width: '70%',
        
        border: '1px solid #dddddd'
    }

    const [users, setUsers] = useState([]);

    const [search, setSearch] = useState({
        'param': '',
        'by': ''
    });
    const [searchLoading, setSearchLoading] = useState(false);

    const getUsers = async () => {
        setIsSearch(false);
        
        let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL : 
        process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/users?page=' + page.page, {headers: {'Content-Type': 'application/json'}, 
        credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setLoading(false);

            console.log(data)
            setUsers([...data.data.data]);
            setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
           
        }
    }

    const changeStatus = async (id, type) => {
        if(loading){
            return;
        }
        setLoading(true);

        let temp_user = users.filter((u)=> u.id == id)[0]
        temp_user = temp_user.first_name + " " + temp_user.middle_name + " " + temp_user.last_name;

        Swal.fire({
            title: 'Are you sure?',
            text: "The user \"" + temp_user  + "\" will be " + type + "d!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#17a2b8',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + type + ' them!'
          }).then(async (result) => {


            if (result.isConfirmed) {

                setLoadingInfo(true)

                let url = process.env.NODE_ENV === "development"?
                process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
                let response = await fetch(url + '/api/v1/toggle-user-type?user_id=' + id + '&type=' + type, 
                {method: 'POST',
                 headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();
        
                if(data.success) {
                    setLoadingInfo(false)

                    let msg = type.charAt(0).toUpperCase() + type.slice(1) + 'd'
                    await 
                      Swal.fire({
                        icon: "success",
                        title: msg,
                        confirmButtonColor: "#17a2b8",
                      });
                    window.location.reload();
                }
                setLoadingInfo(false)
    
    



            }
            setLoading(false);
            setLoadingInfo(false)

          }).catch(err =>{
            Swal.fire({
                icon: "error",
                title: "Failed!",
                text: "Operation was unsuccessful",
                confirmButtonColor: "#17a2b8",
              });
            setLoading(false);

          })

    }

    const openModal = async (pack) =>{
        if(loading){
            return;
        }
        setLoading(true);

        let temp_user = users.filter((u) => u.id == pack.user_id)[0]
        let full_name = temp_user.first_name + " " + temp_user.middle_name + " " + temp_user.last_name;

        if(temp_user.type === 0){
            Swal.fire({
                icon: "error",
                title: "Failed!",
                text: "You need to activate the user first!",
                confirmButtonColor: "#17a2b8",
              });
        setLoading(false);

        }
        else if(temp_user.type === 4){

        Swal.fire({
            title: pack.subscription_name,
            imageUrl: SubscriptionIcon,
            html:
              'This pending subscription belongs to the user <b>' + full_name + '</b> email : <b>' + temp_user.email + 
              '</b> <br>Amount: <b>'+ pack.subscription_amount + "</b>" +
              '<br>Cost: <b>D' + pack.subscription_cost + "</b>",

            showCloseButton: true,
            showDenyButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonColor: '#17a2b8',
            denyButtonColor: '#d33',
            confirmButtonText:
              '<i class="fa fa-check"></i> Accept',
            confirmButtonAriaLabel: 'Accept!',
            denyButtonText:
              '<i class="fas fa-times"></i> Reject',
            denyButtonAriaLabel: 'Reject'
          }).then(async (result) => {

            if (result.isConfirmed) {
                let url = process.env.NODE_ENV === "development"?
                process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
                let response = await fetch(url + '/api/v1/package-payment?id='+ pack.id + '&status=accept', 
                {method: 'POST',
                 headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();
        
                if(data.success) {
                    await Swal.fire({
                        icon: "success",
                        title: "Successfully subscribed!",
                        confirmButtonColor: "#17a2b8",
                      });
                    window.location.reload();
                }
    
            }
            else if(result.isDenied){
                let url = process.env.NODE_ENV === "development"?
                process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
                let response = await fetch(url + '/api/v1/package-payment?id='+ pack.id + '&status=reject', 
                {method: 'POST',
                 headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();
        
                if(data.success) {
                    
                    await Swal.fire({
                        icon: "error",
                        title: "Subscription rejected!",
                        confirmButtonColor: "#17a2b8",
                      });

                    window.location.reload();
                }
            }
            setLoading(false);

          }).catch(err =>{
            Swal.fire('Operation was unsuccessful', '', 'error')
            setLoading(false);

          })

        }

    }

    const deleteUser = (id) => {
        if(loading){
            return;
        }
        let temp_user = users.filter((u)=> u.id == id)[0]
        temp_user = temp_user.first_name + " " + temp_user.middle_name + " " + temp_user.last_name;

        setLoading(true);
        Swal.fire({
            title: 'Are you sure?',
            html: "The user <b>\"" + temp_user  + "\"</b> will be deleted!<br> You wont be able to revert this",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#17a2b8',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Yes, delete "' + temp_user + '"'
          }).then(async (result) => {


            if (result.isConfirmed) {

                setLoadingInfo(true)
                let url = process.env.NODE_ENV === "development"?
                process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
                let response = await fetch(url + '/api/v1/delete-user?id=' + id, 
                {method: 'DELETE',
                 headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();
        
                if(data.success) {
                    setLoading(false);
                    await Swal.fire({
                        icon: "success",
                        title: "Deleted!",
                        confirmButtonColor: "#17a2b8",
                      });
                    window.location.reload();
                }
                setLoadingInfo(false)

    


            }else{
            setLoading(false);

            }

          }).catch(err =>{
            Swal.fire({
                icon: "error",
                title: "Failed!",
                text: "Deletion was unsuccessful",
                confirmButtonColor: "#17a2b8",
              });

          })


        setLoading(false);
    }



    const searchUser = async (event) => {
        if(loading){
            return;
          }
        event.preventDefault();
        setSearchLoading(true);
        let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL + '/api/v1/search-user?param=' + search['param'] + '&by=' + search['by'] : 
        process.env.REACT_APP_PRODUCTION_API_URL + '/api/v1/search-user?param=' + search['param'] + '&by=' + search['by'];

        let response = await fetch(url, {headers: {'Content-Type': 'application/json'}, credentials: 'include'});
        let data = await response.json();

        if(data.success) {
           
            
            setUsers([...data.data]);
            setSearchLoading(false);
            setIsSearch(true);
        }
        setSearchLoading(false);
    }


    const handleSearch = (event) => {
        if(loading){
            return;
          }
        setSearch({
            ...search,
            [event.target.id] : event.target.value

        });
    }



    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/admin/manage-users?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/admin/manage-users?page=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/admin/manage-users?page=' + currentPage);
         }
         window.scrollTo(0,0);
         
     }

    useEffect(() => {
        getUsers();
    }, [page]);

    return (
        <div className="dashboard__container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">Manage Users</div>
                <div className="add-search">
                    <div className="add">
                        <a href="/admin/manage-users/add">Add User <i className="fas fa-plus"></i></a>
                    </div>
                    <div className="search">
                        <form onSubmit={searchUser}>
                            <select name="" id="by" onChange={handleSearch}  style={searchByStyle}>
                                <option value=""  disabled selected >Search by</option>
                                <option value="name">Name</option>
                                <option value="username">Username</option>
                                <option value="token">Token</option>
                                <option value="email">Email</option>

                            </select>
                            <input type="text" placeholder="Search" id="param" onChange={handleSearch}/>
                            <button type="submit" disabled={search['param'].length > 3 && search['by'] != ''? false : true}>{searchLoading? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-search"></i>}</button>
                        </form>
                    </div>
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                <table>
                       <thead>
                           <tr>
                               <th>Name</th>
                               <th>Email</th>
                               <th>Telephone 1</th>
                               <th>Status</th>
                               <th>Pending Subscriptions</th>
                               <th>Subscriptions</th>
                               <th>Edit</th>
                               <th>Delete</th>
                           </tr>
                       </thead>
                       <tbody>
                           {users.map((value) => {
                               return (
                                    <tr key={value.id}>
                                        <td>{value.first_name + " "
                                         + value.middle_name + " "
                                         + value.last_name}</td>
                                         <td>{value.email}</td>
                                         <td>{value.telephone1}</td>
                                         <td>
                                             <ul>
                                                 {
                                                    value.type === 0 ? (
                                                        <li className='' onClick={()=> changeStatus(value.id, 'activate')}  style={{...customButton1, ...yesStyle}}>Activate</li>

                                                     ): (
                                                        <li className='' >Activated</li>

                                                     )
                                                 }
                                             </ul>
                                         </td>
                                         <td>
                                             <ul>
                                            {value.pending_packages.length >= 1? (
                                                <>
                                                {value.pending_packages.map((pack)=>{
                                                    {
                                                        return(
                                                            <li>
                                                                <a href="#" onClick={()=>openModal(pack)} style={{...customButton1, ...packStyle}}>{pack.subscription_name}</a>
                                                            </li>
                                                    
                                                        )
                                                     }
                                                })

                                                }
                                             </>) : null}
                                             </ul>
                                         </td>
                                        
                                        
                                        {/* subs */}
                                         <td>
                                         <ul>
                                            {value.subscribed_packages.length >= 1? (
                                                <>
                                                { value.subscribed_packages.map((pack)=>{
                                                    {
                                                        return(
                                                            <li style={{display:'block'}}>
                                                                <span  style={subscriptionStyle}>{pack.subscription_name}</span>
                                                            </li>
                                                    
                                                        )
                                                     }
                                                 })}
                                                </>
                                            ) : null}
                                             </ul>
                                         </td>



                                         <td>
                                             <ul>
                                                 <li className="btn2">
                                                 
                                                 <Link to={"/admin/manage-users/edit/" + value.id}><i className="fas fa-edit"></i></Link>
                                                 </li>
                                             </ul>
                                         </td>


                                         <td>
                                             <ul>
                                                 <li  onClick={() => deleteUser(value.id)} className="btn2 bg-danger"><i className="fas fa-trash"></i></li>
                                             </ul>
                                         </td>
                                    </tr>

                               )
                           })}
                       </tbody>
                   </table>
                </div>
                {pagination.last_page >= 2 && !isSearch? (
                    <>
                      <div className="pagination">
                        <button className="pagination__btn"  
                        disabled={pagination.lastPage === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                        <button className="pagination__btn" 
                        disabled={ Number(page.page_no) === pagination.lastPage ? true : false} 
                        onClick={next}>{loading? 
                        <i className="fas fa-spinner fa-spin"></i> : 'Next'}</button>
                    </div>
                    </>
                ): null}
            </div>
        
        <LoadingScreen show={loadingInfo} />
        
        </div>
    )
}

export default ManageUsers;

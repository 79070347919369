import React, { useState, useEffect } from 'react';
import Logo from '../../assets/imgs/icon.png';
import { Helmet } from 'react-helmet';
import CertificateBorder from '../../assets/imgs/second-certificate.jpeg';
import { Redirect } from 'react-router';

const Confirmation = () => {

    const [confirmation, setConfirmation] = useState({userInfo:{}, 
        companyInfo: {},
        subscriptionInfo: []});
    const [redirect, setRedirect] = useState(false);
    const printBtn = {
        width: '100px',
        height: '40px',
        border: 'none',
        backgroundColor: '#f5365c',
        borderRadius: '5px',
        cursor: 'pointer'
    }

    const [plans, setPlans] = useState([])

    const getConfirmationData = async () => {
        let url = process.env.NODE_ENV === "development"?
             process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        let response = await fetch(url + '/api/v1/confirmation', {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setConfirmation({userInfo: {...data.data.userInfo[0]}, 
                companyInfo: {...data.data.companyInfo[0]},
                subscriptionInfo: [...data.data.subscriptionInfo]
                
            
            });
            let temp_plans = []
            let i = 1

            data.data.subscriptionInfo.forEach(element => {
                let obj_plan = {"id": i, "type": ''}
                obj_plan.type = element.subscription_type
                temp_plans.push(obj_plan)
                i++;
            });
            setPlans([...temp_plans])

           
        } else {
            setRedirect(true);
        }

    }

    useEffect(() => {
        getConfirmationData();
    }, []);

    if(redirect) {
        return <Redirect to="/user/register" />
    }

    return (
        <>
          <div className="confirm_registration">
              <Helmet>
                  <meta charSet="utf-8" />
                  <title>Confirmation Page</title>
              </Helmet>
              <button style={printBtn} onClick={() => window.print()}>Print</button>
          <div className="confirmation" style={{backgroundImage: CertificateBorder, width: '100%', height: '100%',WebkitPrintColorAdjust: 'exact'}}>
                              <div className="logo">
                                  <img src={Logo} style={{maxHeight: '15%', maxWidth: '15%'}} />
                                  <h2>CONFIRMATION</h2>
                              </div>
                              <div className="print__details">
                                  <div className="print__title">
                                       <h4>Registration Info</h4>
                                  </div>
                                  <div className="column">
                                      <div className="one__column">
                                          <h5>Token</h5>
                                          <p>{confirmation.userInfo.token}</p>
                                      </div>
                                      {/* <div className="one__column">
                                          <h5>SR. Number</h5>
                                          <p>283487324</p>
                                      </div>
                                      <div className="one__column">
                                          <h5>Location Of Land</h5>
                                          <p>busumbala</p>
                                      </div> */}
                                  </div>
                                  <div className="print__title">
                                       <h4>User Info</h4>
                                  </div>
                                  <div className="column">
                                      <div className="one__column">
                                          <h5>First Name</h5>
                                          <p>{confirmation.userInfo.first_name}</p>
                                      </div>
                                      <div className="one__column">
                                          <h5>Middle Name</h5>
                                          <p>{confirmation.userInfo.middle_name}</p>
                                      </div>
                                      <div className="one__column">
                                          <h5>Last Name</h5>
                                          <p>{confirmation.userInfo.last_name}</p>
                                      </div>
                                  </div>
                                  <div className="column">
                                  <div className="one__column">
                                          <h5>Email</h5>
                                          <p>{confirmation.userInfo.email}</p>
                                      </div>
                                      <div className="one__column">
                                          <h5>NIN</h5>
                                          <p>{confirmation.userInfo.nin}</p>
                                      </div>
                                      <div className="one__column">
                                          <h5>TIN</h5>
                                          <p>{confirmation.userInfo.tin}</p>
                                      </div>
                                  </div>
                                  <div className="column">
                                      <div className="one__column">
                                          <h5>Telephone 1</h5>
                                          <p>{confirmation.userInfo.telephone1}</p>
                                      </div>
                                      <div className="one__column">
                                          <h5>Telephone 2</h5>
                                          <p>{confirmation.userInfo.telephone2}</p>
                                      </div>
                                      
                                  </div>
                                  <div className="print__title">
                                    <h4>Company Info</h4>
                                </div>
                                <div className="column">
                                    <div className="one__column">
                                        <h5>Company Name</h5>
                                        <p>{confirmation.companyInfo.name}</p>
                                    </div>
                                    <div className="one__column">
                                        <h5>Address</h5>
                                        <p>{confirmation.companyInfo.address}</p>
                                    </div>
                                    <div className="one__column">
                                        <h5>Email</h5>
                                        <p>{confirmation.companyInfo.email}</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="one__column">
                                        <h5>TIN</h5>
                                        <p>{confirmation.companyInfo.tin}</p>
                                    </div>
                                    <div className="one__column">
                                        <h5>Telephone 1</h5>
                                        <p>{confirmation.companyInfo.telephone1}</p>
                                    </div>
                                    <div className="one__column">
                                        <h5>Telephone 2</h5>
                                        <p>{confirmation.companyInfo.telephone2}</p>
                                    </div>
                                    <div className="one__column">
                                        <h5>Business Type</h5>
                                        <p>{confirmation.companyInfo.type}</p>
                                    </div>
                                </div>
                               
                                <div className="print__title">
                                    <h4>Subscription Package</h4>
                                </div>
                                <div className="column">
                                    {
                                        plans.map((value)=>{
                                            return (
                                                <div key={value.key} className="one__column subscription_package_list">
                                                    <h5>{value.type}</h5>

                                                    <ul className=''>
                                                        {
                                                            confirmation.subscriptionInfo.map((sub)=>{
                                                                if (sub.subscription_type == value.type)
                                                                {
                                                                    return(
                                                                        <li>
                                                                           <span>{sub.subscription_name}</span>
                                                                           <ol>
                                                                               <li>Cost: {sub.subscription_cost}</li>
                                                                               <li>Subscription Amount: {sub.subscription_amount}</li>
                                                                           </ol>
                                                                        </li>
    
                                                                    )
    
                                                                }

                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                                
                              </div>
                            </div>
          </div>
        </>
    )
}

export default Confirmation

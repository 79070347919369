import React, { useState, useEffect } from 'react';
import Sidebar from '../inc/Sidebar';
import TitleHeader from '../inc/TitleHeader';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const UserProfile = (props) => {
    const [user, setUser] = useState({
        "first_name": "",
        "middle_name": "",
        "last_name": "",
        "password": "",
        "confirmPassword": "",
        "nin": "",
        "telephone1": "",
        "telephone2": "",
        "email": ""
    });
    const [loading, setLoading] = useState(false);
    const [passwortNotMatch, setPasswordNotMatch] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [notUpdated, setNotUpdated] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [pdfAllowed, setPDFAllowed] = useState(false);

    const getAuthUser = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/user', 
        {headers: {'Content-Type': 'application/json'}, 
        credentials: 'include'});
        let data = await response.json();

        if(data) {
            setUser({...data.data});
        }
    }

    const uploadSignature = (event) => {
        setUploading(true);
        let file = event.target.files[0];
        const data = new FormData();
        data.append('signature', file);
  
        if(file.type !== "image/jpeg") {
            setPDFAllowed(true);
            setLoading(false);
            return;
        }
  
        let url = process.env.NODE_ENV === "development"?
          process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
  
      axios.post(url + '/api/v1/upload-signature',data,{headers: 
          {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': `multipart/form-data; boundary=${data._boundary}`  
          }, withCredentials: true})
        .then(async (response) => {
          if(response.success) {
              setUploading(false);
              setPDFAllowed(false);
          } else {
                setUploading(false);
                setPDFAllowed(false);
          }
        }).catch((err) => {
         
        });
    }

    const submit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;


        if(user.password !== user.confirmPassword) {
            setPasswordNotMatch(true);
            setLoading(false);
            return;
        }

        let response = await fetch(url + '/api/v1/update-profile', {credentials: 'include',method: 'PUT', 
        body: JSON.stringify(user), headers: {'Content-Type': 'application/json'}});
        let data = await response.json();

        if(data) {
            if(data.success) {
                setLoading(false);
                setUpdated(true);
                setNotUpdated(false);
            } else {
                setLoading(false);
                setUpdated(false);
                setNotUpdated(true);
            }
            setLoading(false);
            window.scrollTo(0,0);
        }
    }

    const handleChange = (event) => {
        setUser({...user, [event.target.id]: event.target.value});
    }

    useEffect(() => {
        getAuthUser();
    },[]);

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Profile</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  <h3>My Profile</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                   {notUpdated? (
                       <>
                        <div style={{backgroundColor: 'red', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Profile Not Updated</h6>
                            </div>
                       </>
                   ): null}
                   {updated? (
                       <>
                        <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Profile Updated</h6>
                            </div>
                       </>
                   ): null}
                  
                      <form className="form__add_plan" onSubmit={ submit }>
                           <div className="one__column">
                               <input type="text" placeholder="First Name" value={user.first_name} onChange={handleChange} id="firstName"/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Middle Name" onChange={handleChange} value={user.middle_name} id="middleName" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Last Name" onChange={handleChange} value={user.last_name} id="lastName"/>
                           </div>
                           <div className="one__column">
                               <input type="password" placeholder="Password" onChange={handleChange} id="password"/>
                           </div>
                           <div className="one__column">
                               <input type="password" placeholder="Confirm Password" onChange={handleChange} id="confirmPassword"/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="NIN" onChange={handleChange} value={user.nin} id="nin" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Telephone 1" onChange={handleChange} value={user.telephone1} id="telephone1" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Telephone 2" onChange={handleChange} value={user.telephone2} id="telephone2" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Email" disabled onChange={handleChange} value={user.email} id="email" />
                           </div>
                           {user.type === 2? (
                               <>
                               <div className="one__column">
                                 {uploading? <span style={{color: 'blue'}}>Uploading...</span> : null}
                               <div className="group">
                                   <label>Signature</label>
                                   <input type="file" name="signature" onChange={uploadSignature}/>
                               </div>
                           </div>
                               </>
                           ) : null}
                          <input type="submit" disabled={uploading} value={loading? 'Loading...' : "Update"} />
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default withRouter(UserProfile);

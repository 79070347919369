import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';

const SubscribeButton = (props) => {


    const [statusText, setStatusText] = useState('')

    const changeSubStatus = async () =>{
        if(props.isPending){


            Swal.fire({
                title: 'Are you sure you wish to Unsubscribe?',
                text: "This will unsubscribe you from the \"" + props.subName + "\" package",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#17a2b8',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, unsubscribed me!'
              }).then(async (result) => {
    

    
                if (result.isConfirmed) {
                    let url = process.env.NODE_ENV === "development"?
                    process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
                    let response = await fetch(url + '/api/v1/package-subscription?id=' + props.subId + '&status=unsub',
                    {credentials: 'include',
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'}}
                    );
                    let data = await response.json();
            
                    
            
                    if(data.success) {
                     
                        await Swal.fire(
                            'You have been successfully unsubscribed',
                            '',
                            'success'
                          )
                        window.location.reload();
                    }
        
        
    
    
    
                }
              }).catch(err =>{
                Swal.fire('Unsubscription was unsuccessful', '', 'error')
              })

        }
        else{
            Swal.fire({
                title: 'Are you sure you wish to Subscribe?',
                text: "This will subscribe you to the \"" + props.subName + "\" package",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#17a2b8',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, subscribed me!'
              }).then(async (result) => {
    
                if (result.isConfirmed) {
                  let url = process.env.NODE_ENV === "development"?
                  process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
                  let response = await fetch(url + '/api/v1/package-subscription?id=' + props.subId + '&status=sub',
                  {credentials: 'include',
                  method: 'POST',
                  headers: {'Content-Type': 'application/json'}}
                  );
                  let data = await response.json();
          
                    if(data.success) {
                     
                        await Swal.fire(
                            'You have been successfully unsubscribed',
                            '',
                            'success'
                          )
                        window.location.reload();
                    }
        
        
    
    
    
                }
              }).catch(err =>{
                Swal.fire('Subscription was unsuccessful', '', 'error')
              })


            
        }

        // code
    }

    useEffect(() => {
        if(props.isPending){
            setStatusText("Unsubscribe")
        }
        else{
            setStatusText("Subscribe")
        }

    }, []);

    return (
        <>
          <button
          style={{
              fontSize: '1.1rem'
          }}
          onClick={() => changeSubStatus()}>{
              statusText
              }

              
            </button>  
        </>
    )
}

export default SubscribeButton

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

const LogOut = (props) => {
  const [redirect, setRedirect] = useState(false);
  const logOut = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL + "/api/v1/logout"
        : process.env.REACT_APP_PRODUCTION_API_URL + "/api/v1/logout";
    let response = await fetch(url, { credentials: "include" });
    let data = await response.json();

    if (data.success) {
      localStorage.removeItem("success");
      localStorage.removeItem("type");
      localStorage.removeItem("id");
      window.location.reload();
    } else {
      localStorage.removeItem("success");
      localStorage.removeItem("type");
      localStorage.removeItem("id");
      window.location.reload();
    }
  };

  useEffect(() => {
    logOut();
  }, [redirect]);
  return (
    <div className="wrapper-2">
      <div className="wrapper-2-gradient">
        <div className="login__container">
          <div className="login text-center">
            <h1> Logging out! </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LogOut);

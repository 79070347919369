import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const Chart1SA = (props) => {
  // chartData per month [all months]

  const [chartData, setChartData] = useState({});
  const [stats, setStats] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [plans, setPlans] = useState([]);

  const getChartData = async (id) => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/chart-1-sa?plan=" + id, {
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setChartData({
        data: {
          labels: data.data.labels,
          datasets: [
            {
              label: "Sold Subscriptions",
              data: data.data.count_cost,
              borderColor: "#8b054d",
              backgroundColor: "rgba(130, 160, 188, 0.8)",
              fill: true,
            },
          ],
        },
      });
    }

    // console.log(props.year)
  };
  const getPlans = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/all-plans", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      if (data.success) {
        setPlans([...data.data]);
        if (data.data.length >= 1) {
          setSelectedPlan(data.data[0].name);
          getChartData(data.data[0].id);
        }
      }
    }
  };

  const getDashboardStats = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/dashboard-stats-sa", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      // get the plans
      //get the subscription plans under the plans
      setStats({ ...data.data });

      // console.log(data.data);
    }
  };

  const handleClick = (id, name) => {
    setSelectedPlan(name);
    getChartData(id);

    var elements = document.getElementsByClassName("nav-link");

    Array.prototype.forEach.call(elements, function (e) {
      // Do stuff here
      e.classList.remove("active");
    });

    var ele = document.getElementById(id);
    ele.className += " active";
  };

  useEffect(async () => {
    if (localStorage.getItem("type") && localStorage.getItem("type") === "SA") {
      getPlans();
      getDashboardStats();
    }
  }, []);

  return (
    <div>

      {
        stats != null ?

        (
          <>
      <div className="wrapper-3">
        <div className="wrapper-3-gradient">
          <div className="mb-5 py-5 text-white ">
            <div className="row">
              <div className=" col-4 px-5 pt-3 pb-2 border-right">
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className=" text-bold text-sm">
                      Packages bought this week
                    </span>
                  </div>
                  <div className="col-12">
                    <span
                      style={{ fontSize: "2.4rem" }}
                      className=" text-bold "
                    >
                      {stats['packages']['this_week']}
                    </span>
                  </div>
                  <div
                    className="col-12 text-sm text-bold"
                    style={{ fontSize: "0.9rem" }}
                  >
                    <span className="">All time:</span>
                    <span className="ps-1 ">{stats['packages']['all_time']}</span>
                  </div>
                </div>
              </div>
              <div className="col-4 px-5 pt-3 pb-2 border-right">
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className=" text-bold text-sm">
                      Pending subscriptions
                    </span>
                  </div>
                  <div className="col-12">
                    <span
                      style={{ fontSize: "2.4rem" }}
                      className=" text-bold"
                    >
                      {stats["pending_subs"]}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 px-5 pt-3 pb-2 border-right">
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className=" text-bold text-sm">New Users</span>
                  </div>
                  <div className="col-12">
                    <span
                      style={{ fontSize: "2.4rem" }}
                      className=" text-bold"
                    >
                      {stats["users"]["new_users"]}
                    </span>
                  </div>
                  <div
                    className="col-12 text-sm text-bold"
                    style={{ fontSize: "0.9rem" }}
                  >
                    <span className=" ">Total users:</span>
                      <span className="ps-1">
                        {stats["users"]["total_users"]}
                      </span>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </div>

      <div className="custom-card-1 mt-3 mx-4">
        <div className="d-flex ms-2">
          <ul className="nav nav-pills  nav-fill">
            {plans.map((value, index) => {
              return (
                <li key={value.id} className="nav-item me-2">
                  <a
                    id={value.id}
                    onClick={() => handleClick(value.id, value.name)}
                    className={`nav-link ${index === 0 ? "active" : ""}`}
                    href="#"
                  >
                    {value.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        {selectedPlan != 0 ? (
          <div>
            <Bar
              data={chartData.data}
              width={100}
              height={500}
              options={{
                maintainAspectRatio: false,
                //   responsive: true,
                scales: {
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: "Dalasis",
                      },
                    },
                  ],
                  xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: selectedPlan,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        ) : null}
      </div>
 
          </>
        ) : null
      }


 
    </div>
  );
};

export default Chart1SA;

import React, { useState, useEffect, useContext } from "react";

import Swal from "sweetalert2";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../../../App";
import LoadingScreen from "../../inc/LoadingScreen";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import moment from "moment";

const DeedComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);

  let deed_or_mortage =
    props.deedType == "kombo-mortgage" ||
    props.deedType == "provincial-mortgage"
      ? "/mortgage/"
      : "/deed/";

  function convertToDDMMYYYY(dateString) {
    const date = moment(dateString, [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      // Add more formats as needed
    ]);

    if (date.isValid()) {
      return date.format("DD-MM-YYYY");
    } else {
      return "Invalid Date";
    }
  }

  const deleteDeed = async (bookID) => {
    if (loading) {
      return;
    }
    setLoading(true);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      cancelButtonColor: "#f5365c",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          let url =
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_DEVELOPMENT_API_URL
              : process.env.REACT_APP_PRODUCTION_API_URL;

          let response = await fetch(
            url + "/api/v1/deed/delete?book_id=" + bookID,
            {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          let data = await response.json();

          if (data.success) {
            setLoading(false);
            await Swal.fire({
              icon: "success",
              title: "Deleted!",
              confirmButtonColor: "#17a2b8",
            });
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "Operation was unsuccessful",
          confirmButtonColor: "#17a2b8",
        });
        setLoading(false);
      });

    setLoading(false);
  };

  const approve = async (bookID, type, userType) => {
    if (loading) {
      return;
    }
    setLoading(true);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#66cd9a",
      cancelButtonColor: "#f5365c",
      confirmButtonText: "Approve",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingInfo(true);

        let url =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT_API_URL +
              "/api/v1/approve?book_id=" +
              bookID +
              "&type=" +
              type +
              "&userType=" +
              userType
            : process.env.REACT_APP_PRODUCTION_API_URL +
              "/api/v1/approve?book_id=" +
              bookID +
              "&type=" +
              type +
              "&userType=" +
              userType;
        let response = await fetch(url, {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        let data = await response.json();

        if (data.success) {
          setLoadingInfo(false);

          await Swal.fire({
            icon: "success",
            title: "Approved!",
            confirmButtonColor: "#17a2b8",
          });
          props.history.push("/");
          props.history.push(deed_or_mortage + props.deedType);
        } else {
          setLoadingInfo(false);

          await Swal.fire({
            icon: "error",
            title: "Failed!",
            text: "Operation was unsuccessful",
            confirmButtonColor: "#17a2b8",
          });
          setLoading(false);
        }
      }
    });
  };

  const reject = async (bookID, type) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Rejection Message",
      inputPlaceholder: "Reason for rejection..",
      inputAttributes: {
        "aria-label": "Reason of reject",
      },
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: "Submit",
    });

    if (text) {
      setLoadingInfo(true);

      let url =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_API_URL + "/api/v1/reject"
          : process.env.REACT_APP_PRODUCTION_API_URL + "/api/v1/reject";
      let param = { bookID: bookID, type: type, reason: text };
      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(param),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      let data = await response.json();

      if (data.success) {
        setLoadingInfo(false);

        await Swal.fire({
          title: "Rejected",
          text: "",
          confirmButtonColor: "#17a2b8",
        });
        window.location.href =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT +
              deed_or_mortage +
              props.deedType
            : process.env.REACT_APP_PRODUCTION +
              deed_or_mortage +
              props.deedType;
      } else {
        setLoadingInfo(false);

        await Swal.fire({
          icon: "error",
          title: "Error!",
          text: "",
          confirmButtonColor: "#17a2b8",
        });
        setLoading(false);
      }
    } else {
      await Swal.fire({
        icon: "error",
        title: "Empty! Rejection",
        text: "",
        confirmButtonColor: "#17a2b8",
      });
      setLoading(false);
    }

    setLoading(false);
  };

  const getRejectionReson = async (bookID) => {
    if (loadingInfo) {
      return;
    }

    setLoadingInfo(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/reject/reason?book_id=" +
          bookID
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/reject/reason?book_id=" +
          bookID;

    let response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setLoadingInfo(false);
      Swal.fire({
        title: data.data,
        confirmButtonColor: "#17a2b8",
      });
    }
    setLoadingInfo(false);
  };

  const authUser = useContext(AuthContext);

  if (props.pending) {
    return <LoadingSpinner />;
  }

  return (
    <div className="table__container" style={{ overflowX: "auto" }}>
      <table>
        <thead>
          <tr>
            <th>Ref. Number</th>
            <th>Serial Number</th>
            <th>Date of Receipt</th>
            <th>Nature of Instrument</th>
            <th>Location of Land</th>
            <th>Date of Execution</th>
            <th>Grantor</th>
            <th>Grantee</th>
            <th>Party Registering</th>
            <th>OR Number</th>
            {authUser.type === "RA" || authUser.type === "user" ? (
              <>
                <th>Document</th>
                <th>Status</th>
                <th>Action</th>
              </>
            ) : (
              <>
                <th>Documents</th>

                <th>Status</th>
                <th>Details</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {props.genericDeeds.length >= 1 ? (
            props.genericDeeds.map((value) => {
              return (
                <>
                  <tr key={value.id}>
                    <td>{value.serial_number}</td>
                    <td>{value.reference_number}</td>
                    <td>{convertToDDMMYYYY(value.recipient_date)}</td>
                    <td>
                      {value.instrumentType[0]
                        ? value.instrumentType[0].type
                        : "N/A"}
                    </td>
                    <td>{value.land_situation}</td>
                    <td>{convertToDDMMYYYY(value.execution_date)}</td>
                    <td>
                      {value.grantor[0]
                        ? value.grantor[0].first_name +
                          " " +
                          value.grantor[0].last_name
                        : "N/A"}
                    </td>
                    <td>
                      {value.grantee[0]
                        ? value.grantee[0].first_name +
                          " " +
                          value.grantee[0].last_name +
                          " " +
                          value.grantee[0].nin
                        : "N/A"}
                    </td>
                    <td>
                      {value.party[0]
                        ? value.party[0].first_name +
                          " " +
                          value.party[0].last_name +
                          " " +
                          value.party[0].nin
                        : "N/A"}
                    </td>
                    <td>{value.or_number}</td>
                    {authUser.type === "RA" || authUser.type === "user" ? (
                      <>
                        <td>
                          <ul>
                            {authUser.type === "RA" && (
                              <>
                                <li>
                                  <Link
                                    to={
                                      "/add/document/" +
                                      value.registration_type +
                                      "/" +
                                      value.id
                                    }
                                    className="add"
                                  >
                                    Add
                                  </Link>
                                </li>
                              </>
                            )}
                            <li>
                              <Link
                                to={"/document/view/" + value.id}
                                className="view"
                              >
                                View
                              </Link>
                            </li>
                          </ul>
                        </td>
                        <td>
                          {value.status === "Approved RA" &&
                          authUser.type !== "RG" ? (
                            <>
                              <button className="approved__btn_RA">
                                Approved By RA
                              </button>
                            </>
                          ) : null}
                          {value.status === "Approved RG" ? (
                            <>
                              <button className="approved__btn">
                                Approved By RG
                              </button>
                            </>
                          ) : null}
                          {value.status === "Approved User" &&
                          authUser.type === "user" ? (
                            <>
                              <button className="approved__btn_user">
                                Approved By User
                              </button>
                            </>
                          ) : null}
                          {value.status === "Rejected User" ||
                          value.status === "Rejected RA" ||
                          value.status === "Rejected RG" ||
                          value.status === "Rejected" ? (
                            <>
                              <button className="rejected__btn">
                                {value.status == "Rejected User" ? (
                                  <>Rejected by User </>
                                ) : value.status == "Rejected RA" ? (
                                  <>Rejected by RA </>
                                ) : value.status == "Rejected RG" ? (
                                  <>Rejected by RG </>
                                ) : (
                                  <>Rejected</>
                                )}
                              </button>
                              <button
                                className="info__btn"
                                onClick={() => getRejectionReson(value.id)}
                              >
                                {loadingInfo ? (
                                  <i className="fas fa-spinner fa-spin"></i>
                                ) : (
                                  "info"
                                )}
                              </button>
                            </>
                          ) : null}
                          {value.status === "Rejected" &&
                          value.status === "Approved RA" &&
                          authUser.type === "RA" ? (
                            <>
                              <ul>
                                <li>
                                  <Link
                                    to={"/drb/edit/" + value.id}
                                    className="view"
                                  >
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={
                                      "/deed/" +
                                      props.deedType +
                                      "/view/" +
                                      value.id
                                    }
                                    className="view"
                                  >
                                    View
                                  </Link>
                                </li>
                                <li>
                                  <button
                                    className="delete__btn"
                                    onClick={() => deleteDeed(value.id)}
                                  >
                                    {loading ? (
                                      <i className="fas fa-spinner fa-spin"></i>
                                    ) : (
                                      "Delete"
                                    )}
                                  </button>
                                </li>
                              </ul>
                            </>
                          ) : null}
                          {}
                          {value.status === null ||
                          (value.status === "Approved User" &&
                            authUser.type === "RA") ||
                          (value.status === "Pending User" &&
                            authUser.type === "user") ||
                          (value.status === "Pending RA" &&
                            authUser.type === "RA") ? (
                            <>
                              <button
                                className="btn__approve"
                                onClick={() =>
                                  approve(
                                    value.id,
                                    value.registration_type,
                                    authUser.type
                                  )
                                }
                              >
                                Approve
                              </button>
                              <button
                                className="btn__reject"
                                onClick={() =>
                                  reject(
                                    value.id,
                                    value.registration_type,
                                    authUser.type
                                  )
                                }
                              >
                                Reject
                              </button>
                            </>
                          ) : null}
                        </td>

                        <td>
                          <ul>
                            {value.status !== "Pending User" &&
                              (authUser.type === "RA" ||
                                authUser.type === "RG") &&
                              value.status !== "Approved RA" &&
                              authUser.type === "RG"}
                            <li>
                              <Link
                                to={
                                  "/deed/" +
                                  props.deedType +
                                  "/view/" +
                                  value.id
                                }
                                className="view"
                              >
                                View
                              </Link>
                            </li>
                          </ul>
                          {value.status === "Rejected User" ||
                          value.status === null ||
                          (value.status === "Pending User" &&
                            authUser.type === "user") ||
                          (value.status === "Pending RA" &&
                            authUser.type === "RA") ||
                          value.status === "Rejected RA" ||
                          value.status === "Rejected RG" ||
                          value.status === "Rejected" ||
                          value.status === "Approved RA" ? (
                            <ul>
                              {value.status !== "Rejected User" ? (
                                <li>
                                  <Link
                                    to={"/drb/edit/" + value.id}
                                    className="view"
                                  >
                                    Edit
                                  </Link>
                                </li>
                              ) : null}

                              <li>
                                <button
                                  className="delete__btn"
                                  onClick={() => deleteDeed(value.id)}
                                >
                                  {loading ? (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  ) : (
                                    "Delete"
                                  )}
                                </button>
                              </li>
                            </ul>
                          ) : null}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <ul>
                            {/* {value.status === "Approved RA" ? (
                              <li>
                                <Link
                                  to={
                                    "/add/document/" +
                                    value.registration_type +
                                    "/" +
                                    value.id
                                  }
                                  className="add"
                                >
                                  Add
                                </Link>
                              </li>
                            ) : null} */}

                            <li>
                              <Link
                                to={"/document/view/" + value.id}
                                className="view"
                              >
                                View
                              </Link>
                            </li>
                          </ul>
                        </td>

                        <td>
                          <ul>
                            {value.status === "Approved RA" ? (
                              <>
                                <button
                                  className="btn__approve"
                                  onClick={() =>
                                    approve(
                                      value.id,
                                      value.registration_type,
                                      authUser.type
                                    )
                                  }
                                >
                                  Approve
                                </button>
                              </>
                            ) : value.status === "Approved RG" ? (
                              <>
                                <li>
                                  <button className="approved__btn">
                                    Approved By RG
                                  </button>
                                </li>
                              </>
                            ) : value.status === "Rejected RG" ? (
                              <>
                                <li>
                                  <button
                                    style={{
                                      color: "#fff",
                                      width: "100%",
                                      marginBottom: "5px",
                                      border: "none",
                                      backgroundColor: "red",
                                      padding: "5px",
                                      borderRadius: "5px",
                                    }}
                                    className="approved__btn"
                                  >
                                    Rejected by RG
                                  </button>
                                </li>
                              </>
                            ) : value.status === "Rejected" ? (
                              <>
                                <button
                                  className="btn__reject"
                                  style={{ background: "red", color: "#fff" }}
                                >
                                  rejected
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn__approve"
                                  disabled={true}
                                >
                                  Pending
                                </button>
                                <button
                                  className="btn__reject"
                                  onClick={() =>
                                    reject(
                                      value.id,
                                      value.registration_type,
                                      authUser.type
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </>
                            )}
                            {value.status == "Approved RA" ||
                            value.status == "Approved RG" ? (
                              <button
                                className="btn__reject"
                                onClick={() =>
                                  reject(
                                    value.id,
                                    value.registration_type,
                                    authUser.type
                                  )
                                }
                              >
                                Reject
                              </button>
                            ) : null}
                          </ul>
                        </td>

                        <td>
                          <ul>
                            <li>
                              <Link
                                to={
                                  "/deed/" +
                                  props.deedType +
                                  "/view/" +
                                  value.id
                                }
                                className="view"
                              >
                                View
                              </Link>
                            </li>
                          </ul>
                        </td>
                      </>
                    )}
                  </tr>
                </>
              );
            })
          ) : noResultFound ? (
            <tr>
              <td
                colSpan="20"
                style={{
                  textAlign: "center",
                  background: "white",
                  fontWeight: "bold",
                }}
              >
                No records found
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      <LoadingScreen show={loadingInfo} />
    </div>
  );
};

export default withRouter(DeedComponent);

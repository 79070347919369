import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';

const ManageSecurityQuestions = (props) => {

    const [security_questions, setSecurityQuestions] = useState([]);

    const getSecurityQuestions = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/security-questions', {headers: {'Content-Type': 'application/json'},
        credentials: 'include'});
        let data = await response.json();

       if(data) {
           if(data.success) {
             setSecurityQuestions([...data.data]);
           } 
       }
 
    }


    const deletePlan = (id) => {

      
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#17a2b8',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {





            if (result.isConfirmed) {


                let url = process.env.NODE_ENV === "development"?
                process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
                let response = await fetch(url + '/api/v1/delete-security-question?id=' + id, 
                {method: 'DELETE',
                 headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();
        
                if(data.success) {
                   
                    await Swal.fire(
                        'Deleted',
                        '',
                        'success'
                      )
                    window.location.reload();
                }
    
    



            }
          })


    }



    useEffect(() => {
       getSecurityQuestions();
    }, []);
    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Manage Security Questions</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">Manage Security Questions</div>
                <div className="add-search">
                    <div className="add">
                        <a href="/admin/manage-security-questions/add">Add New <i className="fas fa-plus"></i></a>
                    </div>
                    <div className="search">
                        <form>
                            <input type="text" placeholder="Search" />
                            <button type="submit"><i className="fas fa-search"></i></button>
                        </form>
                    </div>
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                   <table>
                       <thead>
                           <tr>
                               <th>Question</th>
                               <th>Edit</th>
                               <th>Delete</th>
                           </tr>
                       </thead>
                       <tbody>
                           {security_questions.map((value) => {
                               return (
                                <tr key={value.id}>
                                    <td>{value.question}</td>
                                    <td>
                                        <ul>
                                        <li><Link to={"/admin/manage-security-questions/edit/" + value.id}><i className="fas fa-edit"></i></Link></li>

                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            <li  onClick={() => deletePlan(value.id)} ><i className="fas fa-trash"></i></li>
                                        </ul>
                                    </td>
                                </tr>
                               )
                           })}
                       </tbody>
                   </table>
                </div>
            </div>
        </div>
    )
}

export default ManageSecurityQuestions;

import React from 'react';
import { Link } from 'react-router-dom';

const TitleHeader = () => {
    return (
        <div className="dashboard__title">
                    <div className="dash__left">
            
                    </div>
                    <div className="dash__right">
                        <ul>
                            <li><Link to="/user/profile"><i className="fas fa-user"></i></Link></li>
                            <li style={{cursor: 'pointer'}}><Link to="/logout"><i className="fas fa-power-off"></i></Link></li>
                        </ul>
                    </div>
            </div>
    )
}

export default TitleHeader;

import React, { useState } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddSecurityQuestion = () => {

     const [security_question, setSecurityQuestion] = useState({
         question: "",
     });
    const [created, setCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notCreated, setNotCreated] = useState(false);
    const [required, setRequired] = useState();

    const submit = async (event) => {
        event.preventDefault(); 
        setLoading(true);

        if(security_question.question === "")
        {
            setRequired((security_question.question === ""? "Question cannot be blank" : "Question Required"));
            setLoading(false);
            return;
        }
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/create-security-question', {method: 'POST', body: JSON.stringify(security_question), headers: {'Content-Type': 'application/json'},
        credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setCreated(true);
            setLoading(false);
        } else {
            setNotCreated(true);
        }   
        setLoading(false);
        setRequired();
    }

    const handleChange = (event) => {
        setSecurityQuestion({...security_question,[event.target.id]: event.target.value});
    }

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Security Question</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  
                  <h3>Add Security Question</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                      <form className="form__add_plan" onSubmit={ submit }>
                        {created? (
                            <>
                            <h6 style={{backgroundColor: '#17a2b8', marginBottom: '10px', padding: '5px', borderRadius: '5px'}}>Plan Created</h6>
                            </>
                        ) : null}

                        {notCreated? (
                            <>
                            <h6 style={{backgroundColor: '#fb6340', marginBottom: '10px', padding: '5px', borderRadius: '5px'}}>Plan Not Created</h6>
                            </>
                        ) : null}

                        {required? (
                            <>
                            <h6 style={{color: '#fb6340', marginBottom: '10px', padding: '5px', borderRadius: '5px'}}>{required}</h6>
                            </>
                        ) : null}

                          <div className="one__column">
                              <textarea placeholder="Security Question" id="question" onChange={handleChange}></textarea>
                          </div>
                          <button type="submit">{loading? 'Loading...' : 'Create'}</button>
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )

}

export default AddSecurityQuestion

import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddSubscriptionPlan = (props) => {

    const [subscription, setSubscription] = useState({
        name: "",
        description: "",
        type: "",
        cost: "",
        amount: ""
    });
    const [created, setCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notCreated, setNotCreated] = useState(false);
    const [plans, setPlans] = useState([]);
    const [required, setRequired] = useState();


    const getPlans = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/plans', {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setPlans([...data.data.data]);
        } 
    }

    const submit = async (event) => {
        event.preventDefault();
        if(loading){
            return;
          } 
        setLoading(true);

        if(subscription.name === "" || subscription.description === "" ||
        subscription.type === "0" || subscription.cost === "" || subscription.amount === "") {
            setRequired((subscription.name === ""? "Name Required" : null) || (subscription.description === ""?
              "Description Required" : null) || (subscription.type === "0"? "Subscription Plan Required" : null)
              || (subscription.cost === ""? "Cost Required" : null) || (subscription.amount === ""? "Amount Required" : null));
              setLoading(false);
              setCreated(false);
              setNotCreated(false);
              window.scrollTo(0,0);
            return 1;
        }

        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/create-subscription', {method: 'POST',
        body: JSON.stringify(subscription), headers: {'Content-Type': 'application/json'}
          , credentials: 'include'});
        let data = await response.json();



        if(data.success) {
            setCreated(true);
            setNotCreated(false);
        } else {
            setNotCreated(true);
            setCreated(false);
        }
        setLoading(false);
        setRequired(false);
        window.scrollTo(0,0);
    }

    const handleChange = (event) => {
        setSubscription({...subscription,[event.target.id]: event.target.value});
    }

    useEffect(() => {
        getPlans();    
    }, [])

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Subscription Plan</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  
                  <h3>Add Subscription Plan</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                      <form className="form__add_plan" onSubmit={ submit }>
                        {created? (
                            <>
                            <h6 style={{backgroundColor: '#17a2b8', marginBottom: '10px', padding: '5px', borderRadius: '5px'}}>Subscription Created</h6>
                            </>
                        ) : null}

                        {notCreated? (
                            <>
                            <h6 style={{backgroundColor: '#fb6340', marginBottom: '10px', padding: '5px', borderRadius: '5px'}}>Subscription Not Created</h6>
                            </>
                        ) : null}

                        {required? (
                            <>
                            <h6 style={{backgroundColor: '#fb6340', marginBottom: '10px', padding: '5px', borderRadius: '5px'}}>{required}</h6>
                            </>
                        ) : null}

                          <div className="one__column">
                              <input type="text" placeholder="Name" id="name" onChange={handleChange} />
                          </div>
                          <div className="one__column">
                              <textarea placeholder="Description" id="description" onChange={handleChange}></textarea>
                          </div>
                          <div className="one__column">
                              <label>Subscription Plan</label>
                              <select id="type"  onChange={handleChange}>
                                  <option value={0}>Select</option>
                                  {plans.map((value) => {
                                      return (
                                          <>
                                          <option value={value.name}>{value.name}</option>
                                          </>
                                      )
                                  })}
                              </select>
                          </div>
                          <div className="one__column">
                              <input type="text" placeholder="Price" id="cost" onChange={handleChange}/>
                          </div>
                          <div className="one__column">
                              <input type="text" placeholder="Subscription Amount" id="amount" onChange={handleChange}/>
                          </div>
                          <button type="submit">{loading? 'Loading...' : 'Create'}</button>
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default withRouter(AddSubscriptionPlan);

import React, { useState } from "react";
import Sidebar from "../../../inc/Sidebar";
import TitleHeader from "../../../inc/TitleHeader";
import { Helmet } from "react-helmet";
import FormData from "form-data";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams, withRouter } from "react-router-dom";

const AddDocument = (props) => {
  const { code, id } = useParams();

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [pdfAllowed, setPDFAllowed] = useState(false);

  const uploadDocument = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    const data = new FormData();
    data.append("document", file);

    if (file.type !== "application/pdf") {
      setPDFAllowed(true);
      setLoading(false);
      return;
    }

    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    axios
      .post(url + "/api/v1/upload-document?code=" + code + "&id=" + id, data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
        withCredentials: true,
      })
      .then(async (response) => {
        if (response.data.success) {
          setLoading(false);
          setPDFAllowed(false);
          await Swal.fire("Uploaded!", "", "success");
          //   props.history.push('/');
          //   props.history.push('/add/document/' + code + '/' + id);
        } else {
          await Swal.fire("Error Uploading!", "", "error");
          setLoading(false);
          setPDFAllowed(false);
        }
      })
      .catch((err) => {});
  };

  const setFileUpload = (event) => {
    setFile(event.target.files[0]);
    // console.log(event.target.files[0]);
  };

  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Document | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="upload__container">
          <h2>ADD DOCUMENT</h2>
          <form onSubmit={uploadDocument}>
            <label>Attach*</label>
            <input type="file" name="document" onChange={setFileUpload} />
            <input
              type="submit"
              value={loading ? "Uploading..." : "Upload"}
              style={{ marginRight: "5px" }}
            />
            {pdfAllowed ? (
              <span style={{ color: "red" }}>Only PDF Allowed*</span>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AddDocument);

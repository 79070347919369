import React from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { withRouter } from 'react-router-dom';

const AddBusinessType = (props) => {


    const submit = (event) => {
        event.preventDefault();

        props.history.push('/lease/kombo-lease/confirm-kombo-lease-registration');
    }

    return (
        <div className="dashboard__container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  <h3>Add Business Type</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                      <form className="form__add_plan" onSubmit={ submit }>
                           <div className="one__column">
                               <input type="text" placeholder="Business Type" />
                           </div>
                           <div className="one__column">
                               <textarea placeholder="Description"></textarea>
                           </div>
                          <input type="submit" value="Submit" />
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default withRouter(AddBusinessType);

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../../inc/Sidebar";
import TitleHeader from "../../../inc/TitleHeader";
import Logo from "../../../../assets/imgs/icon.png";
import { Helmet } from "react-helmet";
import CertificateBorder from "../../../../assets/imgs/second-certificate.jpeg";

const ViewProvincialLease = () => {
  const { lease_id } = useParams();

  const [lease, setLease] = useState([]);
  const [signature, setSignature] = useState("");

  const getLease = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/provincial-lease?lease_id=" +
          lease_id
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/provincial-lease?lease_id=" +
          lease_id;

    let response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setLease([...data.data]);
      if (data.data[0].registrar.length >= 1) {
        getSignature(data.data[0].registrar[0].signature);
      }
    }
  };

  const getSignature = async (id) => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/signature?id=" + id, {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      if (data.success) {
        setSignature(data.data.signature);
      }
    }
  };

  useEffect(() => {
    getLease();
  }, []);

  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>View Provincial Lease | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="details">
          <div className="details__title">
            <h5>DETAILS OF PROVINCIAL LEASE</h5>
            <h5
              onClick={() => {
                setTimeout(window.print(), 100);
              }}
            >
              <a>Print</a>
            </h5>
          </div>
          {lease.length === 0 ? (
            <>
              <h2 style={{ padding: "10px 0px" }}>Loading...</h2>
            </>
          ) : (
            <>
              <div
                className="print"
                style={{ backgroundImage: CertificateBorder }}
              >
                <div className="logo">
                  <img
                    src={Logo}
                    style={{ maxHeight: "12%", maxWidth: "12%" }}
                  />
                  <h2 className="receipt-title">
                    TYPE OF REGISTRATION: Provincial Lease
                  </h2>
                  {/* <h5>Certificate of Registration on Acknowledgement or Proof Before Registrar</h5> */}
                </div>
                <div className="print__details">
                  <div className="print__title">
                    <h4>Registration Info</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>SR. Number</h5>
                      <p>{lease[0].serial_number}</p>
                    </div>
                    <div className="one__column">
                      <h5>L.O.M.P</h5>
                      <p>{lease[0].lomp}</p>
                    </div>
                    <div className="one__column">
                      <h5>Location Of Land</h5>
                      <p>{lease[0].land_situation}</p>
                    </div>
                  </div>
                  <div className="print__title">
                    <h4>Parties Involved</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>Lessor Company</h5>
                      <p>
                        {lease[0].lessor[0].company_name === ""
                          ? "N/A"
                          : lease[0].lessor[0].company_name}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Lessee Company</h5>
                      <p>
                        {lease[0].lessee[0].company_name === ""
                          ? "N/A"
                          : lease[0].lessee[0].company_name}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>Lessor Name</h5>
                      <p>
                        {lease[0].lessor[0].first_name +
                          " " +
                          lease[0].lessor[0].middle_name +
                          " " +
                          lease[0].lessor[0].last_name}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Lessee Name</h5>
                      <p>
                        {lease[0].lessee[0].first_name +
                          " " +
                          lease[0].lessee[0].middle_name +
                          " " +
                          lease[0].lessee[0].last_name}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>NIN</h5>
                      <p>
                        {lease[0].lessor[0].nin === ""
                          ? "N/A"
                          : lease[0].lessor[0].nin}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Date of Birth</h5>
                      <p>
                        {lease[0].lessor[0].dob === ""
                          ? "N/A"
                          : lease[0].lessor[0].dob}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>NIN</h5>
                      <p>
                        {lease[0].lessee[0].nin === ""
                          ? "N/A"
                          : lease[0].lessee[0].nin}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Date of Birth</h5>
                      <p>
                        {lease[0].lessee[0].dob === ""
                          ? "N/A"
                          : lease[0].lessee[0].dob}
                      </p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>Address</h5>
                      <p>
                        {lease[0].lessor[0].address === ""
                          ? "N/A"
                          : lease[0].lessor[0].address}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Telephone</h5>
                      <p>
                        {lease[0].lessor[0].telephone1 === ""
                          ? "N/A"
                          : lease[0].lessor[0].telephone1}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Address</h5>
                      <p>
                        {lease[0].lessee[0].address === ""
                          ? "N/A"
                          : lease[0].lessee[0].address}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>Telephone</h5>
                      <p>
                        {lease[0].lessee[0].telephone1 === ""
                          ? "N/A"
                          : lease[0].lessee[0].telephone1}
                      </p>
                    </div>
                  </div>
                  <div className="print__title">
                    <h4>Transaction Dates</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>Date of Execution</h5>
                      <p>{lease[0].execution_date}</p>
                    </div>
                    <div className="one__column">
                      <h5>Date of Receipt</h5>
                      <p>{lease[0].receipt_date}</p>
                    </div>
                  </div>
                  <div className="print__title">
                    <h4>Party Registering</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>Name</h5>
                      <p>
                        {lease[0].party.length === 0
                          ? "N/A"
                          : lease[0].party[0].first_name +
                            " " +
                            lease[0].party[0].middle_name +
                            " " +
                            lease[0].party[0].last_name}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>NIN</h5>
                      <p>
                        {lease[0].party.length === 0
                          ? "N/A"
                          : lease[0].party[0].nin}
                      </p>
                    </div>
                  </div>
                  <div className="print__title">
                    <h4>Recipient Information</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>Name</h5>
                      <p>
                        {lease[0].recipient[0].first_name +
                          " " +
                          lease[0].recipient[0].middle_name +
                          " " +
                          lease[0].recipient[0].last_name}
                      </p>
                    </div>
                    <div className="one__column">
                      <h5>NIN</h5>
                      <p>{lease[0].recipient[0].nin}</p>
                    </div>
                    <div className="one__column">
                      <h5>Receipt Date</h5>
                      <p>{lease[0].receipt_date}</p>
                    </div>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <h5>OR Number</h5>
                      {lease[0].or_number}
                    </div>
                    <div className="one__column">
                      <h5>Stamp Duty</h5>
                      {lease[0].stamp_duty}
                    </div>
                  </div>
                  <div className="print__title">
                    <h4>Miscellaneous</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <p>{lease[0].note === "" ? "N/A" : lease[0].note}</p>
                    </div>
                  </div>
                  <div className="print__title">
                    <h4>Before me:</h4>
                  </div>
                  <div className="column">
                    <div className="one__column">
                      <p>Registrar General:</p>
                      <p>
                        {lease[0].status !== null &&
                        lease[0].status !== "Approved RA" &&
                        lease[0].registrar.length > 0
                          ? lease[0].registrar[0].first_name +
                            " " +
                            lease[0].registrar[0].last_name
                          : null}
                      </p>
                    </div>
                    {lease[0].status === "Approved RG" ? (
                      <>
                        <div
                          className="one__column"
                          style={{
                            textAlign: "center",
                            height: "100%",
                          }}
                        >
                          <h6>SIGNATURE</h6>
                          <img
                            src={
                              process.env.NODE_ENV === "development"
                                ? process.env.REACT_APP_DEVELOPMENT_API_URL +
                                  "/user/" +
                                  signature
                                : process.env.REACT_APP_PRODUCTION_API_URL +
                                  "/user/" +
                                  signature
                            }
                            style={{ width: "140px", height: "100px" }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              {/** View */}
              <div className="details__info">
                <div className="details__info_title">
                  <h5>
                    Type of Registration: Provincial Lease{" "}
                    <span style={{ paddingLeft: "20px" }}>
                      {" "}
                      Registrar General:{" "}
                      {lease[0].status !== null &&
                      lease[0].status === "Approved RG" &&
                      lease[0].registrar.length > 0
                        ? lease[0].registrar[0].first_name +
                          " " +
                          lease[0].registrar[0].last_name
                        : null}
                    </span>
                    {lease[0].status === "Approved RG" ? (
                      <span
                        style={{
                          color: "green",
                          paddingLeft: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Approved
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                          paddingLeft: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Pending
                      </span>
                    )}
                  </h5>
                </div>
                <form className="form">
                  <div className="details__header">
                    <h4>Registration Info</h4>
                  </div>
                  <div className="column">
                    <div className="group">
                      <label>SR Number</label>
                      <input
                        type="text"
                        value={lease[0].serial_number}
                        disabled
                      />
                    </div>
                    <div className="group">
                      <label>L.O.M.P</label>
                      <input type="text" value={lease[0].lomp} disabled />
                    </div>
                    <div className="group">
                      <label>Location of Land</label>
                      <input
                        type="text"
                        value={lease[0].land_situation}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="details__header">
                    <h4>Parties Involved</h4>
                  </div>
                  <div className="parties__involved">
                    <div className="one__column">
                      <div className="column__title">Lessor</div>
                      <div className="group">
                        <label>Company</label>
                        <input
                          type="text"
                          value={lease[0].lessor[0].company_name}
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>Name</label>
                        <input
                          type="text"
                          value={
                            lease[0].lessor[0].first_name +
                            " " +
                            lease[0].lessor[0].middle_name +
                            " " +
                            lease[0].lessor[0].last_name
                          }
                          disabled
                        />
                      </div>
                      <div className="two__column">
                        <div className="group">
                          <label>NIN</label>
                          <input
                            type="text"
                            value={lease[0].lessor[0].nin}
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Date of Birth</label>
                          <input
                            type="text"
                            value={lease[0].lessor[0].dob}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="three__column">
                        <div className="group">
                          <label>Address</label>
                          <input
                            type="text"
                            value={lease[0].lessor[0].address}
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Tel1</label>
                          <input
                            type="text"
                            value={lease[0].lessor[0].telephone1}
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Email</label>
                          <input
                            type="text"
                            value={lease[0].lessor[0].email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="one__column">
                      <div className="column__title">Lessee</div>
                      <div className="group">
                        <label>Company</label>
                        <input
                          type="text"
                          value={lease[0].lessee[0].company_name}
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>Name</label>
                        <input
                          type="text"
                          value={
                            lease[0].lessee[0].first_name +
                            " " +
                            lease[0].lessee[0].middle_name +
                            " " +
                            lease[0].lessee[0].last_name
                          }
                          disabled
                        />
                      </div>
                      <div className="two__column">
                        <div className="group">
                          <label>NIN</label>
                          <input
                            type="text"
                            value={lease[0].lessee[0].nin}
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Date of Birth</label>
                          <input
                            type="text"
                            value={lease[0].lessee[0].dob}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="three__column">
                        <div className="group">
                          <label>Address</label>
                          <input
                            type="text"
                            value={lease[0].lessee[0].address}
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Tel1</label>
                          <input
                            type="text"
                            value={lease[0].lessee[0].telephone1}
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Email</label>
                          <input
                            type="text"
                            value={lease[0].lessee[0].email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="details__header">
                    <h4>Transaction Dates</h4>
                  </div>
                  <div className="two__column">
                    <div className="group">
                      <label>Date of Execution</label>
                      <input
                        type="text"
                        value={lease[0].execution_date}
                        disabled
                      />
                    </div>
                    <div className="group">
                      <label>Date of Receipt</label>
                      <input
                        type="text"
                        value={lease[0].receipt_date}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="details__header">
                    <h4>Party Registering</h4>
                  </div>
                  <div className="party__registering">
                    <div className="one__column">
                      <div className="group">
                        <label>Name</label>
                        <input
                          type="text"
                          value={
                            lease[0].party.length >= 1
                              ? lease[0].party[0].first_name +
                                " " +
                                lease[0].party[0].middle_name +
                                " " +
                                lease[0].party[0].last_name
                              : "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>ID</label>
                        <input
                          type="text"
                          value={
                            lease[0].party.length >= 1
                              ? lease[0].party[0].nin
                              : "N/A"
                          }
                          disabled
                        />
                      </div>
                      <div className="three__column">
                        <div className="group">
                          <label>Address</label>
                          <input
                            type="text"
                            value={
                              lease[0].party.length >= 1
                                ? lease[0].party[0].address
                                : "N/A"
                            }
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Tel1</label>
                          <input
                            type="text"
                            value={
                              lease[0].party.length >= 1
                                ? lease[0].party[0].telephone1
                                : "N/A"
                            }
                            disabled
                          />
                        </div>
                        <div className="group">
                          <label>Email</label>
                          <input
                            type="text"
                            value={
                              lease[0].party.length >= 1
                                ? lease[0].party[0].email
                                : "N/A"
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="one__column"></div> */}
                  </div>
                  <div className="details__header">
                    <h4>Recipient Information</h4>
                  </div>
                  <div className="recipient__information">
                    <div className="two__column">
                      <div className="group">
                        <label>Name</label>
                        <input
                          type="text"
                          value={
                            lease[0].recipient[0].first_name +
                            " " +
                            lease[0].recipient[0].middle_name +
                            " " +
                            lease[0].recipient[0].last_name
                          }
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>Receipt Date</label>
                        <input
                          type="text"
                          value={lease[0].recipient_date}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="two__column">
                      <div className="group">
                        <label>ID</label>
                        <input
                          type="text"
                          value={lease[0].recipient[0].nin}
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>OR Number</label>
                        <input
                          type="text"
                          value={lease[0].or_number}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="three__column">
                      <div className="group">
                        <label>Address</label>
                        <input
                          type="text"
                          value={lease[0].recipient[0].address}
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>Tel1</label>
                        <input
                          type="text"
                          value={lease[0].recipient[0].telephone1}
                          disabled
                        />
                      </div>
                      <div className="group">
                        <label>Stamp Duty</label>
                        <input
                          type="text"
                          value={lease[0].stamp_duty}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="details__header">
                    <h4>Miscellaneous Notes & Witness Information</h4>
                  </div>
                  <div className="witness__information">
                    <div className="two__column">
                      <div className="group">
                        <label>Notes</label>
                        <textarea value={lease[0].note} disabled></textarea>
                      </div>
                      <div className="group">
                        <label>Name</label>
                        <input
                          type="text"
                          value={
                            lease[0].witness.length >= 1
                              ? lease[0].witness[0].first_name +
                                " " +
                                lease[0].witness[0].last_name
                              : "N/A"
                          }
                          disabled
                        />
                        <div className="three__column">
                          <div className="group">
                            <label>ID</label>
                            <input
                              type="text"
                              value={
                                lease[0].witness.length >= 1
                                  ? lease[0].witness[0].nin
                                  : "N/A"
                              }
                              disabled
                            />
                          </div>
                          <div className="group">
                            <label>Date Of Birth</label>
                            <input
                              type="text"
                              value={
                                lease[0].witness.length >= 1
                                  ? lease[0].witness[0].dob
                                  : "N/A"
                              }
                              disabled
                            />
                          </div>
                          <div className="group">
                            <label>Tel</label>
                            <input
                              type="text"
                              value={
                                lease[0].witness.length >= 1
                                  ? lease[0].witness[0].telephone1
                                  : "N/A"
                              }
                              disabled
                            />
                          </div>
                        </div>
                        <div className="two__column">
                          <div className="group">
                            <label>Location Number</label>
                            <input
                              type="text"
                              value={lease[0].location_number}
                              disabled
                            />
                          </div>
                          <div className="group">
                            <label>Box No.</label>
                            <input
                              type="text"
                              value={lease[0].box_number}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {lease[0].status === "Approved RG" ? (
                      <>
                        <div
                          style={{
                            borderTop: "1px solid black",
                            marginTop: "10px",
                            padding: "10px 0px",
                            textAlign: "center",
                            height: "200px",
                          }}
                        >
                          <img
                            src={
                              process.env.NODE_ENV === "development"
                                ? process.env.REACT_APP_DEVELOPMENT_API_URL +
                                  "/user/" +
                                  signature
                                : process.env.REACT_APP_PRODUCTION_API_URL +
                                  "/user/" +
                                  signature
                            }
                            style={{ width: "50%", height: "100%" }}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewProvincialLease;

import React, { useState, useEffect } from "react";
import Logo from "../../assets/imgs/icon.png";
import SubscriptionIcon from "../../assets/imgs/subscription.png";
import Free from "../../assets/imgs/free.png";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";

import LoadingScreen from "../inc/LoadingScreen";

const RegisterUser = (props) => {
  // style
  const errorStyle = {
    color: "red",
    fontSize: "13px !important",
    padding: "0.5rem 0",
    fontWeight: "bold",
  };

  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [serviceType, setServiceType] = useState([]);
  const [serviceToggle, setServiceToggle] = useState([]);
  const [subscriptionPackage, setSubscriptionPackage] = useState([]);
  const [show, setShow] = useState();
  const [selected, setSelected] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [user, setUser] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
    telephone1: "",
    telephone2: "",
    question1: "",
    question2: "",
    question3: "",
    answer1: "",
    answer2: "",
    answer3: "",
    companyName: "",
    address: "",
    companyName: "",
    companyTelephone1: "",
    companyTelephone2: "",
    businessType: "",
  });

  const [securityQuestions1, setSecurityQuestions1] = useState([]);
  const [securityQuestions2, setSecurityQuestions2] = useState([]);
  const [securityQuestions3, setSecurityQuestions3] = useState([]);

  const [formErrors, setFormErrors] = useState({
    question1: "",
    question2: "",
    question3: "",
    businessType: "",
    service: "",
    password: ""
  });

  const selectService = (event) => {
    setShow(event.target.id);
  };

  const getSubscriptionPlans = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/subscription-package", {
      credentials: "include",
    });
    let data = await response.json();
    console.log(data);
    if (data.success) {
      if (serviceType.length <= 0) {
        data.data.map((value) => {
          serviceType.push(value.name);
          setServiceToggle(value.name.replace(/\s/g, ""));
        });
        // serviceType.push("All");
      } else {
        emtpyArray(serviceType);
        data.data.map((value) => {
          serviceType.push(value.name);
        });
        // serviceType.push("All");
      }
      setSubscriptionPackage([...data.data]);
    }
  };

  const emtpyArray = (arr) => {
    while (arr.length >= 1) {
      arr.pop();
    }
  };

  const subscribe = (event, type, subscription, id, subscription_name) => {
    event.preventDefault();
    setSelected([...selected, type]);
    let identifier = subscription + " " + id;
    setSelectedID([...selectedID, identifier]);
    setSelectedPackage([...selectedPackage, subscription_name]);
    if (selectedID.includes(identifier)) {
      let filteredSelectedArr = selectedID.filter((value) => {
        return value != identifier;
      });
      let filteredTypeArr = selected.filter((value) => {
        return value != subscription;
      });
      let filteredSelectedPackages = selectedPackage.filter((value) => {
        return value != subscription_name;
      });
      setSelectedID([...filteredSelectedArr]);
      setSelected([...filteredTypeArr]);
      setSelectedPackage([...filteredSelectedPackages]);
    }
  };

  const getSecurityQuestions = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/security-questions");
    let data = await response.json();

    if (data) {
      if (data.success) {
        setSecurityQuestions([...data.data]);
        setSecurityQuestions1([...data.data]);
        setSecurityQuestions2([...data.data]);
        setSecurityQuestions3([...data.data]);
      }
    }
  };

  const getBusinessTypes = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/business-types");
    let data = await response.json();

    if (data) {
      if (data.success) {
        setBusinessTypes([...data.data]);
      }
    }
  };

  const submit = async (event) => {
    event.preventDefault();
    if(loading){
      return;
    }
    setLoading(true);

    // Form errors
    let isExist = handleFormErrors();

    if (isExist === true) {
      setLoading(false);
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "By confirming you accept that the entered credentials are correct",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, register me!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          setLoadingInfo(true)
          let url =
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_DEVELOPMENT_API_URL
              : process.env.REACT_APP_PRODUCTION_API_URL;
          let response = await fetch(url + "/api/v1/create-user-subscription", {
            method: "POST",
            body: JSON.stringify({
              info: user,
              plan: selected,
              package: selectedPackage,
            }),
            headers: { "Content-Type": "application/json" },
            credentials: "include",
          });
          let data = await response.json();

          if (data.success) {
            setLoadingInfo(false)
            await Swal.fire({
              icon: "success",
              title: "Account Successfully Created!",
              html:
                "This is your confirmation token:<h1>" +
                data.token +
                "</h1></br>" +
                "You can now await approval from the Admins ",
              confirmButtonColor: "#17a2b8",
            });
            props.history.push('/user-login');
            setLoading(false);
          } else {
            setLoadingInfo(false)

            Swal.fire({
              icon: "error",
              title: "Failed!",
              text: data.message.message,
              confirmButtonColor: "#17a2b8",
            });
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "Registration was unsuccessful",
          confirmButtonColor: "#17a2b8",
        });
      });
  };

  const saveUserData = (event) => {
    setUser({ ...user, [event.target.id]: event.target.value });
    if (event.target.id == "question1") {
      let temp = [
        ...securityQuestions.filter((q) => q.id != event.target.value),
      ];
      setSecurityQuestions2([...temp.filter((q) => q.id != user.question3)]);
      setSecurityQuestions3([...temp.filter((q) => q.id != user.question2)]);
    }

    if (event.target.id == "question2") {
      let temp = [
        ...securityQuestions.filter((q) => q.id != event.target.value),
      ];
      setSecurityQuestions1([...temp.filter((q) => q.id != user.question3)]);
      setSecurityQuestions3([...temp.filter((q) => q.id != user.question1)]);
    }

    if (event.target.id == "question3") {
      let temp = [
        ...securityQuestions.filter((q) => q.id != event.target.value),
      ];
      setSecurityQuestions1([...temp.filter((q) => q.id != user.question2)]);
      setSecurityQuestions2([...temp.filter((q) => q.id != user.question1)]);
    }
  };

  const handleFormErrors = () => {
    let isExist = false;
    let securityMsg = "You have to select a security question";

    setFormErrors({
      ...formErrors,
      question1: "",
      question2: "",
      question3: "",
      businessType: "",
      service: "",
      password: ""
    });

    if(user.password !== user.confirmPassword){
        setFormErrors((prevState) => ({
            ...prevState,
            password: "Passwords don't match",
          }));
          isExist = true;
    }

    if (user.question1 == 0 || user.question1 == "") {
      setFormErrors((prevState) => ({
        ...prevState,
        question1: securityMsg,
      }));
      isExist = true;
    }
    if (user.question2 == 0) {
      setFormErrors((prevState) => ({
        ...prevState,
        question2: securityMsg,
      }));
      isExist = true;
    }
    if (user.question3 == 0) {
      setFormErrors((prevState) => ({
        ...prevState,
        question3: securityMsg,
      }));
      isExist = true;
    }

    if (user.businessType == "" || user.businessType == 0) {
      setFormErrors((prevState) => ({
        ...prevState,
        businessType: securityMsg.replace("security question", "business type"),
      }));
      isExist = true;
    }

    if (selectedID.length == 0) {
      setFormErrors((prevState) => ({
        ...prevState,
        service: "You have to select a service",
      }));
      isExist = true;
    }
    return isExist;
  };

  useEffect(() => {
    getSubscriptionPlans();
    getSecurityQuestions();
    getBusinessTypes();
  }, []);

  return (
    <div className="register__user">
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Registration | DRB</title>
      </Helmet>
      <div className="logo__container">
        <img src={Logo} style={{ maxWidth: "100%", maxHeight: "100%" }} />
        <h1>The Gambia, Office of the Registrar of Deeds</h1>
      </div>
      <div className="registration__form">
        <form onSubmit={submit}>
          <h3>General Information</h3>
          <div className="column pt-1">
            <input
              type="text"
              placeholder="First Name (REQUIRED)"
              id="firstName"
              onChange={saveUserData}
              required
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Middle Name"
              id="middleName"
              onChange={saveUserData}
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Last Name (REQUIRED)"
              id="lastName"
              onChange={saveUserData}
              required
              className="form-control"
            />
          </div>

        <div className="column">
            <div className="single__column">
              {formErrors.password != "" ? (
                <>
                  <div style={errorStyle}>
                    <span>{formErrors.password}</span>
                  </div>
                </>
              ) : null}
            </div>

        </div>

          <div className="column pt-3">
            <input
              type="password"
              placeholder="Password (REQUIRED)"
              id="password"
              onChange={saveUserData}
              required
              className="form-control me-2"
            />
            <input
              type="password"
              placeholder="Confirm Password (REQUIRED)"
              onChange={saveUserData}
              required
              id="confirmPassword"
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Email (REQUIRED)"
              id="email"
              onChange={saveUserData}
              required
              className="form-control"
            />
          </div>
          <div className="column pt-3">
            <input
              type="text"
              placeholder="Telephone 1"
              id="telephone1"
              required
              onChange={saveUserData}
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Telephone 2"
              id="telephone2"
              onChange={saveUserData}
              className="form-control"
            />

          </div>

          <div className="row mt-3">
            <div className="col-12">
              <h6>Security Questions</h6>
            </div>
            <div className="col-12 col-md-4 pt-3 ">
              {formErrors.question1 != "" ? (
                  <>
                    <div style={errorStyle}>
                      <span>{formErrors.question1}</span>
                    </div>
                  </>
                ) : null}

              <select className="form-control" id="question1" onChange={saveUserData} required>
                <option value="0">Select</option>
                {securityQuestions1.map((value) => {
                  return (
                    <option value={value.id} key={value.id}>
                      {value.question}
                    </option>
                  );
                })}
              </select>
              <input
                type="text"
                placeholder="Question 1 Answer"
                id="answer1"
                onChange={saveUserData}
                disabled={user.question1 == "" || user.question1 == 0}
                className="inner form-control mt-2"
              />
            </div>
            <div className="col-12 col-md-4 pt-3 ">
              {formErrors.question2 != "" ? (
                  <>
                    <div style={errorStyle}>
                      <span>{formErrors.question2}</span>
                    </div>
                  </>
                ) : null}
              <select id="question2" onChange={saveUserData} className="form-control" required>
                <option value="0">Select</option>
                {securityQuestions2.map((value) => {
                  return (
                    <option value={value.id} key={value.id}>
                      {value.question}
                    </option>
                  );
                })}
              </select>
              <input
                type="text"
                placeholder="Question 2 Answer"
                id="answer2"
                disabled={user.question2 == "" || user.question2 == 0}
                onChange={saveUserData}
                className="inner form-control mt-2"
              />
            </div>
            <div className="col-12 col-md-4 pt-3 ">
              {formErrors.question3 != "" ? (
                  <>
                    <div style={errorStyle}>
                      <span>{formErrors.question3}</span>
                    </div>
                  </>
                ) : null}
              <select id="question3" className="form-control" onChange={saveUserData} required>
                <option value="0">Select</option>
                {securityQuestions3.map((value) => {
                  return (
                    <option value={value.id} key={value.id}>
                      {value.question}
                    </option>
                  );
                })}
              </select>
              <input
                type="text"
                placeholder="Question 3 Answer"
                id="answer3"
                onChange={saveUserData}
                disabled={user.question3 == "" || user.question3 == 0}
                className="inner form-control mt-2"
              />
            </div>
          </div>
          <h3 className="mt-2">Company Information</h3>
          <div className="column pt-1">
            <input
              type="text"
              placeholder="Company Name (REQUIRED)"
              id="companyName"
              onChange={saveUserData}
              required
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Address (REQUIRED)"
              id="companyAddress"
              onChange={saveUserData}
              required
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Email"
              id="companyEmail"
              onChange={saveUserData}
              required
              className="form-control "
            />
          </div>
          <div className="column pt-3">

            <input
              type="text"
              placeholder="Telephone 1"
              id="companyTelephone1"
              onChange={saveUserData}
              required
              className="form-control me-2"
            />
            <input
              type="text"
              placeholder="Telephone 2"
              id="companyTelephone2"
              onChange={saveUserData}
              className="form-control "
            />
          </div>
          <div className="column">
            <div className="single__column">
              {formErrors.businessType != "" ? (
                <>
                  <div style={errorStyle}>
                    <span>{formErrors.businessType}</span>
                  </div>
                </>
              ) : null}
              <select id="businessType" 
              className="form-control "
              onChange={saveUserData} required>
                <option value="0">Business Type</option>
                {businessTypes.map((value) => {
                  return (
                    <>
                      <option value={value.type}>{value.type}</option>
                    </>
                  );
                })}
              </select>
            </div>
          </div>
          <h3 className="mt-2">Plan Information</h3>
          <div style={{ textAlign: "center" }}>
            {formErrors.service != "" ? (
              <>
                <div style={errorStyle}>
                  <span>{formErrors.service}</span>
                </div>
              </>
            ) : null}
          </div>
          <div className="plan__list">
            <h2>Service Types</h2>
          </div>
          <div className="plan__list d-flex">
            {serviceType.length >= 1 &&
              serviceType.map((value) => {
                return (
                  <label key={value.id} className="container">
                    {value}
                    <input
                      type="radio"
                      name="radio"

                      id={value.replace(/\s/g, "").toLowerCase()}
                      onChange={selectService}
                    />
                    {/* <span className="checkmark"></span> */}
                  </label>
                );
              })}
          </div>

          {/* {show === "all" ? (
            <>
              {subscriptionPackage.map((value) => {
                return (
                  <>
                    <div className="plan__container">
                      <div className="plan">
                        <img src={Free} />
                        <h5>FREE</h5>
                        <h6>D 0</h6>
                        <span>0</span>
                        <button
                          onClick={(e) =>
                            subscribe(e, value.name, value.name, 0, "Free")
                          }
                          disabled={
                            selected.includes(value.name) &&
                            !selectedID.includes(value.name + " " + 0)
                          }
                        >
                          {selectedID.includes(value.name + " " + 0)
                            ? "Unsubscribe"
                            : "Subscribe"}
                        </button>
                      </div>

                      {value.package.map((value) => {
                        return (
                          <>
                            <div className="plan">
                              <img src={SubscriptionIcon} />
                              <h5>{value.name}</h5>
                              <h6>D {value.cost}</h6>
                              <span>{value.subscription_amount}</span>
                              <button
                                onClick={(e) =>
                                  subscribe(
                                    e,
                                    value.subscription_type,
                                    value.subscription_type,
                                    value.id,
                                    value.name
                                  )
                                }
                                disabled={
                                  selected.includes(value.subscription_type) &&
                                  !selectedID.includes(
                                    value.subscription_type + " " + value.id
                                  )
                                }
                              >
                                {selectedID.includes(
                                  value.subscription_type + " " + value.id
                                )
                                  ? "Unsubscribe"
                                  : "Subscribe"}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </>
          ) : null} */}

          {subscriptionPackage.map((value) => {
            return (
              <>
              {/* show === value.name.replace(/\s/g, "").toLowerCase()  */}
                {true ? (
                  <>
                    <div className="plan__container">
                      <div className="plan">
                        <img src={Free} />
                        <h5>Trial</h5>
                        <h6>D 0</h6>
                        <span>0</span>
                        <button
                          onClick={(e) =>
                            subscribe(e, value.name, value.name, 0, "Free")
                          }
                          disabled={
                            selected.includes(value.name) &&
                            !selectedID.includes(value.name + " " + 0)
                          }
                        >
                          {selectedID.includes(value.name + " " + 0)
                            ? "Unsubscribe"
                            : "Subscribe"}
                        </button>
                      </div>

                      {value.package.map((value) => {
                        return (
                          <>
                            <div className="plan">
                              <img src={SubscriptionIcon} />
                              <h5>{value.name}</h5>
                              <h6>D {value.cost}</h6>
                              <span>{value.subscription_amount}</span>
                              <button
                                onClick={(e) =>
                                  subscribe(
                                    e,
                                    value.subscription_type,
                                    value.subscription_type,
                                    value.id,
                                    value.name
                                  )
                                }
                                disabled={
                                  selected.includes(value.subscription_type) &&
                                  !selectedID.includes(
                                    value.subscription_type + " " + value.id
                                  )
                                }
                              >
                                {selectedID.includes(
                                  value.subscription_type + " " + value.id
                                )
                                  ? "Unsubscribe"
                                  : "Subscribe"}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </>
                ) : null}
              </>
            );
          })}

          <div className="submit__btn">
            <input type="submit" className="btn" value={loading ? "Loading..." : "Register"} />
          </div>
        </form>
      </div>
   
   
        <LoadingScreen show={loadingInfo} />
          
    </div>
  );
};

export default withRouter(RegisterUser);

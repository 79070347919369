import React, { useState } from "react";
import Logo from "../../assets/imgs/icon.png";
import { Redirect, withRouter, Link } from "react-router-dom";
import { Auth } from "../../App";
import { Helmet } from "react-helmet";

const UserLogin = () => {
  const [credentials, setCredentials] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const SignIn = async (event) => {
    event.preventDefault();
    setLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/auth/user-login", {
      method: "POST",
      headers: { "Content-Type": "applicatin/json" },
      body: JSON.stringify(credentials),
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      Auth.signin(() => {});
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  const handleChange = (event) => {
    setCredentials({ ...credentials, [event.target.id]: event.target.value });
  };

  if (Auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="wrapper-2">
      <div className="wrapper-2-gradient">
        <div className="login__container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Login | GDR - Admin</title>
          </Helmet>
          <div className="login">
            <div className="title__container">
              <img src={Logo} style={{ maxWidth: "100%", maxHeight: "100%" }} />
              {/* <h5>Sign In to Your Account</h5> */}
            </div>
            <div className="form__container">
              <form onSubmit={SignIn}>
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Enter your email"
                  id="email"
                  onChange={handleChange}
                  required
                />
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter your password"
                  id="password"
                  onChange={handleChange}
                  required
                />
                <button type="submit">
                  {loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    "Sign In"
                  )}
                </button>
              </form>
              <span className="create-user">
                <Link to="/user/register" className="link">
                  Create An Account
                </Link>
              </span>
              {error ? (
                <span
                  style={{
                    backgroundColor: "red",
                    padding: "10px 15px",
                    color: "white",
                    borderRadius: "5px",
                  }}
                >
                  Invalid Login Credentials
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(UserLogin);

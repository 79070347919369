import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../inc/Sidebar";
import TitleHeader from "../../inc/TitleHeader";
import qs from "query-string";
import Swal from "sweetalert2";
import { Link, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../../App";
import DeedComponent from "./DeedComponent";

const KomboMortgage = (props) => {
  let page_no = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .page
    ? qs.parse(props.location.search, { ignoreQueryPrefix: true }).page
    : 1;

  const [page, setPage] = useState({ page: page_no });
  const [pagination, setPagination] = useState({
    current_page: "",
    last_page: "",
  });
  const [KomboMortgage, setKomboMortgage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);


  const getKomboMortgage = async () => {
    setLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(
      url + "/api/v1/kombo-mortgages?page=" + page.page,
      { credentials: "include" }
    );
    let data = await response.json();

    if (data) {
      if (data.success) {
        setLoading(false);
        setKomboMortgage([...data.data.data]);
        setPagination({
          current_page: data.data.current_page,
          last_page: data.data.last_page,
        });
        if (data.data.data.length != 0) setNoResultFound(false);
        else setNoResultFound(true);
      }
    }
  };





  const searchDeed = async (event) => {
    event.preventDefault();
    setSearchLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/search?search_param=" +
          search +
          "&cat=KM"
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/search?search_param=" +
          search +
          "&cat=KM";

    let response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setKomboMortgage([...data.data.data]);
      setSearchLoading(false);
      // setPagination({current_page: "", last_page: ""});
      setPagination({
        current_page: data.data.current_page,
        last_page: data.data.last_page,
      });
    } else {
      // shortFunds(data.message.message);
    }
    setSearchLoading(false);
  };

  const next = () => {
    setLoading(true);
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    setLoading(false);
    window.scrollTo(0, 0);
    props.history.push("/mortgage/kombo-mortgage?page=" + currentPage);
  };

  const previous = () => {
    setLoading(true);
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      setLoading(false);
      props.history.push("/mortgage/kombo-mortgage?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      setLoading(false);
      props.history.push("/mortgage/kombo-mortgage?page=" + currentPage);
    }
    window.scrollTo(0, 0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const authUser = useContext(AuthContext);
  const shortFunds = (msg) => {
    Swal.fire({
      title: "Insufficent funds",
      text: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: "OK",
    });
  };

  useEffect(() => {
    getKomboMortgage();
  }, [page]);
  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kombo Mortgage | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="breadcrumb__title">Kombo Mortgage Registrations</div>
        <div className="add-search">
          <div className="add">
            {authUser.type === "RA" || authUser.type === "user" ? (
              <Link to="/mortgage/kombo-mortgage/add-mortgage">
                Add New <i className="fas fa-plus"></i>
              </Link>
            ) : null}
          </div>
          {authUser.type === "RA" || authUser.type === "RG" ? (
            <>
              <div className="search">
                <form onSubmit={searchDeed}>
                  <input
                    type="text"
                    placeholder="Search"
                    id="search"
                    onChange={handleSearch}
                  />
                  <button
                    type="submit"
                    className="button"
                    disabled={search.length > 3 ? false : true}
                  >
                    {searchLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : null}
          {authUser.type === "user" ? (
            <>
              <div className="search">
                <form onSubmit={searchDeed}>
                  <input
                    type="text"
                    placeholder="Search"
                    id="search"
                    onChange={handleSearch}
                  />
                  <button
                    type="submit"
                    disabled={search.length > 3 ? false : true}
                  >
                    {searchLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : null}
        </div>
        <DeedComponent 
          genericDeeds={KomboMortgage} noResultFound={noResultFound}
          history={props.history} 
          deedType={'kombo-mortgage'}
          pending={loading}
          />
        {pagination.last_page >= 2 ? (
          <>
            <div className="pagination">
              <button
                className="pagination__btn"
                disabled={
                  pagination.lastPage === 1 || Number(page_no) === 1
                    ? true
                    : false
                }
                onClick={previous}
              >
                Previous
              </button>
              <button
                className="pagination__btn"
                disabled={
                  Number(page.page_no) === pagination.lastPage ? true : false
                }
                onClick={next}
              >
                {loading ? <i className="fas fa-spinner-3x"></i> : "Next"}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default KomboMortgage;

import React from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';

const ManageRegistrationQuota = () => {
    return (
        <div className="dashboard__container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">Manage Registration Quota</div>
                <div className="add-search">
                    {/* <div className="add">
                        <a href="/admin/manage-search-plans/add">Add New <i className="fas fa-plus"></i></a>
                    </div> */}
                    <div className="search">
                        <form>
                            <input type="text" placeholder="Search" />
                            <button type="submit"><i className="fas fa-search"></i></button>
                        </form>
                    </div>
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                   <table>
                       <thead>
                           <tr>
                               <th>Name</th>
                               <th>Total Searches</th>
                               <th>Balanced Searches</th>
                               <th>Used Searches</th>
                               <th>Action</th>
                           </tr>
                       </thead>
                       <tbody>
                            <tr>
                               <td>Cayor User</td>
                               <td>10</td>
                               <td>10</td>
                               <td>5</td>
                               <td>
                                   <ul>
                                       <li><i className="fas fa-edit"></i></li>
                                       <li><i className="fas fa-trash"></i></li>
                                   </ul>
                               </td>
                           </tr>
                           <tr>
                               <td>Cayor User</td>
                               <td>10</td>
                               <td>10</td>
                               <td>5</td>
                               <td>
                                   <ul>
                                       <li><i className="fas fa-edit"></i></li>
                                       <li><i className="fas fa-trash"></i></li>
                                   </ul>
                               </td>
                           </tr>
                           <tr>
                               <td>Cayor User</td>
                               <td>10</td>
                               <td>10</td>
                               <td>5</td>
                               <td>
                                   <ul>
                                       <li><i className="fas fa-edit"></i></li>
                                       <li><i className="fas fa-trash"></i></li>
                                   </ul>
                               </td>
                           </tr>
                           
                       </tbody>
                   </table>
                </div>
                <div className="pagination">
                    <button className="pagination__btn">Previous</button>
                    <button className="pagination__btn">Next</button>
                </div>
            </div>
        </div>
    )
}

export default ManageRegistrationQuota;

import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const EditSecurityQuestion = () => {
    // style
    const errorStyle = {
        color: 'red',
        fontSize: '13px !important',
        padding: '0.5rem 0',
        fontWeight: 'bold'
        
    }

    const { id } = useParams();

    const [security_questions, setSecurityQuestions] = useState({
        "id":"",
        "question": ""
    });

    const [loading, setLoading] = useState(false);

    const [updated, setUpdated] = useState(false);

    const [formErrors, setFormErrors] = useState({
        question: "",
    
    });

    const getSecurityQuestions = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/get-security-question?id='+ id, {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setSecurityQuestions({...data.data[0]});
        } 
    }
    const submit = async (event) => {
        event.preventDefault();
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        setLoading(true);

        // Form errors
        let isExist = handleFormErrors();

        if (isExist === true){
            setLoading(false)
            return
        }
        else
        {
            let response = await fetch(url + '/api/v1/update-security-question', {credentials: 'include',method: 'PUT', 
            body: JSON.stringify(security_questions), headers: {'Content-Type': 'application/json'}});
            let data = await response.json();
    
            if(data) {
                if(data.success) {
                    setLoading(false);
                    setUpdated(true);
                } else {
                    setLoading(false);
                    setUpdated(false);
                }
                setLoading(false);
                window.scrollTo(0,0);
            }
        }

        
    }

    const handleChange = (event) => {

        setSecurityQuestions(prevState => ({
            ...prevState,
            [event.target.id] : event.target.value
        }));

    }

    const handleFormErrors = ()=>{
        let isExist = false;
        
        setFormErrors({
            ...formErrors,
            question: "",

            
        })

        if(security_questions.question.length == 0){

            setFormErrors(prevState => ({
                ...prevState,
                question: 'Question cannot be blank',
                
            }));
            isExist = true;
        }

        return isExist;
    }


    useEffect(() => {
        getSecurityQuestions()    
    }, [])

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Security Question</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>  

            <div className="dashboard__content">
                <TitleHeader />
                <div className="registration__page">
                    <h3>Edit Security Question</h3>
                    <span className="underline"></span>
                    <div className="registration__form__container">

                        {updated? (
                            <>
                                <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                                    margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                        <h6>Security Question Updated</h6>
                                    </div>
                            </>
                        ): null}

                        <form className="form__add_plan" onSubmit={ submit }>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.question != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.question}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Question</label>
                              <textarea style={{marginTop:"8px"}} placeholder="Security Question" id="question" value={security_questions.question} onChange={handleChange}></textarea>
                          </div>

                          <input type="submit" value={loading? 'Loading...' : "Update"} />

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSecurityQuestion

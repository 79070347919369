import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../inc/Sidebar';
import TitleHeader from '../inc/TitleHeader';
import { Helmet } from 'react-helmet';
import SubscriptionIcon from '../../assets/imgs/subscription.png';
import Free from '../../assets/imgs/free.png';
import SubscribeButton from '../inc/SubscribeButton'
import { AuthContext } from "../../App";

const Packages = () => {

    const [serviceType, setServiceType] = useState([]);
    const [serviceToggle, setServiceToggle] = useState([]);
    const [subscriptionPackage, setSubscriptionPackage] = useState([]);
    const [show, setShow] = useState("all");

    const authUser = useContext(AuthContext);

    const getSubscriptionPlans = async () => {
        let url = process.env.NODE_ENV === "development"?
             process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        let response = await fetch(url + '/api/v1/subscription-package', { credentials: 'include'});
        let data = await response.json();

        console.log(data)
        if(data.success) {
           if(serviceType.length <= 0) {
                data.data.map((value) => {
                    serviceType.push(value.name);
                    setServiceToggle(value.name.replace(/\s/g,''));
                });
                serviceType.push('All');
           } else {
               emtpyArray(serviceType);
               data.data.map((value) => {
                serviceType.push(value.name);
                });
                serviceType.push('All');
           }
           setSubscriptionPackage([...data.data]);
        }
    }

    const emtpyArray = (arr) => {
        while(arr.length >= 1) {
            arr.pop();
        }
    }

    const selectService = (event) => {
        setShow(event.target.id);
    }

    useEffect(() => {
        getSubscriptionPlans();
    }, []);

    return (
        <div className="dashboard__container">
             <Helmet>
                <meta charSet="utf-8" />
                <title>Subscription Packages | Dashboard</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="subscription__container">
                    <h2>Subscription Packages</h2>
                    <div className="plan__list d-flex">
                        {serviceType.length >= 1 && serviceType.map((value) =>  {
                            return (
                                <label key={value.id} className="container">{value}
                                    <input type="radio"  name="radio" id={value.replace(/\s/g,'').toLowerCase()} onChange={selectService}/>
                                    <span className="checkmark"></span>
                                </label>
                            )
                        })}
                    </div>
                  
                    {show === "all"? (
                        <>
                         {subscriptionPackage.map((value) => {
                             return (
                                 <>
                                 <div className="plan__container">
                                         
                                        {value.package.map((value) => {
                                            return (
                                                <>
                                                <div className="plan">
                                                    <img src={SubscriptionIcon} />
                                                    <h5>{value.name}</h5>
                                                    <h6>D {value.cost}</h6>
                                                    <span>{value.subscription_amount}</span>

                                                    {
                                                        authUser.type == "user" ? (
                                                            <>
                                                    {
                                                        value.can_change && (value.is_pending || !value.is_pending)  ? (
                                                            <SubscribeButton subId={value.id} subName={value.name} isPending={value.is_pending}></SubscribeButton>

                                                        ) : (
                                                            <button style={{
                                                                backgroundColor:'whitesmoke',
                                                                color:'black',
                                                                fontWeight:'bolder',
                                                                fontSize: '1.1rem'}}>Subscribed
                                                            </button>
                                                        )
                                                    }
                                                            </>
                                                        ) : null
                                                    }

                                                </div>
                                                </>
                                            )
                                        })}
                                          </div>
                                 </>
                             )
                         })}
                        </>
                    ) : null}
                    

                    {subscriptionPackage.map((value) => {
                        return (
                            <>
                               {show === value.name.replace(/\s/g,'').toLowerCase() ? (
                                    <>
                                    <div className="plan__container">
                                   
                                        {value.package.map((value) => {
                                            return (
                                                <>
                                                <div className="plan">
                                                    <img src={SubscriptionIcon} />
                                                    <h5>{value.name}</h5>
                                                    <h6>D {value.cost}</h6>
                                                    <span>{value.subscription_amount}</span>
                                                    {
                                                        authUser.type === 'user' ? (
                                                            <>
                                                    {
                                                        value.can_change && (value.is_pending || !value.is_pending)  ? (
                                                            <SubscribeButton subId={value.id} subName={value.name}  isPending={value.is_pending}></SubscribeButton>

                                                        ) : (
                                                            <button style={{
                                                                backgroundColor:'whitesmoke',
                                                                color:'black',
                                                                fontWeight:'bolder',
                                                                fontSize: '1.1rem'}}>Subscribed
                                                            </button>
                                                        )
                                                    }
                                                            </>
                                                        ) : null
                                                    }

                                                </div>
                                                </>
                                            )
                                        })}
                                          </div>
                                    </>
                             ) : null}
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Packages;

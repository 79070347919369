import React, { useState } from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddInstrumentType = (props) => {

    const [created, setCreated] = useState(false);
    const [notCreated, setNotCreated] = useState(false);
    const [instrument, setInstrument] = useState({'type': '', 'description': ''});
    const [loading, setLoading] = useState(false);

    const submit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
    
        let response = await fetch(url + '/api/v1/create-instrument', {method: 'POST', body: JSON.stringify(instrument),
        headers: {'Content-Type': 'application/json'}, 
        credentials: 'include'});
        let data = await response.json();
     
        if(data) {
            if(data.success) {
                setCreated(true);
                setNotCreated(false);
            } else {
                setNotCreated(true);
                setCreated(false);
            }
            setLoading(false);
            window.scrollTo(0,0);
        }
    }

    const handleChange = (event) => {
        setInstrument({...instrument, [event.target.id]: event.target.value});
    }

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Instrument Type</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  <h3>Add Instrument Type</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                  {created? (
                       <>
                        <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Instrument Created</h6>
                            </div>
                       </>
                   ):null}

                    {notCreated? (
                       <>
                        <div style={{backgroundColor: 'red', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Instrument Not Created</h6>
                            </div>
                       </>
                   ):null}
                   
                      <form className="form__add_plan" onSubmit={ submit }>
                           <div className="one__column">
                               <input type="text" placeholder="Instrument Type" id="type" onChange={handleChange}/>
                           </div>
                           <div className="one__column">
                               <textarea placeholder="Description" id="description" onChange={handleChange}></textarea>
                           </div>
                          <input type="submit" value={loading? "Loading..." : "Create"} />
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default withRouter(AddInstrumentType);

import React, { useState } from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AddSubscriptionAdmin = (props) => {
    const [user, setUser] = useState({
        "firstName": "",
        "middleName": "",
        "lastName": "",
        "password": "",
        "confirmPassword": "",
        "nin": "",
        "telephone1": "",
        "telephone2": "",
        "email": ""
    });
    const [loading, setLoading] = useState(false);
    const [passwortNotMatch, setPasswordNotMatch] = useState(false);
    const [created, setCreated] = useState(false);
    const [emailExists, setEmailExists] = useState(false);

    const submit = async (event) => {
        event.preventDefault();
        if(loading){
            return;
          }
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;


        if(user.password !== user.confirmPassword) {
            setPasswordNotMatch(true);
            setLoading(false);
            return;
        }

        let response = await fetch(url + '/api/v1/create-subscription-admin', {credentials: 'include',method: 'POST', 
        body: JSON.stringify(user), headers: {'Content-Type': 'application/json'}});
        let data = await response.json();

        if(data) {
            if(data.data.emailExists) {
                setLoading(false);
                setEmailExists(true);
                setCreated(false);
                setPasswordNotMatch(false);
            } else {
                setLoading(false);
                setCreated(true);
                setEmailExists(false);
                setPasswordNotMatch(false);
            }
            setLoading(false);
            window.scrollTo(0,0);
        }
    }

    const handleChange = (event) => {
        setUser({...user, [event.target.id]: event.target.value});
    }

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Subscription Admin</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  <h3>Add Subscription Admin</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                   {passwortNotMatch? (
                       <>
                        <div style={{backgroundColor: 'red', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Password Do Not Match</h6>
                            </div>
                       </>
                   ): null}
                   {created? (
                       <>
                        <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>User Created</h6>
                            </div>
                       </>
                   ): null}
                   {emailExists? (
                       <>
                        <div style={{backgroundColor: 'red', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Email Exist</h6>
                            </div>
                       </>
                   ): null}
                      <form className="form__add_plan" onSubmit={ submit }>
                           <div className="one__column">
                               <input type="text" placeholder="First Name" onChange={handleChange} id="firstName"/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Middle Name" onChange={handleChange} id="middleName" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Last Name" onChange={handleChange} id="lastName"/>
                           </div>
                           <div className="one__column">
                               <input type="password" placeholder="Password" onChange={handleChange} id="password"/>
                           </div>
                           <div className="one__column">
                               <input type="password" placeholder="Confirm Password" onChange={handleChange} id="confirmPassword"/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="NIN" onChange={handleChange} id="nin" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Telephone 1" onChange={handleChange} id="telephone1" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Telephone 2" onChange={handleChange} id="telephone2" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Email" onChange={handleChange} id="email" />
                           </div>
                           <div className="one__column">
                               <div className="group">
                                   <label>Image</label>
                                   <input type="file" />
                               </div>
                           </div>
                          <input type="submit" value={loading? 'Loading...' : "Register"} />
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default withRouter(AddSubscriptionAdmin);

import React from 'react';

const LoadingScreen = (props) => {
    const loading = {
        position: "fixed",
        left: "0",
        top: "0",
        right: "0",
        bottom: "0",
        height: "100vh",
        zIndex: "99999 !important",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(84, 94, 117, 1)"
    }




    return (
        <>
        {props.show ? (
        <div className=""
        style={loading}
            >
            <div >
            <i className="fa fa-spinner fa-pulse fa-fw"
                style={{color:"white", position:"absolute", opacity:1,
                fontSize:"3rem"}}></i>
            <span className="sr-only">Loading...</span>
            </div>
        </div>  

        ) :  null}
 
        </>
    )
}

export default LoadingScreen

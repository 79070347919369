import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import { AuthContext } from '../../../App';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import moment from "moment";

const PendingLease = (props) => {
    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;

    const [page, setPage] = useState({page: page_no});
    const [pagination, setPagination] = useState({current_page: "", last_page: ""})
    const [pendingLease, setPendingLease] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noResultFound, setNoResultFound] = useState(false);

    function convertToDDMMYYYY(dateString) {
        const date = moment(dateString, [
          "YYYY-MM-DD",
          "DD-MM-YYYY",
          "MM-DD-YYYY",
          "YYYY-MM-DDTHH:mm:ss.SSSZ",
          // Add more formats as needed
        ]);
    
        if (date.isValid()) {
          return date.format("DD-MM-YYYY");
        } else {
          return "Invalid Date";
        }
      }

    // for the RG
    const getPendingLeases = async () => {
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/pending-lease?page=' + page.page, { credentials: 'include' });
        let data = await response.json();

        console.log('Logging data === ', data);

       if(data) {
           if(data.success) {
            setLoading(false);
             setPendingLease([...data.data.data]);
             setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
            if(data.data.data.length != 0)
                setNoResultFound(false);
            
            else
                setNoResultFound(true);
           } 
       }
 
    }

    const getPendingLeasesRA = async () => {
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/pending-lease-ra?page=' + page.page, { credentials: 'include' });
        let data = await response.json();

       if(data) {
           if(data.success) {
            setLoading(false);
                setPendingLease([...data.data.data]);
                setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
                if(data.data.data.length != 0)
                    setNoResultFound(false);
                
                else
                    setNoResultFound(true);
           } 
       }
 
    }

    const getPendingLeasesUser = async () => {
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/pending-lease-user?page=' + page.page, { credentials: 'include' });
        let data = await response.json();

       if(data) {
           if(data.success) {
            setLoading(false);
             setPendingLease([...data.data.data]);
             setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
            if(data.data.data.length != 0)
                setNoResultFound(false);
            
            else
                setNoResultFound(true);
           } 
       }
 
    }


    const approve = async (bookID,type, userType) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2dce89',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Approve'
          }).then(async (result) => {
            if (result.isConfirmed) {
                let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL + '/api/v1/approve?book_id=' + bookID  + '&type=' + type + '&userType='+ userType: 
                process.env.REACT_APP_PRODUCTION_API_URL + '/api/v1/approve?book_id=' + bookID  + '&type=' + type + '&userType='+ userType;
                let response = await fetch(url,{headers: {'Content-Type': 'application/json'}, credentials: 'include'});
                let data = await response.json();

                if(data.success) {
                    await Swal.fire({
                        icon: "success",
                        title: "Approved!",
                        confirmButtonColor: "#17a2b8",
                      });
                      props.history.push('/');
                      props.history.push('/lease/pending-lease');
                } else {
                    await Swal.fire({
                        icon: "error",
                        title: "Failed!",
                        text: "Error Approving",
                        confirmButtonColor: "#17a2b8",
                      });
                }
            }
          })
    }

    const reject = async (bookID,type) => {
        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Rejection Message',
            inputPlaceholder: 'Reason for rejection..',
            inputAttributes: {
              'aria-label': 'Reason of reject'
            },
            showCancelButton: true,
            confirmButtonText: 'Submit'
          })
          
          if (text) {
              let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL + '/api/v1/reject' : 
              process.env.REACT_APP_PRODUCTION_API_URL + '/api/v1/reject';
              let param = {bookID: bookID, type: type, reason: text};
              let response = await fetch(url, {method: 'POST', body: 
              JSON.stringify(param), headers: {'Content-Type': 'application/json'}, credentials: 'include'});
              let data = await response.json();

              if(data.success) {
                  await Swal.fire('Rejected');
                  props.history.push('/');
                    props.history.push('/lease/pending-lease');
              } else {
                  await Swal.fire('Error!');
              }
          } else {
              await Swal.fire('Empty! Rejection Error');
          }
    }

    const authUser = useContext(AuthContext);

    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/lease/pending-lease?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/lease/pending-lease?page=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/lease/pending-lease?page=' + currentPage);
         }
         window.scrollTo(0,0);
         
     }

    useEffect(() => {
        if(authUser.type === "RA") {
            getPendingLeasesRA();
        } 
        if(authUser.type === "RG"){
            getPendingLeases();
        }
        if(authUser.type === "user") {
            getPendingLeasesUser();
        }
    },[page]);

    if(loading){
        return <LoadingSpinner />;
    }

    return (
        <div className="dashboard__container">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Pending Leases | Dashboard</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">Pending Lease Registrations</div>
                <div className="add-search">
                    <div className="search">
                        {/* <form>
                            <input type="text" placeholder="Search" />
                            <button type="submit"><i className="fas fa-search"></i></button>
                        </form> */}
                    </div>
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                <table>
                       <thead>
                           <tr>
                               <th>Ref. Number</th>
                               <th>Serial Number</th>
                               <th>Date of Receipt</th>
                               {/* <th>Nature of Instrument</th> */}
                               <th>Location of Land</th>
                               <th>Date of Execution</th>
                               <th>Details</th>
                               <th>Documents</th>
                               <th>Action</th>
                           </tr>
                       </thead>
                       <tbody>
                        {pendingLease.length >= 1 ? (
                            <>
                                {pendingLease.map((value) => {
                                                return (
                                                    <>
                                                    {value.book && value.book.length >=1? (
                                                        <>
                                                        <tr>
                                                        <td>{value.book.length >= 1? value.book[0].serial_number : 'N/A'}</td>
                                                        <td>{value.book.length >= 1? value.book[0].reference_number : 'N/A'}</td>
                                                        <td>{value.book.length >= 1? convertToDDMMYYYY(value.book[0].recipient_date) : 'N/A'}</td>
                                                        {/* <td>{value.book.length >= 1? value.book[0].instrumentType : 'N/A'}</td> */}
                                                        <td>{value.book.length >= 1? value.book[0].land_situation : 'N/A'}</td>
                                                        <td>{value.book.length >= 1? convertToDDMMYYYY(value.book[0].execution_date) : 'N/A'}</td>
                                                        <td>
                                                            <ul>
                                                                <li><Link to={"/lease/view/" + value.book_id +"/" + value.book[0].registration_type}  className="view">View</Link></li>
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul>
                                                                <li><Link to={"/document/view/" + value.book_id} className="view">View</Link></li>
                                                            </ul>
                                                        </td>
                                                        <td>
                                                                <button className="btn__approve" onClick={() => approve(value.book_id,value.property_type, authUser.type)}>Approve</button>
                                                                <button className="btn__reject" onClick={() => reject(value.book_id,value.property_type, authUser.type)}>Reject</button>
                                                        </td>
                                                    </tr>
                                                        </>
                                                    ) : null}
                                                    </>
                                                )
                                            })
                                }
                            </>
                        ) : noResultFound ? (
                            <tr>
                                <td colSpan='11'  style={{textAlign:"center"}}>
                                    No records found
                                </td>
                                
                            </tr>

                        ) : null}

                       </tbody>
                   </table>
                </div>
                {pagination.last_page >= 2? (
                    <>
                      <div className="pagination">
                        <button className="pagination__btn"  
                        disabled={pagination.last_page === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                        <button className="pagination__btn" 
                        disabled={ Number(page.page) === pagination.last_page ? true : false} 
                        onClick={next}>{loading? 
                        <i className="fas fa-spinner-3x"></i> : 'Next'}</button>
                    </div>
                    </>
                ): null}
            </div>
        </div>
    )
}

export default PendingLease;

import React, { useState, useEffect } from "react";
import Sidebar from "../../inc/Sidebar";
import TitleHeader from "../../inc/TitleHeader";
import { Helmet } from "react-helmet";
import qs from "query-string";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const SearchLease = (props) => {
  let page_no = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .page
    ? qs.parse(props.location.search, { ignoreQueryPrefix: true }).page
    : 1;

  const [page, setPage] = useState({ page: page_no });
  const [result, setResult] = useState([]);
  const [noResultFound, setNoResultFound] = useState(false);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: "",
    last_page: "",
  });
  const [nameRequired, setNameRequired] = useState(false);
  const [dateRequired, setDateRequired] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    serialNumber: "",
    refNumber: "",
    orNumber: "",
    startDate: "",
    endDate: "",
  });

  const isFormFieldEmpty = Object.values(formFields).every(
    (value) => value === ""
  );

  function convertToDDMMYYYY(dateString) {
    const date = moment(dateString, [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      // Add more formats as needed
    ]);

    if (date.isValid()) {
      return date.format("DD-MM-YYYY");
    } else {
      return "Invalid Date";
    }
  }

  const getSearchLease = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let apiUrl = url + "/api/v1/search-lease";

    const queryParams = {
      page: Number(page?.page), //Include a page parameter
    };

    if (formFields.serialNumber) {
      queryParams.serial_number = formFields.serialNumber;
    }

    if (formFields.refNumber) {
      queryParams.ref_number = formFields.refNumber;
    }

    if (formFields.orNumber) {
      queryParams.or_number = formFields.orNumber;
    }

    if (formFields.firstName) {
      queryParams.first_name = formFields.firstName;
    }

    if (formFields.lastName) {
      queryParams.last_name = formFields.lastName;
    }

    if (formFields.startDate !== null) {
      queryParams.start_date = formFields.startDate;
    }

    if (formFields.endDate !== null) {
      queryParams.end_date = formFields.endDate;
    }

    // Convert the queryParams object to a query string
    const queryString = new URLSearchParams(queryParams).toString();

    // Append the query string to the URL
    if (queryString) {
      apiUrl += `?${queryString}`;
    }

    const response = await fetch(apiUrl, { credentials: "include" });
    const data = await response.json();

    if (data) {
      if (data.success) {
        setResult([...data.data.data]);
        setPagination({
          current_page: data.data.current_page,
          last_page: data.data.last_page,
        });
      }
    }
  };

  const searchByLease = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (
      (formFields.firstName.length > 3 && formFields.lastName === "") ||
      (formFields.firstName === "" && formFields.lastName.length > 3)
    ) {
      setNameRequired(true);
      setLoading(false);
      return;
    } else {
      setNameRequired(false);
    }

    if (
      (formFields.startDate &&
        formFields.endDate === "") ||
      (formFields.endDate &&
        formFields.startDate === "")
    ) {
      setDateRequired(true);
      setLoading(false);
      return;
    } else {
      setDateRequired(false);
    }

    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let apiUrl = url + "/api/v1/search-lease";

    // Create an object to hold the query parameters
    const queryParams = {};

    if (formFields.serialNumber) {
      queryParams.serial_number = formFields.serialNumber;
    }

    if (formFields.refNumber) {
      queryParams.ref_number = formFields.refNumber;
    }

    if (formFields.orNumber) {
      queryParams.or_number = formFields.orNumber;
    }

    if (formFields.firstName) {
      queryParams.first_name = formFields.firstName;
    }

    if (formFields.lastName) {
      queryParams.last_name = formFields.lastName;
    }

    if (formFields.startDate) {
      queryParams.start_date = formFields.startDate;
    }

    if (formFields.endDate) {
      queryParams.end_date = formFields.endDate;
    }

    // Convert the queryParams object to a query string
    const queryString = new URLSearchParams(queryParams).toString();

    // Append the query string to the URL
    if (queryString) {
      apiUrl += `?${queryString}`;
    }

    try {
      const response = await fetch(apiUrl, { credentials: "include" });
      const data = await response.json();

      if (data.success) {
        setResult([...data.data.data]);
        setPagination({
          current_page: data.data.current_page,
          last_page: data.data.last_page,
        });
        setLoading(false);
        if (data.data.data?.length !== 0) {
          setNoResultFound(false);
        } else {
          setNoResultFound(true);
        }
      } else {
        // shortFunds(data.message.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // const handleStartDateChange = (date) => {
  //   setStartDate(date);
  //   setFormFields({
  //     ...formFields,
  //     startDate: moment(date).format("YYYY-MM-DD"),
  //   });
  // };

  // const handleEndDateChange = (date) => {
  //   setEndDate(date);
  //   setFormFields({
  //     ...formFields,
  //     endDate: moment(date).format("YYYY-MM-DD"),
  //   });
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date !== null) {
      setFormFields({
        ...formFields,
        startDate: moment(date).format("YYYY-MM-DD"),
      });
    } else {
      setFormFields({
        ...formFields,
        startDate: "",
      });
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    if (date !== null) {
      setFormFields({
        ...formFields,
        endDate: moment(date).format("YYYY-MM-DD"),
      });
    } else {
      setFormFields({
        ...formFields,
        endDate: "",
      });
    }
  };

  const handleChange = (event) => {
    setFormFields({ ...formFields, [event.target.id]: event.target.value });
  };

  const shortFunds = (msg) => {
    Swal.fire({
      title: "Insufficent funds",
      text: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: "OK",
    });
  };

  const next = () => {
    setLoading(true);
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    setLoading(false);
    window.scrollTo(0, 0);
    props.history.push("/search/search-lease?page=" + currentPage);
  };

  const previous = () => {
    setLoading(true);
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      setLoading(false);
      props.history.push("/search/search-lease?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      setLoading(false);
      props.history.push("/search/search-lease?page=" + currentPage);
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!isFormFieldEmpty) {
      getSearchLease();
    }
  }, [page]);

  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search DRB Global</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="breadcrumb__title">Search Lease Global</div>
        <div className="add-search">
          <div className="search" id="global">
            <form onSubmit={searchByLease}>
              <div class="form-container">
                <div className="form-group alt group">
                  <div className="form-group-inner">
                    <input
                      type="text"
                      className="input-group"
                      placeholder="First Name"
                      id="firstName"
                      onChange={handleChange}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                    <input
                      type="text"
                      className="input-group"
                      placeholder="Last Name"
                      id="lastName"
                      onChange={handleChange}
                      style={{ width: "100%", marginBottom: "5px" }}
                    />
                  </div>
                  {nameRequired ? (
                    <span style={{ color: "red" }}>Full Name Required</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="input-group"
                    placeholder="Ref Number"
                    id="refNumber"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="input-group"
                    placeholder="Serial Number"
                    id="serialNumber"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="input-group"
                    placeholder="OR Number"
                    id="orNumber"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group alt">
                  <div className="form-group-inner">
                    <DatePicker
                      placeholderText="Start Date"
                      className="input-group"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      popperClassName="date-picker-popper"
                      selectsStart
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                    />
                    <span style={{ margin: "0 10px" }}> to </span>
                    <DatePicker
                      placeholderText="End Date"
                      selected={endDate}
                      className="input-group"
                      onChange={handleEndDateChange}
                      popperClassName="date-picker-popper"
                      selectsEnd
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </div>
                  {dateRequired ? (
                    <span style={{ color: "red" }}>Dates Required</span>
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="button"
                  disabled={isFormFieldEmpty ? true : false}
                >
                  {loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    <i className="fas fa-search"></i>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="table__container" style={{ overflowX: "auto" }}>
          <table>
            <thead>
              <tr>
                <th>Serial Number</th>
                <th>Ref Number</th>
                <th>Date of Execution</th>
                <th>Land Situation</th>
                <th>Grantor</th>
                <th>Grantee</th>
                <th>Party Registering</th>
                <th>OR Number</th>
                <th>Details</th>
                <th>Documents</th>
              </tr>
            </thead>
            <tbody>
              {result && result?.length >= 1 ? (
                <>
                  {result[0]?.book && result[0].book?.length >= 1 ? (
                    <>
                      {result &&
                        result?.map((value) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  {value.book[0]
                                    ? value.book[0].serial_number
                                    : null}
                                </td>
                                <td>
                                  {value.book[0]
                                    ? value.book[0].reference_number
                                    : null}
                                </td>
                                <td>
                                  {value.book[0]
                                    ? value.book[0].execution_date
                                    : null}
                                </td>
                                <td>
                                  {value.book[0]
                                    ? value.book[0].land_situation
                                    : null}
                                </td>
                                <td>
                                  {value.lessor?.length >= 1
                                    ? value.lessor[0].first_name +
                                      " " +
                                      value.lessor[0].middle_name +
                                      " " +
                                      value.lessor[0].last_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {value.lessee?.length >= 1
                                    ? value.lessee[0].first_name +
                                      " " +
                                      value.lessee[0].middle_name +
                                      " " +
                                      value.lessee[0].last_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {value.party?.length >= 1
                                    ? value.party[0].first_name +
                                      " " +
                                      value.party[0].middle_name +
                                      " " +
                                      value.party[0].last_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {value.book[0]
                                    ? value.book[0].or_number
                                    : null}
                                </td>
                                <td>
                                  {value.book[0] &&
                                  value.book[0].registration_type === "KL" ? (
                                    <>
                                      <a
                                        href={
                                          "/deed/kombo-lease/view/" +
                                          value.book_id
                                        }
                                        className="view"
                                      >
                                        View
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "/deed/provincial-lease/view/" +
                                          value.book_id
                                        }
                                        className="view"
                                      >
                                        View
                                      </a>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <a
                                    href={"/document/view/" + value.book_id}
                                    className="view"
                                  >
                                    View
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      {result &&
                        result?.map((value) => {
                          return (
                            <>
                              <tr>
                                <td>{value.serial_number}</td>
                                <td>{value.reference_number}</td>
                                <td>
                                  {convertToDDMMYYYY(value.execution_date)}
                                </td>
                                <td>{value.land_situation}</td>
                                <td>
                                  {value.lessor?.length >= 1
                                    ? value.lessor[0].first_name +
                                      " " +
                                      value.lessor[0].middle_name +
                                      " " +
                                      value.lessor[0].last_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {value.lessee?.length >= 1
                                    ? value.lessee[0].first_name +
                                      " " +
                                      value.lessee[0].middle_name +
                                      " " +
                                      value.lessee[0].last_name
                                    : "N/A"}
                                </td>
                                <td>
                                  {value.party?.length >= 1
                                    ? value.party[0].first_name +
                                      " " +
                                      value.party[0].middle_name +
                                      " " +
                                      value.party[0].last_name
                                    : "N/A"}
                                </td>
                                <td>{value.or_number}</td>
                                <td>
                                  {value.registration_type === "KL" ? (
                                    <>
                                      <a
                                        href={
                                          "/deed/kombo-lease/view/" + value.id
                                        }
                                        className="view"
                                      >
                                        View
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "/deed/provincial-lease/view/" +
                                          value.id
                                        }
                                        className="view"
                                      >
                                        View
                                      </a>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <a
                                    href={"/document/view/" + value.id}
                                    className="view"
                                  >
                                    View
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </>
                  )}
                </>
              ) : noResultFound ? (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    No search results found
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        {pagination.last_page >= 2 ? (
          <>
            <div className="pagination">
              <button
                className="pagination__btn"
                disabled={
                  pagination.last_page === 1 || Number(page_no) === 1
                    ? true
                    : false
                }
                onClick={previous}
              >
                Previous
              </button>
              <button
                className="pagination__btn"
                disabled={
                  Number(page_no) === Number(pagination.last_page)
                    ? true
                    : false
                }
                onClick={next}
              >
                {loading ? <i className="fas fa-spinner fa-spin"></i> : "Next"}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SearchLease;

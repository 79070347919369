
import React, { useEffect, useState } from 'react';
import Sidebar from './../../inc/Sidebar';
import TitleHeader from './../../inc/TitleHeader';
import {Helmet} from 'react-helmet';
import qs from 'query-string';
import ExcelButton from './../../inc/ExcelButton';

const WorkerActivity = (props) => {


    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;


    // styles -----------------
    const formStyle = {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        padding: "10px",
      };

    const nameStyle={
        padding: '8px',
        backgroundColor: "white",
        border: '1px solid #dddddd',
        width: "100%"
    }
    const dateStyle={
        padding: '8px',
        backgroundColor: "white",
        border: '1px solid #dddddd'
    };
    const filterStyle={
        padding: "10px 40px",
        border: "none",
        borderRadius: '5px',
        backgroundColor: "#17a2b8",
        border: "1px solid #dddddd"
    }

    // endstyles -------------------

    const [filter, setFilter] = useState({
        id: '',
        full_name:'',
        from_date: '',
        to_date:'',
        resultStatus: false,
        withData: false
      });
    

    const [page, setPage] = useState({page: page_no});
    const [users, setUsers] = useState([]);
    const [report, setReport] = useState([]);
    const [pagination, setPagination] = useState({current_page: "", last_page: ""});
    const [loading, setLoading] = useState(false);

    const [paramsPrep, setParams] = useState('')

    const handleChange = (event) => {
        // console.log(event.target)
        setFilter(prevState => ({
            ...prevState,
            [event.target.id] : event.target.value
        }));
        console.log(filter)
    }

    const getUsers = async () =>{

        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/users-list', { credentials: 'include' });
        let data = await response.json();

       if(data) {
           if(data.success) {
            setUsers(data.data);
           } 
       }
 

    }

    const getReport = async () => {
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
        let response = await fetch(url + '/api/v1/performance?from=' + filter.from_date + '&to=' + filter.to_date + '&id=' + filter.id + '&page=' + page.page, 
        { credentials: 'include' });
        let data = await response.json();

       if(data) {
           if(data.success) {
            setLoading(false);
        
           let temp_user = data.data.user[0]
           let temp_full_name = temp_user.first_name + ' ' + temp_user.middle_name + ' ' + temp_user.last_name
           let temp_params = 'methodName=getReport' + '&fullName=' + temp_full_name + '&id='+ filter.id + '&from=' + filter.from_date + '&to=' + filter.to_date

            setFilter({
                ...filter,
                full_name: temp_full_name,
                resultStatus: true,
                withData: data.data.books.data.length > 0 ? true: false
            });
           
            setReport([...data.data.books.data])
            setParams(
                temp_params
            )

            setPagination({current_page: 0, last_page: data.data.books.last_page});

           } 
       }
 

    }

    const formatRegistrationType = (abbr)=>{
        switch (abbr) {
            case 'KL':
                return 'Kombo Lease'
                
            case 'PL':
                return 'Provincial Lease'
            
            case 'KD':
                return 'Kombo Deed'

            case 'PD':
                return 'Provincial Deed'
                
            case 'MD':
                return 'Miscellaneous Deed'
            
            case 'CD':
                return 'City Deed'
            
            case 'KM':
                return 'Kombo Mortgage'

            case 'PM':
                return 'Provincial Mortgage'
            
            case 'PA':
                return 'Power Of Attorney'

            default:
                return ''

        }


    }

    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/reports/worker-activity?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/reports/worker-activity?page=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/reports/worker-activity?page=' + currentPage);
         }
         window.scrollTo(0,0);
     }


    useEffect(() => {
       getUsers()
        if(filter.id != '' && filter.from_date != '' && filter.to_date != '' && page.page >= 1) {
            getReport();
        } 
        
      }, [page]);
    
    
    return (
        <div className="dashboard__container">

            <Helmet>
                <meta charSet="utf-8" />
                <title>Worker Activity | Dashboard</title>
            </Helmet>

            <div className="sidebar">
                <Sidebar />
            </div>

            <div className="dashboard__content">
                <TitleHeader />

                <div className="breadcrumb__title">Worker  Activity Report</div>
                <div className="add-search">
                    <div className="search" style={{textAlign:"left"}}>
                        <form action="" style={formStyle}>

                            <div>
                                <select style={nameStyle} name="full_name" id="id" onChange={handleChange}>
                                    <option value="" >Select Worker</option>
                                    {
                                        users.map((user)=>{
                                            return(
                                                <option key={user.id} value={user.id}
                                                >{user.first_name} {user.middle_name} {user.last_name}</option>

                                            )
                                            
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <label htmlFor="from" style={{paddingRight:"10px"}}>From</label>
                                <input type="date" style={dateStyle} name="" onChange={handleChange} id="from_date"/>
                            </div>
                            <div>
                                <label htmlFor="to" style={{paddingRight:"10px"}}>To</label>
                                <input type="date" style={dateStyle} name="" onChange={handleChange} id="to_date"/>
                            </div>
                            <div>
                                <input type="button" style={filterStyle} name="" value="Filter" id="filter"
                                disabled={ ( filter.id != ''  && filter.from_date != '' 
                                && filter.to_date != '') ? false: true}
                                onClick={() => getReport()}
                                />
                            </div>
                        </form>
                    </div>
                </div>

                {filter.resultStatus == true &&

                    <div>
                        


                                <div className="table__container" style={{overflowX:'auto'}}>

                                    <header style={{textAlign:"center"}}>
                                        <h3>
                                            {filter.full_name}
                                        </h3>
                                    </header>     
                                    {
                                        filter.withData == true &&
                                        <div style={{textAlign:"right", paddingBottom:"10px"}}>
                                            <ExcelButton methodName={"getReport"} params={paramsPrep} />

                                        </div>         
                                        
                                    }

                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Registration Type</th>
                                                <th>Serial Number</th>
                                                <th>Reference Number</th>
                                                <th>Modification Date</th>
                                            </tr>
                                        </thead>


                                <tbody>

                                { 
                                (filter.resultStatus == true && filter.withData == true) &&

                                 report.map((value) => {
                                     return (

                                          <tr key={value.id}>
                                                <td>{formatRegistrationType(value.registration_type)}</td>
                                                <td>{value.serial_number}</td>
                                                <td>{value.reference_number}</td>
                                                <td>{value.modification_datetime}</td>
                                          </tr>

                                     )
                                 }) 
                                
                                }
                                { 
                                (filter.resultStatus == true && filter.withData == false) &&

                                 <tr>
                                     <td colSpan='4' style={{textAlign:"center"}}>
                                         <i>No available entries</i>
                                     </td>
                                 </tr>
                                
                                }
                                </tbody>
                            </table>
                            </div>
                            {pagination.last_page >= 2? (
                                        <>
                                        <div className="pagination">
                                            <button className="pagination__btn"  
                                            disabled={pagination.last_page === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                                            <button className="pagination__btn" 
                                            disabled={ Number(page_no) === Number(pagination.last_page) ? true : false} 
                                            onClick={next}>{loading? 
                                            <i className="fas fa-spinner fa-spin"></i> : 'Next'}</button>
                                        </div>
                                        </>
                            ): null}

                    </div>


                }


            </div>
        </div>
    )
}

export default WorkerActivity

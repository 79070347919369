import React, { useState, useEffect } from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { useParams, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const EditLeaseRegistrationType = (props) => {

    const { id } = useParams();

    const [updated, setUpdated] = useState(false);
    const [notUpdated, setNotUpdated] = useState(false);
    const [lease, setLease] = useState({'type': '','code': '', 'description': ''});
    const [loading, setLoading] = useState(false);


    const getLease  = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/get-lease-registration?id=' + id, {credentials: 'include'});
        let data = await response.json();
        if(data) {
            setLease({type: data.data[0].type, code: data.data[0].code, description: data.data[0].description, id: id});
        }
    }

    const submit = async (event) => {
        event.preventDefault();
        setLoading(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
  
        let response = await fetch(url + '/api/v1/update-lease-registration', {method: 'PUT', body: JSON.stringify(lease),
        headers: {'Content-Type': 'application/json'}, 
        credentials: 'include'});
        let data = await response.json();
       
        if(data) {
            if(data.success) {
                setUpdated(true);
                setNotUpdated(false);
            } else {
                setNotUpdated(true);
                setUpdated(false);
            }
            setLoading(false);
            window.scrollTo(0,0);
        }
    }

    const handleChange = (event) => {
        setLease({...lease, [event.target.id]: event.target.value});
    }

    useEffect(() => {
       getLease();
    }, [])

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Lease Registration Types</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
              <div className="registration__page">
                  <h3>Add Lease Registration Type</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">
                  {updated? (
                       <>
                        <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Lease Updated</h6>
                            </div>
                       </>
                   ):null}

                    {notUpdated? (
                       <>
                        <div style={{backgroundColor: 'red', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>Lease Not Updated</h6>
                            </div>
                       </>
                   ):null}
                      <form className="form__add_plan" onSubmit={ submit }>
                           <div className="one__column">
                               <input type="text" placeholder="Lease Registration Type" id="type" value={lease.type} onChange={handleChange}/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="CODE" id="code" value={lease.code} onChange={handleChange}/>
                           </div>
                           <div className="one__column">
                               <textarea placeholder="Description" id="description" value={lease.description} onChange={handleChange}></textarea>
                           </div>
                          <input type="submit" value={loading? 'Loading...' : 'Update'} />
                      </form>
                  </div>
              </div>
            </div>
        </div>
    )
}

export default withRouter(EditLeaseRegistrationType);

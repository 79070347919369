import React, { useState, useEffect } from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const EditSubscriptionPlan = (props) => {

    // style
    const errorStyle = {
        color: 'red',
        fontSize: '13px !important',
        padding: '0.5rem 0',
        fontWeight: 'bold'
        
    }

    const { id } = useParams();


    const [subscription, setSubscription] = useState({
        "id":"",
        "name": "",
        "picture":"",
        "subscription_amount":"",
        "subscription_type":"",
        "description": "",
        "cost": "",
    });

    const [loading, setLoading] = useState(false);

    const [updated, setUpdated] = useState(false);
    const [plans, setPlans] = useState([]);

    const [formErrors, setFormErrors] = useState({
        name: "",
        picture:"",
        subscription_amount:"",
        subscription_type:"",
        description: "",
        cost: "",
    
    });


    const getSubscriptionPlan = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/get-subscription-plan?id='+ id, {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setSubscription({...data.data[0]});
        } 
    }
    const getPlans = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/plans', {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setPlans([...data.data.data]);
        } 
    }

    const submit = async (event) => {
        event.preventDefault();
        if(loading){
            return;
          }
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        setLoading(true);

        // Form errors
        let isExist = handleFormErrors();

        if (isExist === true){

            // console.log(isExist, 'if')
            setLoading(false)
            return
        }
        else
        {
            // console.log(isExist, 'else')

            let response = await fetch(url + '/api/v1/update-subscription-plan', {credentials: 'include',method: 'PUT', 
            body: JSON.stringify(subscription), headers: {'Content-Type': 'application/json'}});
            let data = await response.json();
    
            if(data) {
                if(data.success) {
                    setLoading(false);
                    setUpdated(true);
                } else {
                    setLoading(false);
                    setUpdated(false);
                }
                setLoading(false);
                window.scrollTo(0,0);
            }
        }

        
    }

    const handleChange = (event) => {

        setSubscription(prevState => ({
            ...prevState,
            [event.target.id] : event.target.value
        }));

    }

    const handleFormErrors = ()=>{
        let isExist = false;
        
        setFormErrors({
            ...formErrors,
            name: "",
            picture:"",
            subscription_amount:"",
            subscription_type:"",
            description: "",
            cost: "",
            
        })

        if(subscription.name.length <= 1 || subscription.name.length >= 25){

            setFormErrors(prevState => ({
                ...prevState,
                name: 'Subscription name must be between 1 and 25 characters',
                
            }));
            isExist = true;
        }
        if(subscription.description.length == 0){

            setFormErrors(prevState => ({
                ...prevState,
                description: 'Description must not be blank',
                

            }));
            isExist = true;

        }
        if(subscription.subscription_type == '' || subscription.subscription_type == 0){

            setFormErrors(prevState => ({
                ...prevState,
                subscription_type: 'Please choose a Subscription Plan',
                

            }));
            isExist = true;

        }
        if(isNaN(parseFloat(subscription.cost))){

            setFormErrors(prevState => ({
                ...prevState,
                cost: 'Please enter valid numbers',
                

            }));
            isExist = true;

        }
        if(isNaN(parseFloat(subscription.subscription_amount))){

            setFormErrors(prevState => ({
                ...prevState,
                subscription_amount: 'Please enter valid numbers',
                

            }));
            isExist = true;

        }
        return isExist;
    }

    useEffect(() => {
        getSubscriptionPlan();
        getPlans()    
    }, [])


    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Subscription Plan</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="registration__page">
                    
                    <h3>Edit Subscription Plan</h3>
                    <span className="underline"></span>
                    <div className="registration__form__container">

                        {updated? (
                            <>
                                <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                                    margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                        <h6>Subscription Updated</h6>
                                    </div>
                            </>
                        ): null}

                        <form className="form__add_plan" onSubmit={ submit }>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.name != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.name}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Name</label>
                              <input type="text" placeholder="Name" name='name' value={subscription.name} id="name" onChange={handleChange} />
                          </div>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.description != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.description}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Description</label>
                              <textarea placeholder="Description" id="description" value={subscription.description} onChange={handleChange}></textarea>
                          </div>

                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.subscription_type != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.subscription_type}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Subscription Plan</label>
                              <select id="subscription_type" onChange={handleChange} >
                                  <option value={0} disabled>Select</option>
                                  {plans.map((value) => {
                                      return (
                                          <option key={value.id}  value={value.name} 
                                          selected = {value.name ==  subscription.subscription_type}
                                          >{value.name}</option>
     
                                      )
                                  })}
                              </select>
                          </div>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.cost != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.cost}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Cost</label>
                              <input type="text" placeholder="Price" id="cost" value={subscription.cost} onChange={handleChange}/>
                          </div>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.subscription_amount != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.subscription_amount}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Subscription Amount</label>
                              <input type="text" placeholder="Subscription Amount" id="subscription_amount"
                              value={subscription.subscription_amount}
                              onChange={handleChange}/>
                          </div>
                          <input type="submit" value={loading? 'Loading...' : "Update"} />

                        </form>
                    </div>
                </div>
                
            </div>

        </div>
    )
}

export default EditSubscriptionPlan

import React, { useState } from "react";
import Sidebar from "../../../inc/Sidebar";
import TitleHeader from "../../../inc/TitleHeader";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";

const AddProvincialLease = (props) => {
  const [loading, setLoading] = useState(false);
  const [executionDate, setExecutionDate] = useState(null);
  const [executionDateText, setExecutionDateText] = useState("");
  const [dateOfReceipt, setDateOfReceipt] = useState(null);
  const [dateOfReceiptText, setDateOfReceiptText] = useState("");
  const [landLocation, setLandLocation] = useState("");
  const [lomp, setLomp] = useState("");
  const [partyRegistering, setPartyRegistering] = useState({
    companyName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    nin: "",
    dob: "",
    address: "",
    telephone1: "",
    telephone2: "",
    email: "",
  });
  const [required, setRequired] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState({
    orNumber: "",
    stampDuty: "",
  });
  const [notes, setNotes] = useState({ notes: "" });
  const [recipient, setRecipient] = useState({
    companyName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    nin: "",
    dob: "",
    address: "",
    telephone1: "",
    telephone2: "",
    email: "",
  });
  const [location, setLocation] = useState({
    boxNumber: "",
    locationNumber: "",
  });
  const [witnessInformation, setWitnessInformation] = useState([
    {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      nin: "",
      telephone: "",
    },
  ]);
  const [lessee, setLessee] = useState([
    {
      companyName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    },
  ]);
  const [lessor, setLessor] = useState([
    {
      companyName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    },
  ]);

  const addLesseeRow = (e) => {
    e.preventDefault();
    lessee.push({
      companyName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    });
    setLessee([...lessee]);
  };

  const removeLesseeRow = (e, index) => {
    e.preventDefault();
    lessee.splice(index);
    setLessee([...lessee]);
  };

  const saveLessee = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const tempLessee = [...lessee];
    tempLessee[index][id] = value;
    setLessee(tempLessee);
  };

  const addLessorRow = (e) => {
    e.preventDefault();
    lessor.push({
      companyName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nin: "",
      dob: "",
      address: "",
      telephone1: "",
      telephone2: "",
      email: "",
    });
    setLessor([...lessor]);
  };

  const removeLessorRow = (e, index) => {
    e.preventDefault();
    lessor.splice(index);
    setLessor([...lessor]);
  };

  const saveLessor = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const tempLessor = [...lessor];
    tempLessor[index][id] = value;
    setLessor(tempLessor);
  };

  const addWitnessInformationRow = (e) => {
    e.preventDefault();
    witnessInformation.push({
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      nin: "",
      telephone: "",
    });
    setWitnessInformation([...witnessInformation]);
  };

  const removeWitnessInformationRow = (e, index) => {
    e.preventDefault();
    witnessInformation.splice(index);
    setWitnessInformation([...witnessInformation]);
  };

  const saveWitnessInformation = (e, index) => {
    e.preventDefault();
    const { id, value } = e.target;
    const tempWitness = [...witnessInformation];
    tempWitness[index][id] = value;
    setWitnessInformation(tempWitness);
  };

  const savePartyRegistering = (event) => {
    setPartyRegistering({
      ...partyRegistering,
      [event.target.id]: event.target.value,
    });
  };

  const saveRecipient = (event) => {
    setRecipient({ ...recipient, [event.target.id]: event.target.value });
  };

  const saveNotes = (event) => {
    setNotes({ ...notes, [event.target.id]: event.target.value });
  };

  const saveLocation = (event) => {
    setLocation({ ...location, [event.target.id]: event.target.value });
  };

  const saveLandLocation = (event) => {
    setLandLocation(event.target.value);
  };

  const saveLomp = (event) => {
    const val = event.target.value;
    if(val){
      getCaveatData(val);
    }
    setLomp(val);
  };

  const getCaveatData = async (query) => {
    try {
      let url =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_API_URL
          : process.env.REACT_APP_PRODUCTION_API_URL;
  
      let response = await fetch(
        url + "/api/v1/book/check-caveat?ref_number=" + query,
        { credentials: "include" }
      );
  
      // Check if the response is okay (status code 200)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      let data = await response.json();

  
      if (data && data.success) {
        const row = data.data;
        let hasCaveat = false;
        let hasReleaseOfCaveat = false;
  
        // Check if the 'data' array is not empty
        if (row && row.length > 0) {
          row.forEach(element => {
            if (element === 'CAVEAT') {
              hasCaveat = true;
            } else if (element === 'RELEASE OF CAVEAT') {
              hasReleaseOfCaveat = true;
            }
          });
  
          if (hasCaveat && !hasReleaseOfCaveat) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Cannot Registration, this document has caveat",
              customClass: "swal-height",
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (hasCaveat && hasReleaseOfCaveat) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "You can processed with registration, the document has release of caveat",
              customClass: "swal-height",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
         
        }
      }
    } catch (error) {
      
    }
  };

  const handleExecusionDate = (date) => {
    setExecutionDate(date);
    let month = date.getMonth() + 1;
    let selectedDate = date.getDate();
    let year = date.getFullYear();
    let fullDate = month + "-" + selectedDate + "-" + year;
    setExecutionDateText(fullDate);
  };

  const handleDateOfReceipt = (date) => {
    setDateOfReceipt(date);
    let month = date.getMonth() + 1;
    let selectedDate = date.getDate();
    let year = date.getFullYear();
    let fullDate = month + "-" + selectedDate + "-" + year;
    setDateOfReceiptText(fullDate);
  };

  const submit = async (event) => {
    event.preventDefault();
    if (loading) {
      return;
    }

    setLoading(true);
    let date = {
      executionDate: executionDateText,
      receiptDate: dateOfReceiptText,
    };
    let data = {
      note: notes,
      date: date,
      landLocation: landLocation,
      partyRegistering: partyRegistering,
      location: location,
      witness: witnessInformation,
      recipient: recipient,
      lessor: lessor,
      lessee: lessee,
      lomp: lomp,
    };
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/lease-registration?lease_type=PL"
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/lease-registration?lease_type=PL";

    if (
      executionDateText === "" ||
      dateOfReceiptText === "" ||
      partyRegistering.orNumber === "" ||
      partyRegistering.stampDuty === ""
    ) {
      setRequired(true);
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }
    setRequired(false);
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let result = await response.json();
    if (result) {
      if (result.success) {
        await Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Registered",
          customClass: "swal-height",
          showConfirmButton: false,
          timer: 1500,
        });
        props.history.push("/lease/provincial-lease");
        setLoading(false);
      } else if (!result.success && result.message.type == "short funds") {
        await Swal.fire({
          title: "Insufficent funds",
          text: result.message.message,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#17a2b8",
          confirmButtonText: "OK",
        });
      } else {
        await Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Error!",
          customClass: "swal-height",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      }
    }
    setLoading(false);
  };

  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Add Provincial Lease | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="registration__page">
          <h3>Provincial Lease Registration</h3>
          <span className="underline"></span>
          <div className="registration__form__container">
            <h5>Registration Number</h5>
            <form className="form" onSubmit={submit}>
              <div className="two__column">
                <input type="text" placeholder="L.O.M.P" onChange={saveLomp} />
                <input
                  type="text"
                  placeholder="Location of Land"
                  onChange={saveLandLocation}
                />
              </div>
              <h5>Parties Involved</h5>
              <h5>Lessor</h5>
              {lessor.map((value, index) => {
                return (
                  <>
                    <div className="one__column">
                      <input
                        type="text"
                        placeholder="Company Name"
                        id="companyName"
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="firstName"
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Middle Name"
                        id="middleName"
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        id="lastName"
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="nin"
                        id="nin"
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        id="dob"
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Address"
                        id="address"
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="Telephone 1"
                        id="telephone1"
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Telephone 2"
                        id="telephone2"
                        onChange={(e) => saveLessor(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Email"
                        id="email"
                        onChange={(e) => saveLessor(e, index)}
                      />
                    </div>
                    <div className="add__more">
                      <button onClick={addLessorRow}>
                        <i className="fas fa-plus"></i>
                      </button>
                      {index >= 1 ? (
                        <button onClick={(e) => removeLessorRow(e, index)}>
                          <i className="fas fa-minus"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                );
              })}

              <h5>Lessee</h5>
              {lessee.map((value, index) => {
                return (
                  <>
                    <div className="one__column">
                      <input
                        type="text"
                        placeholder="Company Name"
                        id="companyName"
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="firstName"
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Middle Name"
                        id="middleName"
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        id="lastName"
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="nin"
                        id="nin"
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        id="dob"
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Address"
                        id="address"
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="Telephone 1"
                        id="telephone1"
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Telephone 2"
                        id="telephone2"
                        onChange={(e) => saveLessee(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Email"
                        id="email"
                        onChange={(e) => saveLessee(e, index)}
                      />
                    </div>
                    <div className="add__more">
                      <button onClick={addLesseeRow}>
                        <i className="fas fa-plus"></i>
                      </button>
                      {index >= 1 ? (
                        <button onClick={(e) => removeLesseeRow(e, index)}>
                          <i className="fas fa-minus"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                );
              })}
              <h5>Transaction Dates</h5>
              <div className="two__column">
                <div className="group">
                  <label>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </label>
                  <DatePicker
                    placeholderText="Execusion Date"
                    className="input-group"
                    selected={executionDate}
                    onChange={handleExecusionDate}
                    popperClassName="date-picker-popper"
                    showYearDropdown
                  />
                </div>
                <div className="group">
                  <label>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                  </label>
                  <DatePicker
                    placeholderText="Receipt Date"
                    className="input-group"
                    selected={dateOfReceipt}
                    onChange={handleDateOfReceipt}
                    popperClassName="date-picker-popper"
                    showYearDropdown
                  />
                </div>
              </div>
              <h5>Party Registering</h5>
              <div className="one__column">
                <input
                  type="text"
                  placeholder="Company Name"
                  id="companyName"
                  onChange={savePartyRegistering}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="First Name"
                  id="firstName"
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Middle Name"
                  id="middleName"
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  id="lastName"
                  onChange={savePartyRegistering}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="nin"
                  id="nin"
                  onChange={savePartyRegistering}
                />
                <input
                  type="date"
                  placeholder="Date of Birth"
                  id="dob"
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Address"
                  id="address"
                  onChange={savePartyRegistering}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="Telephone 1"
                  id="telephone1"
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Telephone 2"
                  id="telephone2"
                  onChange={savePartyRegistering}
                />
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  onChange={savePartyRegistering}
                />
              </div>
              <h5>Recipient</h5>
              {required ? <span style={{ color: "red" }}>Required</span> : null}
              <div className="one__column">
                <input
                  type="text"
                  placeholder="Company Name"
                  id="companyName"
                  onChange={saveRecipient}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="First Name"
                  id="firstName"
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Middle Name"
                  id="middleName"
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  id="lastName"
                  onChange={saveRecipient}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="nin"
                  id="nin"
                  onChange={saveRecipient}
                />
                <input
                  type="date"
                  placeholder="Date of Birth"
                  id="dob"
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Address"
                  id="address"
                  onChange={saveRecipient}
                />
              </div>
              <div className="three__column">
                <input
                  type="text"
                  placeholder="Telephone 1"
                  id="telephone1"
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Telephone 2"
                  id="telephone2"
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Email"
                  id="email"
                  onChange={saveRecipient}
                />
              </div>
              {required ? <span style={{ color: "red" }}>Required</span> : null}
              <div className="three__column">
                <input
                  type="text"
                  placeholder="OR Number"
                  id="orNumber"
                  onChange={saveRecipient}
                />
                <input
                  type="text"
                  placeholder="Stamp Duty"
                  id="stampDuty"
                  onChange={saveRecipient}
                />
              </div>
              <div className="one__column">
                <textarea
                  placeholder="Notes"
                  id="notes"
                  onChange={saveNotes}
                ></textarea>
              </div>
              <h5>Witness Information</h5>
              {witnessInformation.map((value, index) => {
                return (
                  <>
                    {required ? (
                      <span style={{ color: "red" }}>Required</span>
                    ) : null}
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="First Name"
                        id="firstName"
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Middle Name"
                        id="middleName"
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Last Name"
                        id="lastName"
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                    </div>
                    <div className="three__column">
                      <input
                        type="text"
                        placeholder="ID Number"
                        id="nin"
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="date"
                        placeholder="Date of Birth"
                        id="dob"
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                      <input
                        type="text"
                        placeholder="Telephone"
                        id="telephone"
                        onChange={(e) => saveWitnessInformation(e, index)}
                      />
                    </div>
                    <div className="add__more">
                      <button onClick={addWitnessInformationRow}>
                        <i className="fas fa-plus"></i>
                      </button>
                      {index >= 1 ? (
                        <button
                          onClick={(e) => removeWitnessInformationRow(e, index)}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                      ) : null}
                    </div>
                  </>
                );
              })}
              <h5>Location in archive</h5>
              <div className="two__column">
                <input
                  type="text"
                  placeholder="Box Number"
                  id="boxNumber"
                  onChange={saveLocation}
                />
                <input
                  type="text"
                  placeholder="Location Number"
                  id="locationNumber"
                  onChange={saveLocation}
                />
              </div>
              <input
                type="submit"
                value={loading ? "Loading..." : "Register"}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AddProvincialLease);

import React, { useState, useEffect, useContext } from "react";
import RegistrationsPerMonth from "./graphs/RegistrationsPerMonth";
import Sidebar from "../inc/Sidebar";
import TitleHeader from "../inc/TitleHeader";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../App";
import Search from "../../assets/imgs/search.png";
import Registration from "../../assets/imgs/registration.png";
import SubscriptionIcon from "../../assets/imgs/subscription.png";
import SubscribeButton from "../inc/SubscribeButton";
import Chart1SA from "./graphs/Chart1SA";

const Dashboard = () => {
  const authUser = useContext(AuthContext);

  const [leases, setLeases] = useState(0);
  const [deed, setDeed] = useState(0);
  const [users, setUsers] = useState(0);
  const [registerCountBalance, setRegisterCountBalance] = useState(0);
  const [searchCountBalance, setSearchCountBalance] = useState(0);
  const [packages, setPackages] = useState({
    subedPackages: [],
    pendingPackages: [],
  });

  const [registrationYears, setRegistrationYears] = useState([]);

  const getPendingDeed = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/pending-drb-count", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setDeed(data.data);
    }
  };

  const getPendingDeedRA = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/pending-drb-count-ra", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setDeed(data.data);
    }
  };

  const getPendingDeedUser = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/pending-drb-count-user", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setDeed(data.data);
    }
  };

  const getPendingLeases = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/pending-lease-count", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setLeases(data.data);
    }
  };

  const getPendingLeasesRA = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/pending-lease-count-ra", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setLeases(data.data);
    }
  };

  const getPendingLeasesUser = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/pending-lease-count-user", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setLeases(data.data);
    }
  };

  const getUsersCount = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(url + "/api/v1/users-count", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setUsers(data.data);
    }
  };

  const getRegisterCountBalance = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/register-count-balance", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      setRegisterCountBalance(data.data);
    }
  };

  const getSearchCountBalance = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/search-count-balance", {
      credentials: "include",
    });
    let data = await response.json();

    if (data) {
      // console.log(data)
      setSearchCountBalance(data.data);
    }
  };

  const getSubscribedPackages = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/user-packages", {
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setPackages({
        ...packages,
        subedPackages: [...data.data.subscriptions],
        pendingPackages: [...data.data.pending],
      });
    }
  };

  const getRegistrationYears = async () => {
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;
    let response = await fetch(url + "/api/v1/years?type=registration", {
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setRegistrationYears([...data.data]);
    } else {
      console.log(data);
    }
  };

  useEffect(async () => {
    if (localStorage.getItem("type") && localStorage.getItem("type") === "RG") {
      getPendingDeed();
      getPendingLeases();
      getUsersCount();
      getRegistrationYears();
    }

    else if (localStorage.getItem("type") && localStorage.getItem("type") === "RA") {
      getPendingDeedRA();
      getPendingLeasesRA();
      getUsersCount();
      getRegistrationYears();
    }

    else if (
      localStorage.getItem("type") &&
      localStorage.getItem("type") === "user"
    ) {
      getPendingLeasesUser();
      getPendingDeedUser();
      getRegisterCountBalance();

      getSearchCountBalance();

      getSubscribedPackages();
      getSearchCountBalance();
    }


  }, [users, leases, deed]);
  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>GDR - ADMIN | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className={`dashboard__content ${authUser.type === 'SA' ? "bg-white" : ""}`}>
        <TitleHeader />

        {
          localStorage.getItem("type")  === "RA" ||
          localStorage.getItem("type")  === "RG" ||
          localStorage.getItem("type")  === "user" ?
          (
            <>

<div className="wrapper-3">
        <div className="wrapper-3-gradient">
          <div className="mb-5 py-5 text-white ">
            <div className="row">
              <div 
              
              className={`px-5 pt-3 pb-2 border-right ${authUser.type === 'user' ? "col-6" : "col-4"}`}
              >
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className=" text-bold text-sm">
                      PENDING LEASES
                    </span>
                  </div>
                  <div className="col-12">
                    <span
                      style={{ fontSize: "2.4rem" }}
                      className=" text-bold "
                    >
                      {leases}
                    </span>
                  </div>
                </div>
              </div>
              <div
               className={`px-5 pt-3 pb-2 border-right ${authUser.type === 'user' ? "col-6" : "col-4"}`}
              >
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className=" text-bold text-sm">
                      PENDING DEEDS
                    </span>
                  </div>
                  <div className="col-12">
                    <span
                      style={{ fontSize: "2.4rem" }}
                      className=" text-bold"
                    >
                      {deed}

                    </span>
                  </div>
                </div>
              </div>
              {authUser.type === "RA" || authUser.type === "RG" ? 
              (
                <div className="col-4 px-5 pt-3 pb-2 border-right">
                <div className="row">
                  <div className="col-12 pb-2">
                    <span className=" text-bold text-sm">USERS</span>
                  </div>
                  <div className="col-12">
                    <span
                      style={{ fontSize: "2.4rem" }}
                      className=" text-bold"
                    >
                      {users}
                    </span>
                  </div>

                </div>
              </div>
              ) : null}

            </div>

            
          </div>
        </div>
      </div>


  
            </>
  
          ) : null
        }


        <div >
          {localStorage.getItem("type") === "RA" ||
          localStorage.getItem("type") === "RG" ? (
            <div className="custom-card-1 mt-3 mx-4">
              <div className="graph__box revenue__graph">
                <div className="graph__title" style={{ textAlign: "center" }}>
                  <h5>Book Registrations Per Year</h5>
                </div>
                <RegistrationsPerMonth years={registrationYears} />
              </div>
            </div>
          ) : null}
          {localStorage.getItem("type") === "user" ? (
            <div className="dashboard__graph">
            <div style={{ width: "100%" }}>
              <div className="analytics">
                {/* <div className="available_reg_balance">
                  <div className="icon">
                    <img
                      src={Search}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                  <h5>Balance: {searchCountBalance} Searches</h5>
                </div> */}
                <div className="available_search_balance">
                  <div className="icon">
                    <img
                      src={Registration}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                  <h5>Balance: {registerCountBalance} Registrations</h5>
                </div>
              </div>

              <div style={{ textAlign: "center" }}>
                {packages.subedPackages.length === 0 &&
                packages.pendingPackages.length === 0 ? (
                  <div>
                    <div className="breadcrumb__title">Packages</div>

                    <div>You have no subbed or pending packages</div>
                  </div>
                ) : (
                  <div className="subscription__container">
                    <div className="breadcrumb__title">
                      Packages Subscribed to
                    </div>

                    {packages.subedPackages.length === 0 ? (
                      <div>You have no subscribed packages</div>
                    ) : (
                      <div className="plan__container">
                        {packages.subedPackages.map((value) => {
                          return (
                            <div key={value.subscription_id} className="plan">
                              <img src={SubscriptionIcon} />
                              <h5>{value.subscription_name}</h5>
                              <h6>D {value.subscription_cost}</h6>
                              <span>{value.subscription_amount}</span> <br />
                              <span>
                                <small>Plan: {value.subscription_type} </small>{" "}
                              </span>
                              <button
                                style={{
                                  backgroundColor: "whitesmoke",
                                  color: "black",
                                  fontWeight: "bolder",
                                  fontSize: "1.1rem",
                                }}
                              >
                                Subscribed
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div
                      className="breadcrumb__title"
                      style={{
                        borderTop: "1px dashed #17a2b8",
                        marginTop: "2rem",
                      }}
                    >
                      Packages Pending Approval
                    </div>
                    {packages.pendingPackages.length === 0 ? (
                      <div>You have no pending packages</div>
                    ) : (
                      <div className="plan__container">
                        {packages.pendingPackages.map((value) => {
                          return (
                            <div key={value.subscription_id} className="plan">
                              <img src={SubscriptionIcon} />
                              <h5>{value.subscription_name}</h5>
                              <h6>D {value.subscription_cost}</h6>
                              <span>{value.subscription_amount}</span> <br />
                              <span>
                                <small>Plan: {value.subscription_type} </small>{" "}
                              </span>
                              <SubscribeButton
                                subId={value.subscription_id}
                                subName={value.subscription_name}
                                isPending={true}
                              ></SubscribeButton>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          ) : null}
        </div>
        {localStorage.getItem("type") === "SA" ? (
          <>
            <Chart1SA />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Dashboard;

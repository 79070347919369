import React, { useState, useEffect } from 'react';
import Sidebar from '../../inc/Sidebar';
import TitleHeader from '../../inc/TitleHeader';
import qs from 'query-string';
import { Link } from 'react-router-dom';

const BusinessType = (props) => {
    let page_no = qs.parse(props.location.search, {ignoreQueryPrefix: true}).page? 
    qs.parse(props.location.search, {ignoreQueryPrefix: true}).page : 1;

    const [page, setPage] = useState({page: page_no});
    const [pagination, setPagination] = useState({current_page: "", last_page: ""})
    const [businessType, setBusinessType] = useState([]);
    const [loading, setLoading] = useState(false);

    const getBusinessType = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/business-type?page=' + page.page, { credentials: 'include' });
        let data = await response.json();

       if(data) {
           if(data.success) {
             setBusinessType([...data.data.data]);
             setPagination({current_page: data.data.current_page, last_page: data.data.last_page});
           } 
       }
 
    }

    const next = () => {
        setLoading(true);
        let currentPage = Number(page.page) + 1;
        setPage({page: currentPage});
        setLoading(false);
        window.scrollTo(0,0);
        props.history.push('/admin/manage-globals/business-type?page=' + currentPage);
     }
 
     
     const previous = () => {
         setLoading(true);
         let currentPage = Number(page.page) - 1;
         if(currentPage === 0 ) {
             setPage({page: 1});
             setLoading(false);
             props.history.push('/admin/manage-globals/business-type?page=' + currentPage);
         } else {
             setPage({page: currentPage});
             setLoading(false);
             props.history.push('/admin/manage-globals/instrument-type?page=' + currentPage);
         }
         window.scrollTo(0,0);
         
     }

    useEffect(() => {
        getBusinessType();
    },[page]);
    return (
        <div className="dashboard__container">
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="breadcrumb__title">Business Type</div>
                <div className="add-search">
                    <div className="add">
                        <Link to="/admin/manage-globals/business-type/add">Add New <i className="fas fa-plus"></i></Link>
                    </div>
                    <div className="search">
                        <form>
                            <input type="text" placeholder="Search" />
                            <button type="submit"><i className="fas fa-search"></i></button>
                        </form>
                    </div>
                </div>
                <div className="table__container" style={{overflowX:'auto'}}>
                   <table>
                       <thead>
                           <tr>
                               <th>Instrument Type</th>
                               <th>Description</th>
                               <th>Action</th>
                           </tr>
                       </thead>
                       <tbody>
                            {businessType.map((value, index) => {
                                return (
                                    <>
                                     <tr>
                                         <td>{value.type}</td>
                                         <td>{value.description}</td>
                                         <td>
                                             <ul>
                                                <li><a href="#" className="view">Edit</a></li>
                                                <li><a href="#" className="delete">Delete</a></li>
                                            </ul>
                                         </td>
                                     </tr>
                                    </>
                                )
                            })}
                       </tbody>
                   </table>
                </div>
                {pagination.last_page >= 2? (
                    <>
                      <div className="pagination">
                        <button className="pagination__btn"  
                        disabled={pagination.last_page === 1 || Number(page_no) === 1? true : false} onClick={previous}>Previous</button>
                        <button className="pagination__btn" 
                        disabled={ Number(page.page) === pagination.last_page ? true : false} 
                        onClick={next}>{loading? 
                        <i className="fas fa-spinner-3x"></i> : 'Next'}</button>
                    </div>
                    </>
                ): null}
            </div>
        </div>
    )
}

export default BusinessType;

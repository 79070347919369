import React from 'react';

const ExcelButton = (props) => {
    const excelStyle = {
        width: '100px',
        height: '40px',
        border: 'none',
        backgroundColor: 'rgb(52,110,57)',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '5px',
        flex: '1 1',
        boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
        cursor: 'pointer',
        margin: '5px'

    }

    const downloadExcel = async ()=>{
        
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        let response = await fetch(url + '/api/v1/excel-export?' + props.params, 
        { credentials: 'include' });
        
        let data = await response.arrayBuffer()
        if(data) {

            let now = new Date()
            let filename = now.getTime() + "_.xlsx"
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        }

  
    }

    return (
        <button onClick={downloadExcel} style={excelStyle}>
           Excel
        </button>
    )
}

export default ExcelButton

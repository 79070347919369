import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Swal from 'sweetalert2';
import axios from 'axios';

const ViewDocument = () => {


    const {book_id} = useParams();

    const [document, setDocument] = useState([]);
    const [open, setOpen] = useState(false);
    const [url, setURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const getDocuments = async () => {
        let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL + '/api/v1/document?book_id=' + book_id : 
        process.env.REACT_APP_PRODUCTION_API_URL + '/api/v1/document?book_id=' + book_id;

        let response = await fetch(url, {headers: {'Content-Type': 'application/json'}, credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setDocument([...data.data]);
        }
    }

    // const viewDocument = async (name) => {
    //     console.log('Logging name === ', name);
    //     setLoading(true);
    //     let url = process.env.NODE_ENV === "development"?
    //     process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;
        
    //    axios.get(url + '/api/v1/get-document?name=' + name, {withCredentials: true, responseType: 'blob'})
    //     .then((response) => {
    //         console.log('Logging view response === ', response);
    //         const file = new Blob(
    //             [response.data], 
    //             {type: 'application/pdf'});
    //         //Build a URL from the file
    //         const fileURL = URL.createObjectURL(file);
    //         setLoading(false);
    //         //Open the URL on new Window
    //         setURL(fileURL);
    //         window.open(fileURL,'_self');
    //     }).catch((err) => {

    //     });
        
    // }

    const viewDocument = async (name) => {
        console.log('Logging name === ', name);
        setLoading(true);
    
        let url = process.env.NODE_ENV === "development" ?
            process.env.REACT_APP_DEVELOPMENT_API_URL :
            process.env.REACT_APP_PRODUCTION_API_URL;
    
        try {
            const response = await axios.get(url + '/api/v1/get-document?name=' + name, {
                withCredentials: true,
                responseType: 'blob'
            });
    
            const file = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
    
            setLoading(false);
            setURL(fileURL);
            window.open(fileURL, '_self');
        } catch (error) {
            console.error('Error fetching document:', error);
            setLoading(false);
        }
    };
    

    const deleteDocument = async (documentID, documentName) => {
        setDeleting(true);
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/delete-document?id=' + documentID + '&name=' + documentName, {method: 'DELETE', credentials: 'include'});
        let data = await response.json();

        if(data) {
            if(data.success) {
                await Swal.fire(
                    'Deleted!',
                    '',
                    'success'
                  )
                window.location.reload();
            } else {
                await Swal.fire(
                    'Error Deleting!',
                    '',
                    'error'
                  )
            }
            setDeleting(false);
        }
    }

    const onOpenModal = () => {
        setOpen(true);
      };
     
      const onCloseModal = () => {
        setOpen(false);
      };


    useEffect(() => {
        getDocuments();
    }, [])

    return (
        <div className="dashboard__container">
             <Helmet>
                <meta charSet="utf-8" />
                <title>View Document | Dashboard</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="document__container">
                    <h2>Documents</h2>
                    {document.length > 0? (
                        <>
                          {document?.map((value, index) => {
                              return (
                                  <>

                                  <div className="document">
                                     <p>{value?.name}</p>
                                     <div className="view__download">
                                         <ul>
                                             <li><a href="#" onClick={() => viewDocument(value?.name)}>{loading? <span className="fas fa-spin fa-spinner"></span> : 'View'}</a></li>
                                             <li> / </li>
                                             <li onClick={() => deleteDocument(value.id, value.name)}>{deleting? <span className="fas fa-spin fa-spinner"></span> : <span>Delete - <i className="fas fa-trash"></i></span>}</li>
                                         </ul>
                                     </div>
                                    </div>
                                  </>
                              )
                          })}
                        </>
                    ) : <h1>No Documents</h1>}
                      <Modal open={open} onClose={onCloseModal} center>
                           <div style={{marginTop: '20px'}}>
                                <embed src={url} width="800px" height="500px"/> 
                            </div>   
                      </Modal>
                </div>
             </div>
        </div>
    )
}

export default ViewDocument;

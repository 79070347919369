import React, { useState, useContext } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Icon from "../../assets/imgs/icon.png";
import { Link } from "react-router-dom";
import { AuthContext } from "../../App";

const Sidebar = () => {
  const [navs, setNavs] = useState([
    {
      title: "Dashboard",
      icon: "fas fa-tachometer-alt",
      isOpen: false,
      isActive: false,
      route: "/",
    },
    {
      title: "Registration",
      icon: "fas fa-user",
      arrowDown: false,
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Lease",
          subMenu: [
            {
              title: "Kombo Lease",
              route: "/lease/kombo-lease",
            },
            {
              title: "Provincial Lease",
              route: "/lease/provincial-lease",
            },
          ],
        },
        {
          title: "DRB Registrations",
          subMenu: [
            {
              title: "Kombo Deed",
              route: "/deed/kombo-deed",
            },
            {
              title: "Provincial Deed",
              route: "/deed/provincial-deed",
            },
            {
              title: "Miscellaneous Deed",
              route: "/deed/miscellaneous-deed",
            },
            {
              title: "City Deed",
              route: "/deed/city-deed",
            },
            {
              title: "Kombo Mortgage",
              route: "/mortgage/kombo-mortgage",
            },
            {
              title: "Provincial Mortgage",
              route: "/mortgage/provincial-mortgage",
            },
            {
              title: "Power Of Attorney",
              route: "/deed/power-of-attorney",
            },
          ],
        },
      ],
    },
    {
      title: "Pending Approval",
      icon: "fas fa-times",
      arrowDown: false,
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Lease",
          route: "/lease/pending-lease",
        },
        {
          title: "DRB",
          route: "/drb/pending-drb",
        },
      ],
    },
    {
      title: "Searches",
      icon: "fab fa-searchengin",
      arrowDown: false,
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Search Lease",
          route: "/search/search-lease",
        },
        {
          title: "Search DRB",
          route: "/search/search-drb",
        },
        {
          title: "Transaction Report",
          route: "/search/transaction-report",
        },
      ],
    },
    {
      title: "Admin",
      icon: "fas fa-user-shield",
      arrowDown: false,
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Manage Registra Admin",
          route: "/admin/manage-registra-admin",
        },
        {
          title: "Manage Registra General",
          route: "/admin/manage-registra-general",
        },
        {
          title: "Manage Subscription Admin",
          route: "/admin/manage-subscription-admin",
        },
        {
          title: "New User",
          route: "/admin/manage-users/add",
        },
        {
          title: "Manage Users",
          route: "/admin/manage-users",
        },
        {
          title: "Manage Subscription Plans",
          route: "/admin/manage-subscription-plans",
        },
        {
          title: "Manage Plans",
          route: "/admin/manage-plans",
        },
        {
          title: "Manage Security Questions",
          route: "/admin/manage-security-questions",
        },
        {
          title: "Logs",
          route: "/admin/logs",
        },
      ],
    },
    {
      title: "Manage Globals",
      icon: "fas fa-shield-alt",
      arrowDown: false,
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Instrument Type",
          route: "/admin/manage-globals/instrument-type",
        },
        {
          title: "Business Type",
          route: "/admin/manage-globals/business-type",
        },
        {
          title: "DRB Registration Type",
          route: "/admin/manage-globals/drb-registration-type",
        },
        {
          title: "Lease Registration Type",
          route: "/admin/manage-globals/lease-registration-type",
        },
      ],
    },

    {
      title: "Reports",
      icon: "fas fa-file-alt",
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Worker Activity",
          route: "/reports/worker-activity",
        },
      ],
    },
    {
      title: "Subscription",
      icon: "fas fa-gift",
      isOpen: false,
      isActive: false,
      subMenu: [
        {
          title: "Packages",
          route: "/subscription/packages",
        },
      ],
    },
  ]);
  const [animate, setAnimation] = useState(false);
  const authUser = useContext(AuthContext);
  const toggleMenu = (id) => {
    if (navs[id]["isOpen"] === true) {
      navs[id]["isOpen"] = false;
      navs[id]["isActive"] = false;
      navs[id]["arrowDown"] = false;
      setNavs([...navs]);
      setAnimation(true);
    } else {
      navs[id]["isOpen"] = true;
      navs[id]["isActive"] = true;
      navs[id]["arrowDown"] = true;
      setNavs([...navs]);
      setAnimation(true);
    }
  };

  return (
    <div className="sidebar__container">
      <div className="sidebar__title">
        <img src={Icon} />
        <div className="hamburger__menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="sidebar__navigations">
        <ul className="main__nav">
          {navs.map((value, index) => {
            return (
              <>
                {(authUser.type === "RA" ||
                  authUser.type === "user" ||
                  authUser.type === "RG") &&
                (authUser.type === "RA"
                  ? value.title !== "Manage Globals" &&
                    value.title !== "Reports"
                  : false || authUser.type === "user"
                  ? value.title !== "Admin" &&
                    value.title !== "Manage Globals" &&
                    value.title !== "Reports"
                  : false) ? (
                  <>
                    <li key={value.title}>
                      <ul
                        className={
                          "main_menu " + (value.isActive ? "active" : "")
                        }
                        id="dashboard"
                        onAnimationEnd={() => setAnimation(false)}
                        onClick={() => toggleMenu(index)}
                      >
                        <li>
                          <i
                            className={value.icon}
                            style={{
                              // color: "#5e72e4",
                              paddingRight: "20px",
                              fontSize: "20px",
                            }}
                          ></i>{" "}
                          <Link
                            className="main__link"
                            to={value.route ? value.route : "#"}
                          >
                            {value.title}
                          </Link>
                        </li>
                        {value.subMenu ? (
                          <>
                            <li className="icon">
                              {value.arrowDown ? (
                                <>
                                  <i
                                    className="fas fa-chevron-down"
                                    style={value.style}
                                  ></i>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="fas fa-chevron-right"
                                    style={value.style}
                                  ></i>
                                </>
                              )}
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="icon">
                              {/* <i className="fas fa-chevron-down" style={value.style}></i> */}
                            </li>
                          </>
                        )}
                      </ul>
                      <ul
                        className={
                          "sub__menu " + (value.isOpen ? "open" : "close")
                        }
                      >
                        {value.subMenu ? (
                          <>
                            {value.subMenu.map((value) => {
                              return (
                                <>
                                  {value.title !== "Manage Users" &&
                                  value.title !== "Manage Subscription Plans" &&
                                  value.title !== "Manage Plans" &&
                                  value.title !== "Manage Security Questions" &&
                                   value.title !== "New User"  ? (
                                    <>
                                      <li>
                                        <Link to={value.route} className="link">
                                          {value.title}
                                        </Link>
                                      </li>
                                      {value.subMenu ? (
                                        <>
                                          <ul>
                                            {value.subMenu.map((value) => {
                                              return (
                                                <li>
                                                  <Link
                                                    to={value.route}
                                                    className="link"
                                                  >
                                                    {value.title}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </>
                        ) : null}
                      </ul>
                    </li>
                  </>
                ) : null}

                {authUser.type === "RG" ? (
                  <>
                    <li key={index}>
                      <ul
                        className={
                          "main_menu " + (value.isActive ? "active" : "")
                        }
                        id="dashboard"
                        onAnimationEnd={() => setAnimation(false)}
                        onClick={() => toggleMenu(index)}
                      >
                        <li>
                          <i
                            className={value.icon}
                            style={{
                              // color: "#5e72e4",
                              paddingRight: "20px",
                              fontSize: "20px",
                            }}
                          ></i>{" "}
                          <Link
                            className="main__link"
                            to={value.route ? value.route : "#"}
                          >
                            {value.title}
                          </Link>
                        </li>
                        {value.subMenu ? (
                          <>
                            <li className="icon">
                              {value.arrowDown ? (
                                <>
                                  <i
                                    className="fas fa-chevron-down"
                                    style={value.style}
                                  ></i>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="fas fa-chevron-right"
                                    style={value.style}
                                  ></i>
                                </>
                              )}
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="icon">
                              {/* <i className="fas fa-chevron-down" style={value.style}></i> */}
                            </li>
                          </>
                        )}
                      </ul>
                      <ul
                        className={
                          "sub__menu " + (value.isOpen ? "open" : "close")
                        }
                      >
                        {value.subMenu ? (
                          <>
                            {value.subMenu.map((value) => {
                              return (
                                <>
                                  {value.title !== "Manage Users" &&
                                  value.title !== "Manage Subscription Plans" &&
                                  value.title !== "Manage Plans" &&
                                  value.title !== "Manage Security Questions"  &&
                                  value.title !== "Packages" && value.title !== "New User"  ? (
                                    <>
                                      <li>
                                        <Link to={value.route} className="link">
                                          {value.title}
                                        </Link>
                                      </li>
                                      {value.subMenu ? (
                                        <>
                                          <ul>
                                            {value.subMenu.map((value) => {
                                              return (
                                                <li>
                                                  <Link
                                                    to={value.route}
                                                    className="link"
                                                  >
                                                    {value.title}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </>
                        ) : null}
                      </ul>
                    </li>
                  </>
                ) : null}

                {authUser.type === "SA" &&
                (value.title === "Subscription" ||
                  value.title === "Admin" ||
                  value.title === "Dashboard") ? (
                  <>
                    <li key={index}>
                      <ul
                        className={
                          "main_menu " + (value.isActive ? "active" : "")
                        }
                        id="dashboard"
                        onAnimationEnd={() => setAnimation(false)}
                        onClick={() => toggleMenu(index)}
                      >
                        <li>
                          <i
                            className={value.icon}
                            style={{
                              // color: "#5e72e4",
                              paddingRight: "20px",
                              fontSize: "20px",
                            }}
                          ></i>{" "}
                          <Link
                            className="main__link"
                            to={value.route ? value.route : "#"}
                          >
                            {value.title}
                          </Link>
                        </li>
                        {value.subMenu ? (
                          <>
                            <li className="icon">
                              {value.arrowDown ? (
                                <>
                                  <i
                                    className="fas fa-chevron-down"
                                    style={value.style}
                                  ></i>
                                </>
                              ) : (
                                <>
                                  <i
                                    className="fas fa-chevron-right"
                                    style={value.style}
                                  ></i>
                                </>
                              )}
                            </li>
                          </>
                        ) : (
                          <>
                            <li className="icon">
                              {/* <i className="fas fa-chevron-down" style={value.style}></i> */}
                            </li>
                          </>
                        )}
                      </ul>
                      <ul
                        className={
                          "sub__menu " + (value.isOpen ? "open" : "close")
                        }
                      >
                        {value.subMenu ? (
                          <>
                            {value.subMenu.map((value) => {
                              return (
                                <>
                                  {value.title == "Manage Users" ||
                                  value.title === "Manage Subscription Plans" ||
                                  value.title === "Manage Plans" ||
                                  value.title === "Manage Security Questions" ||
                                  value.title === "Packages" ? (
                                    <>
                                      <li>
                                        <Link to={value.route} className="link">
                                          {value.title}
                                        </Link>
                                      </li>
                                      {value.subMenu ? (
                                        <>
                                          <ul>
                                            {value.subMenu.map((value) => {
                                              return (
                                                <li>
                                                  <Link
                                                    to={value.route}
                                                    className="link"
                                                  >
                                                    {value.title}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </>
                              );
                            })}
                          </>
                        ) : null}
                      </ul>
                    </li>
                  </>
                ) : null}
              </>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import qs from "query-string";
import Swal from "sweetalert2";
import { Link, withRouter } from "react-router-dom";

import LoadingScreen from "../../inc/LoadingScreen";

import { Auth, AuthContext } from "../../../App";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const LeaseComponent = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);

  const authUser = useContext(AuthContext);

  function convertToDDMMYYYY(dateString) {
    const date = moment(dateString, [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      // Add more formats as needed
    ]);

    if (date.isValid()) {
      return date.format("DD-MM-YYYY");
    } else {
      return "Invalid Date";
    }
  }

  const getRejectionReson = async (bookID) => {
    setLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/reject/reason?book_id=" +
          bookID
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/reject/reason?book_id=" +
          bookID;

    let response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setLoading(false);
      Swal.fire({
        title: data.data,
        confirmButtonColor: "#17a2b8",
      });
    }
  };
  const deleteDeed = async (bookID) => {
    if (loading) {
      return;
    }
    setLoading(true);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      cancelButtonColor: "#f5365c",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          let url =
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_DEVELOPMENT_API_URL
              : process.env.REACT_APP_PRODUCTION_API_URL;

          let response = await fetch(
            url + "/api/v1/deed/delete?book_id=" + bookID,
            {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          let data = await response.json();

          if (data.success) {
            setLoading(false);
            await Swal.fire({
              icon: "success",
              title: "Deleted!",
              confirmButtonColor: "#17a2b8",
            });
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "Operation was unsuccessful",
          confirmButtonColor: "#17a2b8",
        });
      });

    setLoading(false);
  };

  const approve = async (bookID, type, userType) => {
    if (loading) {
      return;
    }
    setLoading(true);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#66cd9a",
      cancelButtonColor: "#f5365c",
      confirmButtonText: "Approve",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingInfo(true);

        let url =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT_API_URL +
              "/api/v1/approve?book_id=" +
              bookID +
              "&type=" +
              type +
              "&userType=" +
              userType
            : process.env.REACT_APP_PRODUCTION_API_URL +
              "/api/v1/approve?book_id=" +
              bookID +
              "&type=" +
              type +
              "&userType=" +
              userType;
        let response = await fetch(url, {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        let data = await response.json();

        if (data.success) {
          setLoadingInfo(false);

          await Swal.fire({
            icon: "success",
            title: "Approved!",
            confirmButtonColor: "#17a2b8",
          });
          props.history.push("/");
          props.history.push("/lease/" + props.leaseType);
        } else {
          setLoadingInfo(false);

          await Swal.fire({
            icon: "error",
            title: "Failed!",
            text: "Operation was unsuccessful",
            confirmButtonColor: "#17a2b8",
          });
        }
      }
    });

    setLoading(false);
  };

  const reject = async (bookID, type) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Rejection Message",
      inputPlaceholder: "Reason for rejection..",
      inputAttributes: {
        "aria-label": "Reason of reject",
      },
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: "Submit",
    });

    if (text) {
      setLoadingInfo(true);

      let url =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_API_URL + "/api/v1/reject"
          : process.env.REACT_APP_PRODUCTION_API_URL + "/api/v1/reject";
      let param = { bookID: bookID, type: type, reason: text };
      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(param),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      let data = await response.json();

      if (data.success) {
        setLoadingInfo(false);

        await Swal.fire({
          title: "Rejected",
          text: "",
          confirmButtonColor: "#17a2b8",
        });
        props.history.push("/");
        props.history.push("/lease/" + props.leaseType);
      } else {
        setLoadingInfo(false);

        await Swal.fire({
          icon: "error",
          title: "Error!",
          text: "",
          confirmButtonColor: "#17a2b8",
        });
      }
    } else {
      await Swal.fire({
        icon: "error",
        title: "Empty! Rejection",
        text: "",
        confirmButtonColor: "#17a2b8",
      });
    }

    setLoading(false);
  };

  if (props.pending) {
    return <LoadingSpinner />;
  }

  return (
    <div className="table__container" style={{ overflowX: "auto" }}>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>SR. Number</th>
            <th>Lessor</th>
            <th>Lesse</th>
            <th>Date of Execution</th>
            <th>Date of Receipt</th>
            <th>Location of Land</th>
            <th>Party Registering</th>
            <th>Recipient</th>
            <th>OR Number</th>
            <th>Document</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props.genericLeases.length >= 1 ? (
            props.genericLeases.map((value) => {
              return (
                <tr key={value.id}>
                  <td>{value.serial_number}</td>
                  <td>
                    {value.lessor[0]
                      ? value.lessor[0].first_name +
                        " " +
                        value.lessor[0].last_name +
                        " " +
                        value.lessor[0].nin
                      : null}
                  </td>
                  <td>
                    {value.lessee[0]
                      ? value.lessee[0].first_name +
                        " " +
                        value.lessee[0].last_name
                      : null}
                  </td>
                  <td>{convertToDDMMYYYY(value.execution_date)}</td>
                  <td>{convertToDDMMYYYY(value.recipient_date)}</td>
                  <td>{value.land_situation}</td>
                  <td>
                    {value.party[0]
                      ? value.party[0].first_name +
                        " " +
                        value.party[0].last_name
                      : null}
                  </td>
                  <td>
                    {value.recipient[0]
                      ? value.recipient[0].first_name +
                        " " +
                        value.recipient[0].last_name +
                        " " +
                        value.recipient[0].nin
                      : null}
                  </td>

                  <td>{value.or_number}</td>

                  {/* document, status, action */}
                  {authUser.type === "RA" || authUser.type === "user" ? (
                    <>
                      <td>
                        <ul>
                          {authUser.type === "RA" && (
                            <li>
                              <Link
                                to={
                                  "/add/document/" +
                                  value.registration_type +
                                  "/" +
                                  value.id
                                }
                                className="add"
                              >
                                Add
                              </Link>
                            </li>
                          )}
                          {/* {authUser.type === "RA" ? (
                            <>
                              {value.status === "Pending RA" ||
                              value.status === "Approved User" ||
                              value.status === "Rejected RA" ||
                              value.status === "Rejected RG" ? (
                                <>
                                  <li>
                                    <Link
                                      to={
                                        "/add/document/" +
                                        value.registration_type +
                                        "/" +
                                        value.id
                                      }
                                      className="add"
                                    >
                                      Add
                                    </Link>
                                  </li>
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {(value.status === "Pending User" ||
                                value.status === "Rejected RA" ||
                                value.status === "Rejected RG") &&
                              authUser.type === "user" ? (
                                <>
                                  <li>
                                    <Link
                                      to={
                                        "/add/document/" +
                                        value.registration_type +
                                        "/" +
                                        value.id
                                      }
                                      className="add"
                                    >
                                      Add
                                    </Link>
                                  </li>
                                </>
                              ) : null}
                            </>
                          )} */}

                          <li>
                            <Link
                              to={"/document/view/" + value.id}
                              className="view"
                            >
                              View
                            </Link>
                          </li>
                        </ul>
                      </td>
                      <td>
                        {value.status === "Approved RA" &&
                        authUser.type !== "RG" ? (
                          <>
                            <button className="approved__btn_RA">
                              Approved By RA
                            </button>
                          </>
                        ) : null}
                        {value.status === "Approved RG" ? (
                          <>
                            <button className="approved__btn">
                              Approved By RG
                            </button>
                          </>
                        ) : null}
                        {value.status === "Approved User" &&
                        authUser.type === "user" ? (
                          <>
                            <button className="approved__btn_user">
                              Approved By User
                            </button>
                          </>
                        ) : null}
                        {value.status === "Rejected User" ||
                        value.status === "Rejected RA" ||
                        value.status === "Rejected RG" ||
                        value.status === "Rejected" ? (
                          <>
                            <button className="rejected__btn">
                              {value.status == "Rejected User" ? (
                                <>Rejected by User </>
                              ) : value.status == "Rejected RA" ? (
                                <>Rejected by RA </>
                              ) : value.status == "Rejected RG" ? (
                                <>Rejected by RG </>
                              ) : (
                                <>Rejected</>
                              )}
                            </button>
                            <button
                              className="info__btn"
                              onClick={() => getRejectionReson(value.id)}
                            >
                              {loadingInfo ? (
                                <i className="fas fa-spinner fa-spin"></i>
                              ) : (
                                "info"
                              )}
                            </button>
                          </>
                        ) : null}
                        {value.status === "Rejected" &&
                        value.status === "Approved RA" &&
                        authUser.type === "RA" ? (
                          <>
                            <ul>
                              <li>
                                <Link
                                  to={
                                    "/lease/edit/" +
                                    props.leaseType +
                                    "/" +
                                    value.id
                                  }
                                  className="view"
                                >
                                  Edit
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={
                                    "/deed/" +
                                    props.leaseType +
                                    "/view/" +
                                    value.id
                                  }
                                  className="view"
                                >
                                  View
                                </Link>
                              </li>
                              <li>
                                <button
                                  className="delete__btn"
                                  onClick={() => deleteDeed(value.id)}
                                >
                                  {loading ? (
                                    <i className="fas fa-spinner fa-spin"></i>
                                  ) : (
                                    "Delete"
                                  )}
                                </button>
                              </li>
                            </ul>
                          </>
                        ) : null}
                        {}
                        {value.status === null ||
                        (value.status === "Approved User" &&
                          authUser.type === "RA") ||
                        (value.status === "Pending User" &&
                          authUser.type === "user") ||
                        (value.status === "Pending RA" &&
                          authUser.type === "RA") ? (
                          <>
                            <button
                              className="btn__approve"
                              onClick={() =>
                                approve(
                                  value.id,
                                  value.registration_type,
                                  authUser.type
                                )
                              }
                            >
                              Approve
                            </button>
                            <button
                              className="btn__reject"
                              onClick={() =>
                                reject(
                                  value.id,
                                  value.registration_type,
                                  authUser.type
                                )
                              }
                            >
                              Reject
                            </button>
                          </>
                        ) : null}
                      </td>
                      <td>
                        <ul>
                          {value.status !== "Pending User" &&
                            (authUser.type === "RA" ||
                              authUser.type === "RG") &&
                            value.status !== "Approved RA" &&
                            authUser.type === "RG"}
                          <li>
                            <Link
                              to={
                                "/deed/" + props.leaseType + "/view/" + value.id
                              }
                              className="view"
                            >
                              View
                            </Link>
                          </li>
                        </ul>
                        {value.status === "Rejected User" ||
                        value.status === null ||
                        (value.status === "Pending User" &&
                          authUser.type === "user") ||
                        (value.status === "Pending RA" &&
                          authUser.type === "RA") ||
                        value.status === "Rejected RA" ||
                        value.status === "Rejected RG" ||
                        value.status === "Rejected" || value.status === 'Approved RA' ? (
                          <ul>
                            <li>
                              <Link
                                to={
                                  "/lease/edit/" +
                                  props.leaseType +
                                  "/" +
                                  value.id
                                }
                                className="view"
                              >
                                Edit
                              </Link>
                            </li>
                            <li>
                              <button
                                className="delete__btn"
                                onClick={() => deleteDeed(value.id)}
                              >
                                {loading ? (
                                  <i className="fas fa-spinner fa-spin"></i>
                                ) : (
                                  "Delete"
                                )}
                              </button>
                            </li>
                          </ul>
                        ) : null}
                      </td>
                    </>
                  ) : authUser.type === "RG" ? (
                    <>
                      <td>
                        <ul>
                          {/* {value.status != "Rejected RG" ? (
                            <li>
                              <Link
                                to={
                                  "/add/document/" +
                                  value.registration_type +
                                  "/" +
                                  value.id
                                }
                                className="add"
                              >
                                Add
                              </Link>
                            </li>
                          ) : null} */}

                          <li>
                            <Link
                              to={"/document/view/" + value.id}
                              className="view"
                            >
                              View
                            </Link>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          {value.status === "Approved RA" ? (
                            <>
                              <button
                                className="btn__approve"
                                onClick={() =>
                                  approve(
                                    value.id,
                                    value.registration_type,
                                    authUser.type
                                  )
                                }
                              >
                                Approve
                              </button>
                            </>
                          ) : value.status === "Approved RG" ? (
                            <>
                              <li>
                                <button className="approved__btn">
                                  Approved By RG
                                </button>
                              </li>
                            </>
                          ) : value.status === "Rejected RG" ? (
                            <>
                              <li>
                                <button
                                  style={{
                                    color: "#fff",
                                    width: "100%",
                                    marginBottom: "5px",
                                    border: "none",
                                    backgroundColor: "red",
                                    padding: "5px",
                                    borderRadius: "5px",
                                  }}
                                  className="approved__btn"
                                >
                                  Rejected by RG
                                </button>
                              </li>
                            </>
                          ) : value.status === "Rejected" ? (
                            <>
                              <button
                                className="btn__reject"
                                style={{ background: "red", color: "#fff" }}
                              >
                                rejected
                              </button>
                            </>
                          ) : (
                            <>
                              <button className="btn__approve" disabled={true}>
                                Pending
                              </button>
                              <button
                                className="btn__reject"
                                onClick={() =>
                                  reject(
                                    value.id,
                                    value.registration_type,
                                    authUser.type
                                  )
                                }
                              >
                                Reject
                              </button>
                            </>
                          )}

                          {value.status == "Approved RA" ||
                          value.status == "Approved RG" ? (
                            <li>
                              <button
                                className="btn__reject"
                                onClick={() =>
                                  reject(
                                    value.id,
                                    value.registration_type,
                                    authUser.type
                                  )
                                }
                              >
                                Reject
                              </button>
                            </li>
                          ) : null}
                        </ul>
                      </td>

                      <td>
                        <ul>
                          <li>
                            <Link
                              to={
                                "/deed/" + props.leaseType + "/view/" + value.id
                              }
                              className="view"
                            >
                              View
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </>
                  ) : null}
                </tr>
              );
            })
          ) : props.noResultFound ? (
            <tr>
              <td
                colSpan="20"
                style={{
                  textAlign: "center",
                  background: "white",
                  fontWeight: "bold",
                }}
              >
                No records found
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>

      <LoadingScreen show={loadingInfo} />
    </div>
  );
};

export default LeaseComponent;

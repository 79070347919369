import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../../inc/Sidebar";
import TitleHeader from "../../inc/TitleHeader";
import qs from "query-string";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { AuthContext } from "../../../App";
import DeedComponent from "./DeedComponent";

const ProvincialDeed = (props) => {
  let page_no = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .page
    ? qs.parse(props.location.search, { ignoreQueryPrefix: true }).page
    : 1;

  const [page, setPage] = useState({ page: page_no });
  const [pagination, setPagination] = useState({
    current_page: "",
    last_page: "",
  });
  const [provincialDeed, setProvincialDeed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [noResultFound, setNoResultFound] = useState(false);

  const getProvincialDeeds = async () => {
    setLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL
        : process.env.REACT_APP_PRODUCTION_API_URL;

    let response = await fetch(
      url + "/api/v1/provincial-deeds?page=" + page.page,
      { credentials: "include" }
    );
    let data = await response.json();

    if (data) {
      if (data.success) {
        setLoading(false);
        setProvincialDeed([...data.data.data]);
        setPagination({
          current_page: data.data.current_page,
          last_page: data.data.last_page,
        });
        if (data.data.data.length != 0) setNoResultFound(false);
        else setNoResultFound(true);
      }
    }
  };



  const approve = async (bookID, type, userType) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#66cd9a",
      cancelButtonColor: "#f5365c",
      confirmButtonText: "Approve",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let url =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT_API_URL +
              "/api/v1/approve?book_id=" +
              bookID +
              "&type=" +
              type +
              "&userType=" +
              userType
            : process.env.REACT_APP_PRODUCTION_API_URL +
              "/api/v1/approve?book_id=" +
              bookID +
              "&type=" +
              type +
              "&userType=" +
              userType;
        let response = await fetch(url, {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        let data = await response.json();

        if (data.success) {
          await Swal.fire({
            icon: "success",
            title: "Approved!",
            confirmButtonColor: "#17a2b8",
          });
          props.history.push("/");
          props.history.push("/deed/provincial-deed");
        } else {
          await Swal.fire({
            icon: "error",
            title: "Failed!",
            text: "Operation was unsuccessful",
            confirmButtonColor: "#17a2b8",
          });
        }
      }
    });
  };

  const reject = async (bookID, type) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Rejection Message",
      inputPlaceholder: "Reason for rejection..",
      inputAttributes: {
        "aria-label": "Reason of reject",
      },
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: "Submit",
    });


    if (text) {
      let url =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_DEVELOPMENT_API_URL + "/api/v1/reject"
          : process.env.REACT_APP_PRODUCTION_API_URL + "/api/v1/reject";
      let param = { bookID: bookID, type: type, reason: text };
      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(param),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });
      let data = await response.json();

      if (data.success) {
        await Swal.fire({
          title: "Rejected",
          text: "",
          confirmButtonColor: "#17a2b8",
        });
        window.location.href =
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_DEVELOPMENT + "/deed/provincial-deed"
            : process.env.REACT_APP_PRODUCTION + "/deed/provincial-deed";
      } else {
        await Swal.fire({
          icon: "error",
          title: "Error!",
          text: "",
          confirmButtonColor: "#17a2b8",
        });
      }
    } else {
      await Swal.fire({
        icon: "error",
        title: "Empty! Rejection",
        text: "",
        confirmButtonColor: "#17a2b8",
      });
    }
  };

  const getRejectionReson = async (bookID) => {
    setLoadingInfo(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/reject/reason?book_id=" +
          bookID
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/reject/reason?book_id=" +
          bookID;

    let response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setLoadingInfo(false);
      Swal.fire({
        title: data.data,
        confirmButtonColor: "#17a2b8",
      });
    }
  };




  const searchProvincialDeed = async (event) => {
    event.preventDefault();
    setSearchLoading(true);
    let url =
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEVELOPMENT_API_URL +
          "/api/v1/search?search_param=" +
          search +
          "&cat=PD"
        : process.env.REACT_APP_PRODUCTION_API_URL +
          "/api/v1/search?search_param=" +
          search +
          "&cat=PD";

    let response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    let data = await response.json();

    if (data.success) {
      setProvincialDeed([...data.data.data]);
      setSearchLoading(false);
      // setPagination({current_page: "", last_page: ""});
      setPagination({
        current_page: data.data.current_page,
        last_page: data.data.last_page,
      });
    } else {
      // shortFunds(data.message.message);
    }
    setSearchLoading(false);
  };

  const deleteDeed = async (bookID) => {
    setLoading(true);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#17a2b8",
      cancelButtonColor: "#f5365c",
      confirmButtonText: "Yes, delete it!",
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          let url =
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_DEVELOPMENT_API_URL
              : process.env.REACT_APP_PRODUCTION_API_URL;

          let response = await fetch(
            url + "/api/v1/deed/delete?book_id=" + bookID,
            {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
              credentials: "include",
            }
          );
          let data = await response.json();

          if (data.success) {
            setLoading(false);
            await Swal.fire({
              icon: "success",
              title: "Deleted!",
              confirmButtonColor: "#17a2b8",
            });
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "Operation was unsuccessful",
          confirmButtonColor: "#17a2b8",
        });
      });

    setLoading(false);
  };
  
  const authUser = useContext(AuthContext);

  const next = () => {
    setLoading(true);
    let currentPage = Number(page.page) + 1;
    setPage({ page: currentPage });
    setLoading(false);
    window.scrollTo(0, 0);
    props.history.push("/deed/provincial-deed?page=" + currentPage);
  };

  const previous = () => {
    setLoading(true);
    let currentPage = Number(page.page) - 1;
    if (currentPage === 0) {
      setPage({ page: 1 });
      setLoading(false);
      props.history.push("/deed/provincial-deed?page=" + currentPage);
    } else {
      setPage({ page: currentPage });
      setLoading(false);
      props.history.push("/deed/provincial-deed?page=" + currentPage);
    }
    window.scrollTo(0, 0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };
  const shortFunds = (msg) => {
    Swal.fire({
      title: "Insufficent funds",
      text: msg,
      icon: "error",
      showCancelButton: false,
      confirmButtonColor: "#17a2b8",
      confirmButtonText: "OK",
    });
  };

  useEffect(() => {
    getProvincialDeeds();
  }, [page]);
  return (
    <div className="dashboard__container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Provincial Deed | Dashboard</title>
      </Helmet>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="dashboard__content">
        <TitleHeader />
        <div className="breadcrumb__title">Provincial Deed Registrations</div>
        <div className="add-search">
          <div className="add">
            {authUser.type === "RA" || authUser.type === "user" ? (
              <Link to="/deed/provincial-deed/add-deed">
                Add New <i className="fas fa-plus"></i>
              </Link>
            ) : null}
          </div>
          {authUser.type === "RA" || authUser.type === "RG" ? (
            <>
              <div className="search">
                <form onSubmit={searchProvincialDeed}>
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <button
                    type="submit"
                    className="button"
                    disabled={search.length > 3 ? false : true}
                  >
                    {searchLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : null}
          {authUser.type === "user" ? (
            <>
              <div className="search">
                <form onSubmit={searchProvincialDeed}>
                  <input
                    type="text"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                  <button
                    type="submit"
                    disabled={search.length > 3 ? false : true}
                  >
                    {searchLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : null}
        </div>
        <DeedComponent 
          genericDeeds={provincialDeed} noResultFound={noResultFound}
          history={props.history} 
          deedType={'provincial-deed'}
          pending={loading}
          />
        {pagination.last_page >= 2 ? (
          <>
            <div className="pagination">
              <button
                className="pagination__btn"
                disabled={
                  pagination.lastPage === 1 || Number(page_no) === 1
                    ? true
                    : false
                }
                onClick={previous}
              >
                Previous
              </button>
              <button
                className="pagination__btn"
                disabled={
                  Number(page.page_no) === pagination.lastPage ? true : false
                }
                onClick={next}
              >
                {loading ? <i className="fas fa-spinner-3x"></i> : "Next"}
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ProvincialDeed;

import React, { useState, useEffect } from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const EditUser = (props) => {

    // style
    const errorStyle = {
        color: 'red',
        fontSize: '13px !important',
        padding: '0.5rem 0',
        fontWeight: 'bold'
        
    }

    const { id } = useParams();



    const [user, setUser] = useState({
        "id":"",
        "first_name": "",
        "middle_name": "",
        "last_name": "",
        "email": "",
        "password": "",
        "confirm_password": "",
        "picture":"",
        "nin":"",
        "telephone1":"",
        "telephone2": "",

    });

    const [loading, setLoading] = useState(false);

    const [updated, setUpdated] = useState(false);

    const [formErrors, setFormErrors] = useState({
            first_name: "",
            middle_name: "",
            last_name: "",
            email: "",
            password: "",
            picture:"",
            nin:"",
            telephone1:"",
            telephone2: "",
    });



    const getUser = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/get-user?id='+ id, {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setUser({...data.data[0]});
        } 
    }


    const submit = async (event) => {
        event.preventDefault();
        if(loading){
            return;
          }
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        setLoading(true);

        // Form errors
        let isExist = handleFormErrors();
        console.log(formErrors)
        if (isExist === true){

            // console.log(isExist, 'if')
            setLoading(false)
            return
        }
        else
        {
            console.log(isExist, 'else')

            let response = await fetch(url + '/api/v1/update-profile', {credentials: 'include',method: 'PUT', 
            body: JSON.stringify(user), headers: {'Content-Type': 'application/json'}});
            let data = await response.json();
    
            if(data) {
                if(data.success) {
                    setLoading(false);
                    setUpdated(true);
                } else {
                    setLoading(false);
                    setUpdated(false);
                }
                setLoading(false);
                window.scrollTo(0,0);
            }
        }

        
    }

    const handleChange = (event) => {

        setUser(prevState => ({
            ...prevState,
            [event.target.id] : event.target.value
        }));
        


    }

    const handleFormErrors = ()=>{
        let isExist = false;
        
        setFormErrors({
            ...formErrors,
            first_name: "",
            middle_name: "",
            last_name: "",
            email: "",
            password: "",
            picture:"",
            nin:"",
            telephone1:"",
            telephone2: "",
        
        })

        if(user.password !== user.confirm_password){

            setFormErrors(prevState => ({
                ...prevState,
                password: 'Passwords dont match',
                
            }));
            isExist = true;
        }

        return isExist;
    }

    useEffect(() => {
        getUser()
    }, [])


    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update User</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>
            <div className="dashboard__content">
                <TitleHeader />
                <div className="registration__page">
                  <h3>Update User</h3>
                  <span className="underline"></span>
                  <div className="registration__form__container">

                   {updated? (
                       <>
                        <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                            margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                <h6>User Updated</h6>
                            </div>
                       </>
                   ): null}
                      <form className="form__add_plan" onSubmit={ submit }>
                           <div className="one__column">
                               <input type="text" placeholder="First Name" value={user.first_name} onChange={handleChange} id="first_name"/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Middle Name" value={user.middle_name} onChange={handleChange} id="middle_name" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Last Name" value={user.last_name} onChange={handleChange} id="last_name"/>
                           </div>
                           <div className="one__column">

                              {
                                  formErrors.password != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.password}</span>
                                         </div>
                                    </>
                                ): null
                              }

                               <input type="password" placeholder="Password" onChange={handleChange} id="password"/>
                           </div>
                           <div className="one__column">
                               <input type="password" placeholder="Confirm Password" onChange={handleChange} id="confirm_password"/>
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="NIN" value={user.nin} value={user.nin} onChange={handleChange} id="nin" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Telephone 1" value={user.telephone1} onChange={handleChange} id="telephone1" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Telephone 2" value={user.telephone2} onChange={handleChange} id="telephone2" />
                           </div>
                           <div className="one__column">
                               <input type="text" placeholder="Email" value={user.email} disabled onChange={handleChange} id="email" />
                           </div>
                           <div className="one__column">
                               <div className="group">
                                   <label>Image</label>
                                   <input type="file" />
                               </div>
                           </div>
                          <input type="submit" value={loading? 'Loading...' : "Update"} />
                      </form>
                  </div>
              </div>         
            </div>

        </div>
    )
}

export default EditUser

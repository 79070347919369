import React, { useState, useEffect } from 'react';
import Sidebar from '../../../inc/Sidebar';
import TitleHeader from '../../../inc/TitleHeader';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const EditPlan = () => {
    // style
    const errorStyle = {
        color: 'red',
        fontSize: '13px !important',
        padding: '0.5rem 0',
        fontWeight: 'bold'
        
    }

    const { id } = useParams();

    const [plan, setPlan] = useState({
        "id":"",
        "name": "",
        "description": "",
    });

    const [loading, setLoading] = useState(false);

    const [updated, setUpdated] = useState(false);

    const [formErrors, setFormErrors] = useState({
        name: "",
        description: "",
    
    });

    const getPlan = async () => {
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        let response = await fetch(url + '/api/v1/get-plan?id='+ id, {credentials: 'include'});
        let data = await response.json();

        if(data.success) {
            setPlan({...data.data[0]});
        } 
    }
    const submit = async (event) => {
        event.preventDefault();
        if(loading){
            return;
          }
        let url = process.env.NODE_ENV === "development"?
        process.env.REACT_APP_DEVELOPMENT_API_URL : process.env.REACT_APP_PRODUCTION_API_URL;

        setLoading(true);

        // Form errors
        let isExist = handleFormErrors();

        if (isExist === true){
            setLoading(false)
            return
        }
        else
        {
            let response = await fetch(url + '/api/v1/update-plan', {credentials: 'include',method: 'PUT', 
            body: JSON.stringify(plan), headers: {'Content-Type': 'application/json'}});
            let data = await response.json();
    
            if(data) {
                if(data.success) {
                    setLoading(false);
                    setUpdated(true);
                } else {
                    setLoading(false);
                    setUpdated(false);
                }
                setLoading(false);
                window.scrollTo(0,0);
            }
        }

        
    }

    const handleChange = (event) => {

        setPlan(prevState => ({
            ...prevState,
            [event.target.id] : event.target.value
        }));

    }

    const handleFormErrors = ()=>{
        let isExist = false;
        
        setFormErrors({
            ...formErrors,
            name: "",
            description: "",

            
        })

        if(plan.name.length <= 1 || plan.name.length >= 25){

            setFormErrors(prevState => ({
                ...prevState,
                name: 'Subscription name must be between 1 and 25 characters',
                
            }));
            isExist = true;
        }
        if(plan.description.length == 0){

            setFormErrors(prevState => ({
                ...prevState,
                description: 'Description must not be blank',
                

            }));
            isExist = true;

        }

        return isExist;
    }


    useEffect(() => {
        getPlan()    
    }, [])

    return (
        <div className="dashboard__container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Plan</title>
            </Helmet>
            <div className="sidebar">
                <Sidebar />
            </div>  

            <div className="dashboard__content">
                <TitleHeader />
                <div className="registration__page">
                    <h3>Edit Plan</h3>
                    <span className="underline"></span>
                    <div className="registration__form__container">

                        {updated? (
                            <>
                                <div style={{backgroundColor: '#17a2b8', height: '40px', width: '60%', 
                                    margin: 'auto', padding: '0px 8px', borderRadius: '5px'}}>
                                        <h6>Subscription Updated</h6>
                                    </div>
                            </>
                        ): null}

                        <form className="form__add_plan" onSubmit={ submit }>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.name != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.name}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Name</label>
                              <input type="text" placeholder="Name" name='name' value={plan.name} id="name" onChange={handleChange} />
                          </div>
                          <div className="one__column" style={{padding:"10px 0"}}>
                              {
                                  formErrors.description != '' ? (
                                    <>
                                     <div style={errorStyle}>
                                             <span>{formErrors.description}</span>
                                         </div>
                                    </>
                                ): null
                              }
                              <label >Description</label>
                              <textarea placeholder="Description" id="description" value={plan.description} onChange={handleChange}></textarea>
                          </div>

                          <input type="submit" value={loading? 'Loading...' : "Update"} />

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPlan

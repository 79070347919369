import React, { useState, useEffect, createContext } from 'react';
import './assets/css/sass/style.css';
import './assets/css/print.css';
import './assets/css/sass/style2.css';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

/** components */
import Dashboard from './components/dashboard/Dashboard';
import KomboLease from './components/registrations/lease/KomboLease';
import AddKomboLease from './components/registrations/lease/add/AddKomboLease';
import AddProvincialLease from './components/registrations/lease/add/AddProvincialLease';
import ProvincialLease from './components/registrations/lease/ProvincialLease';
import KomboDeed from './components/registrations/drb/KomboDeed';
import AddKomboDeed from './components/registrations/drb/add/AddKomboDeed';
import ProvincialDeed from './components/registrations/drb/ProvincialDeed';
import AddProvincialDeed from './components/registrations/drb/add/AddProvincialDeed';
import MiscellaneousDeed from './components/registrations/drb/MiscellaneousDeed';
import AddMiscellaneousDeed from './components/registrations/drb/add/AddMiscellaneousDeed';
import CityDeed from './components/registrations/drb/CityDeed';
import AddCityDeed from './components/registrations/drb/add/AddCityDeed';
import KomboMortgage from './components/registrations/drb/KomboMortgage';
import AddKomboMortgage from './components/registrations/drb/add/AddKomboMortgage';
import ProvincialMortgage from './components/registrations/drb/ProvincialMortgage';
import AddProvincialMortgage from './components/registrations/drb/add/AddProvincialMortgage';
import PowerOfAttorney from './components/registrations/drb/PowerOfAttorney';
import AddPowerOfAttorney from './components/registrations/drb/add/AddPowerOfAttorney';
import PendingLease from './components/registrations/drb/PendingLease';
import PendingDRB from './components/registrations/drb/PendingDRB';
import SearchLease from './components/registrations/search/SearchLease';
import SearchDRB from './components/registrations/search/SearchDRB';
import ManageSearchPlans from './components/registrations/searchplan/ManageSearchPlans';
import AddSearchPlan from './components/registrations/searchplan/AddSearchPlan';
import ManageSearchQuota from './components/registrations/quota/ManageSearchQuota';
import ManageRegistrationQuota from './components/registrations/quota/ManageRegistrationQuota';
import Logs from './components/registrations/logs/Logs';

import ManageUsers from './components/registrations/users/ManageUsers';
import NewUser from './components/registrations/users/NewUser';
import EditUser from './components/registrations/users/edit/EditUser';

import InstrumentType from './components/registrations/globals/InstrumentType';
import AddInstrumentType from './components/registrations/globals/add/AddInstrumentType';
import BusinessType from './components/registrations/globals/BusinessType';
import AddBusinessType from './components/registrations/globals/add/AddBusinessType';
import DRBRegistrationType from './components/registrations/globals/DRBRegistrationType';
import AddDRBRegistrationType from './components/registrations/globals/add/AddDRBRegistrationType';
import LeaseRegistrationType from './components/registrations/globals/LeaseRegistrationType';
import AddLeaseRegistrationType from './components/registrations/globals/add/AddLeaseRegistrationType';
import RegistraAdmin from './components/registrations/registra/RegistraAdmin';
import AddRegistraAdmin from './components/registrations/registra/add/AddRegistraAdmin';
import RegistraGeneral from './components/registrations/registra/RegistraGeneral';
import ViewKomboDeed from './components/registrations/drb/view/ViewKomboDeed';
import AddDocument from './components/registrations/drb/add/AddDocument';
import ViewDocument from './components/registrations/drb/view/ViewDocument';
import Login from './components/login/Login';
import LogOut from './components/logout/LogOut';
import ViewProvincialDeed from './components/registrations/drb/view/ViewProvincialDeed';
import ViewMiscellaneousDeed from './components/registrations/drb/view/ViewMiscellaneousDeed';
import ViewCityDeed from './components/registrations/drb/view/ViewCityDeed';
import ViewDRB from './components/registrations/drb/view/ViewDRB';
import ViewKomboMortgage from './components/registrations/drb/view/ViewKomboMortgage';
import ViewPowerOfAttorney from './components/registrations/drb/view/ViewPowerOfAttorney';
import ViewProvincialMortgage from './components/registrations/drb/view/ViewProvincialMortgage';
import ViewKomboLease from './components/registrations/lease/view/ViewKomboLease';
import ViewProvincialLease from './components/registrations/lease/view/ViewProvincialLease';
import EditDRB from './components/registrations/drb/edit/EditDRB';
import EditLease from './components/registrations/lease/edit/EditLease';
import ViewLease from './components/registrations/drb/view/ViewLease';
import EditRegistraAdmin from './components/registrations/registra/edit/EditRegistraAdmin';

import AddRegistraGeneral from './components/registrations/registra/add/AddRegistraGeneral';
import EditRegistraGeneral from './components/registrations/registra/edit/EditRegistraGeneral';


import EditInstrument from './components/registrations/globals/edit/EditInstrument';
import UserProfile from './components/profile/UserProfile';
import EditDRBRegistration from './components/registrations/globals/edit/EditDRBRegistration';
import EditLeaseRegistrationType from './components/registrations/globals/edit/EditLeaseRegistrationType';
import TransactionReport from './components/registrations/transactions/TransactionReport';
import RegisterUser from './components/user/RegisterUser';
import ManageSubscriptionPlan from './components/registrations/subscription/ManageSubscriptionPlan';
import AddSubscriptionPlan from './components/registrations/subscription/AddSubscriptionPlan';
import EditSubscriptionPlan from './components/registrations/subscription/edit/EditSubscriptionPlan'

import CreatePlan from './components/registrations/subscription/CreatePlan';
import ManagePlan from './components/registrations/subscription/ManagePlan';
import EditPlan from './components/registrations/subscription/edit/EditPlan';


import SubscriptionAdmin from './components/registrations/subscription/SubscriptionAdmin';
import AddSubscriptionAdmin from './components/registrations/subscription/add/AddSubscriptionAdmin';
import EditSubscriptionAdmin from './components/registrations/subscription/edit/EditSubscriptionAdmin'


import ManageSecurityQuestions from './components/registrations/security_questions/ManageSecurityQuestions';
import AddSecurityQuestion from './components/registrations/security_questions/AddSecurityQuestion';
import EditSecurityQuestion from './components/registrations/security_questions/EditSecurityQuestion';



import WorkerActivity from './components/subscription/reports/WorkerActivity';
import EditProvincialLease from './components/registrations/lease/edit/EditProvincialLease';
import Confirmation from './components/user/Confirmation';
import UserLogin from './components/login/UserLogin';
import Packages from './components/subscription/Packages';

export const AuthContext = createContext();

export const Auth = {
  isAuthenticated: localStorage.getItem('success')? localStorage.getItem('success') : false,
  signin(cb) {
    Auth.isAuthenticated = true;
    setTimeout(cb, 100);
    setTimeout(() => {
      window.location.reload(); 
    }, 100);
  },
  signout(cb) {
    Auth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}



function App() {
  const [isMounted, setIsMounted] = useState(false);
  const [onlyAllowRG, setOnlyAllowRG] = useState(false);
  const [onlyAllowRA,setOnlyAllowRA] = useState(false);
  const [onlyAllowUser, setOnlyAllowUser] = useState(false);
  const [onlyAllowSA, setOnlyAllowSA] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authUser, setAuthUser] = useState({
    type: '',
    success: false,
  });

  

  const checkIsAuthenticated = async () => {
    let url = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_API_URL : 
    process.env.REACT_APP_PRODUCTION_API_URL;
    await axios.get(url + '/api/v1/user', {withCredentials: true})
    .then(async (resp) => {
       if( await resp.data.success) {
          setAuthUser({
            type: resp.data.data.userType[0].name,
            success: true
          });
          localStorage.setItem('success', true);
          localStorage.setItem('type', resp.data.data.userType[0].name);
          Auth.isAuthenticated = true;
          setIsAuthenticated(true);
       }

       if(resp.data.success === false) {
        setAuthUser(authUser);
        setIsAuthenticated(false);
        localStorage.clear();
       }
       
    }).catch((err) => {
      setAuthUser(authUser);
      setIsAuthenticated(false);
      localStorage.clear();
    });
  }

  
  useEffect(() => {
    setIsMounted(true);
    if(!isMounted) {
      checkIsAuthenticated();
    }
    return () => {
      setIsMounted(false);
      if(authUser.type === "RG") {
        setOnlyAllowRG(true);
      }
      if(authUser.type === "RA") {
        setOnlyAllowRA(true);
      }

      if(authUser.type === "user") {
        setOnlyAllowUser(true);
      }
      if(authUser.type === "SA") {
        setOnlyAllowSA(true);
      }
    }
  }, [isAuthenticated,authUser]);
  return (
    <div className="App">
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/user-login" component={UserLogin} />
            <Route exact path="/user/register" component={RegisterUser} />
            <Route exact path="/user/confirmation" component={Confirmation} />
            <AuthContext.Provider value={authUser}>
            <PrivateRoute>
             <Route exact path="/" component={Dashboard} />
            <Route exact path="/lease/kombo-lease" component={KomboLease} />
            <Route exact path="/lease/kombo-lease/add-lease" component={onlyAllowRA || onlyAllowUser? AddKomboLease : Redirect} />
            <Route exact path="/deed/kombo-lease/view/:lease_id" component={ViewKomboLease} />
            <Route exact path="/deed/provincial-lease/view/:lease_id" component={ViewProvincialLease} />
            <Route exact path="/lease/provincial-lease" component={ProvincialLease} />
            <Route exact path="/lease/provincial-lease/add-lease" component={onlyAllowRA || onlyAllowUser? AddProvincialLease : Redirect} />
            <Route exact path="/deed/kombo-deed" component={KomboDeed} />
            <Route exact path="/deed/kombo-deed/add-deed" component={onlyAllowRA || onlyAllowUser? AddKomboDeed : Redirect} />
            <Route exact path="/deed/kombo-deed/view/:deed_id" component={ViewKomboDeed} /> 
            <Route exact path="/deed/provincial-deed" component={ProvincialDeed} />
            <Route exact path="/deed/provincial-deed/view/:deed_id" component={ViewProvincialDeed} />
            <Route exact path="/deed/provincial-deed/add-deed" component={onlyAllowRA || onlyAllowUser? AddProvincialDeed : Redirect} /> 
            <Route exact path="/deed/miscellaneous-deed" component={MiscellaneousDeed} />
            <Route exact path="/deed/miscellaneous-deed/add-deed" component={onlyAllowRA || onlyAllowUser? AddMiscellaneousDeed : Redirect} />
            <Route exact path="/deed/miscellaneous-deed/view/:deed_id" component={ViewMiscellaneousDeed} /> 
            <Route exact path="/deed/city-deed" component={CityDeed} />
            <Route exact path="/deed/city-deed/add-deed" component={onlyAllowRA || onlyAllowUser? AddCityDeed : Redirect} />
            <Route exact path="/deed/city-deed/view/:deed_id" component={ViewCityDeed} /> 
            <Route exact path="/mortgage/kombo-mortgage" component={KomboMortgage} />
            <Route exact path="/mortgage/kombo-mortgage/add-mortgage" component={onlyAllowRA || onlyAllowUser? AddKomboMortgage : Redirect} />
            <Route exact path="/deed/kombo-mortgage/view/:deed_id" component={ViewKomboMortgage} />
            <Route exact path="/mortgage/provincial-mortgage" component={ProvincialMortgage} />
            <Route exact path="/mortgage/provincial-mortgage/add-mortgage" component={onlyAllowRA || onlyAllowUser? AddProvincialMortgage : Redirect} />
            <Route exact path="/deed/provincial-mortgage/view/:deed_id" component={ViewProvincialMortgage} />
            <Route exact path="/deed/power-of-attorney" component={PowerOfAttorney} />
            <Route exact path="/deed/power-of-attorney/add-deed" component={onlyAllowRA || onlyAllowUser? AddPowerOfAttorney : Redirect} />
            <Route exact path="/deed/power-of-attorney/view/:deed_id" component={ViewPowerOfAttorney} />
            <Route exact path="/deed/drb-deed/view/:deed_id/:type" component={ViewDRB} />
            <Route exact path="/lease/view/:lease_id/:type" component={ViewLease} />
            <Route exact path="/lease/pending-lease" component={PendingLease} />
            <Route exact path="/drb/pending-drb" component={PendingDRB} />
            <Route exact path="/drb/edit/:id" component={onlyAllowRA || onlyAllowUser? EditDRB : Redirect} />
            <Route exact path="/lease/edit/:leaseType/:id" component={onlyAllowRA || onlyAllowUser? EditLease : Redirect} />
            <Route exact path="/provincial-lease/edit/:id" component={onlyAllowRA || onlyAllowUser? EditProvincialLease : Redirect} />
            <Route exact path="/search/search-lease" component={SearchLease} />
            <Route exact path="/search/search-drb" component={SearchDRB} />
            <Route exact path="/search/transaction-report" component={TransactionReport} />
            <Route exact path="/admin/manage-search-plans" component={ManageSearchPlans} />
            <Route exact path="/admin/manage-search-plans/add" component={AddSearchPlan} />
            <Route exact path="/admin/manage-subscription-plans" component={ManageSubscriptionPlan} />
            <Route exact path="/admin/manage-subscription-plans/add" component={AddSubscriptionPlan} />
            <Route exact path="/admin/manage-subscription-plans/edit/:id" component={EditSubscriptionPlan} />
            <Route exact path="/admin/manage-plans/add" component={CreatePlan} />
            <Route exact path="/admin/manage-plans/edit/:id" component={EditPlan} />
            <Route exact path="/admin/manage-plans" component={ManagePlan} />
            <Route exact path="/admin/manage-search-quota" component={ManageSearchQuota} />
            <Route exact path="/admin/manage-registration-quota" component={ManageRegistrationQuota} />
            <Route exact path="/admin/logs" component={Logs} />
            <Route exact path="/admin/manage-users" component={ManageUsers} />
            <Route exact path="/admin/manage-users/add" component={NewUser} />
            <Route exact path="/admin/manage-users/edit/:id" component={EditUser} />
            <Route exact path="/admin/manage-security-questions" component={ManageSecurityQuestions} />
            <Route exact path="/admin/manage-security-questions/add" component={AddSecurityQuestion} />
            <Route exact path="/admin/manage-security-questions/edit/:id" component={EditSecurityQuestion} />
            <Route exact path="/admin/manage-globals/instrument-type" component={onlyAllowRG? InstrumentType : Redirect} />
            <Route exact path="/admin/manage-globals/instrument-type/edit/:id" component={onlyAllowRG? EditInstrument : Redirect} />
            <Route exact path="/admin/manage-globals/instrument-type/add" component={onlyAllowRG? AddInstrumentType : Redirect} />
            <Route exact path="/admin/manage-globals/business-type" component={onlyAllowRG? BusinessType : Redirect} />
            <Route exact path="/admin/manage-globals/business-type/add" component={onlyAllowRG? AddBusinessType : Redirect} />
            <Route exact path="/admin/manage-globals/drb-registration-type" component={onlyAllowRG? DRBRegistrationType : Redirect} />
            <Route exact path="/admin/manage-globals/drb-registration-type/add" component={onlyAllowRG? AddDRBRegistrationType : Redirect} />
            <Route exact path="/admin/manage-globals/drb-registration-type/edit/:id" component={onlyAllowRG? EditDRBRegistration : Redirect} />
            <Route exact path="/admin/manage-globals/lease-registration-type" component={onlyAllowRG? LeaseRegistrationType : Redirect} />
            <Route exact path="/admin/manage-globals/lease-registration-type/add" component={onlyAllowRG? AddLeaseRegistrationType : Redirect} />
            <Route exact path="/admin/manage-globals/lease-registration-type/edit/:id" component={onlyAllowRG? EditLeaseRegistrationType : Redirect} />
            <Route exact path="/admin/manage-registra-admin" component={RegistraAdmin} />
            <Route exact path="/admin/manage-registra-admin/add" component={AddRegistraAdmin} />
            <Route exact path="/admin/registra-admin/edit/:id" component={EditRegistraAdmin} />


            <Route exact path="/admin/manage-registra-general" component={RegistraGeneral} />
            <Route exact path="/admin/manage-registra-general/add" component={AddRegistraGeneral} />
            <Route exact path="/admin/registra-general/edit/:id" component={EditRegistraGeneral} />


            <Route exact path="/admin/manage-subscription-admin" component={ onlyAllowRG || onlyAllowRA? SubscriptionAdmin: Redirect} />
            <Route exact path="/admin/manage-subscription-admin/add" component={ !onlyAllowSA ? AddSubscriptionAdmin : Redirect} />
            <Route exact path="/admin/subscription-admin/edit/:id" component={ !onlyAllowSA ? EditSubscriptionAdmin : Redirect} />







            <Route exact path="/add/document/:code/:id" component={AddDocument} />
            <Route exact path="/document/view/:book_id" component={ViewDocument} />
            <Route exact path="/user/profile" component={UserProfile} />
            <Route exact path="/reports/worker-activity" component={onlyAllowRG? WorkerActivity : Redirect} />
            <Route exact path="/subscription/packages" component={Packages} /> 
            <Route exact path="/logout" component={LogOut} />
             </PrivateRoute>
            </AuthContext.Provider>
         </Switch>
        </Router>
    </div>
  );
}

export default App;
